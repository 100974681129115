import logo from "../../assets/images/logo.png";
import DropDownAvatar from "../menus/DropDownAvatar";
import HeaderNotification from "../menus/HeaderNotification";
import DropDownGrid from "../menus/DropDownGrid";
import { useMemo, useState } from "react";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = JSON.parse(sessionStorage.getItem('user'));

  const name = useMemo(() => {
    let firstName = user?.data.first_name;
    let lastName = user?.data.last_name;

    firstName = firstName?.charAt(0).toUpperCase() + firstName?.slice(1);
    lastName = lastName?.charAt(0).toUpperCase() + lastName?.slice(1);
    return `${firstName} ${lastName}`;
  }, [user]);

  const handleSidebarToggle = () => {
    const sidebar = document.getElementsByClassName(
      "layout-sidebar-compact"
    )[0];
    isOpen
      ? sidebar.classList.add("sidenav-open")
      : sidebar.classList.remove("sidenav-open");
    setIsOpen(!isOpen);
  };

  return (
    <div className="main-header">
      <div className="logo">
        <img src={logo} alt="" />{" "}
      </div>
      <div className="menu-toggle" onClick={handleSidebarToggle}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div style={{ margin: "auto" }}></div>
      <div className="header-part-right">
        {/* Full screen toggle */}
        <i
          className="i-Full-Screen header-icon d-none d-sm-inline-block"
          data-fullscreen
        ></i>

        {/* Grid menu Dropdown */}
        <DropDownGrid />

        {/* Notificaiton */}
        <HeaderNotification />

        {/* Notificaiton End
            User avatar dropdown */}
        <DropDownAvatar username={name} />
      </div>
    </div>
  );
};

export default Header;
