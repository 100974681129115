import React from 'react';

const NoRecords = () => {
  return (
  
    <div className="empty-data-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
      <div className="icon-wrapper" style={{ width: '100px', height: '100px', margin: '0', padding: '0' }}>
          <i className="nav-icon i-File-Search big-icon" style={{ fontSize: '100px', color: '#663399' }} />
      </div>
      <p style={{ margin: '20px' }}>No Record Found</p>
      
    </div>
  );
};

export default NoRecords;
