import React from 'react';

const BreadCrumb = ({ title, parentLink, parentTitle, currentTitle }) => {
    return (
        <div className="breadcrumb">
            <h1>{title}</h1>
            <ul>
                <li>
                    <a href={parentLink}>{parentTitle}</a>
                </li>
                <li>{currentTitle}</li>
            </ul>
        </div>
    );
};

export default BreadCrumb;

