import { useEffect, useState } from "react";
import InputLayout from "../layout/reusableIputLayout/InputLayout";
import PermissionCard from "./PermissionCard";
import { switchCase } from "@babel/types";
import { set } from "date-fns";
import { async } from "q";
import Cookies from "js-cookie";
import { serverUrl } from "../../utils/constants";
import axios from "axios";

const DelegationAssignment = ({ activeEmployee, handleUpdateTaskBtn }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(activeEmployee);
  const [delResult, setDelResult] = useState([]);

  useEffect(() => {
    for (let key in selectedEmployee.delegations) {
      if (selectedEmployee.delegations.hasOwnProperty(key)) {
        if (selectedEmployee.delegations[key].status) {
          delResult.push(selectedEmployee.delegations[key].delegation_id);
        }
      }
    }
  }, []);

  const onClickAssignTasksBtn = async () => {
    let token = Cookies.get("access_token");
    //prepare header
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    //send new task assignment to server
    try {
      const response = await axios.post(
        serverUrl + "/delegations/assign",
        {
          employee_id: selectedEmployee.employee_id,
          delegations: delResult,
        },
        config
      );
    } catch (err) {
      console.error(err);
    }
    handleUpdateTaskBtn();
  };

  const handleSelectChange = (id, isChecked) => {
    if (!isChecked) {
      // addition:id it is checked
      if (!delResult.includes(id)) {
        // id is not in result array
        setDelResult((delResult) => [...delResult, id]);
      } else {
        //id is in result array
      }
    } else {
      //removal: id it is unchecked
      if (delResult.includes(id)) {
        //remove it from result
        setDelResult((delResult) => delResult.filter((r) => r !== id));
      }
    }
  };

  return (
    <div className="col-md-6">
      <InputLayout title="Task Delegation">
        <div className="card-body col">
          <p>{`Grant access permission(s) to ${selectedEmployee.first_name} ${selectedEmployee.last_name}`}</p>
          <div className="row mb-2">
            <PermissionCard
              title="Attendance Management"
              id={
                selectedEmployee.delegations.attendance_management.delegation_id
              }
              handleSelectChange={handleSelectChange}
              isPermitted={
                selectedEmployee.delegations.attendance_management.status
              }
            />

            <PermissionCard
              title="Cash Approval"
              id={2}
              handleSelectChange={handleSelectChange}
              isPermitted={selectedEmployee.delegations.cash_approval.status}
            />

            <PermissionCard
              title="Employee Record"
              id={3}
              handleSelectChange={handleSelectChange}
              isPermitted={selectedEmployee.delegations.employee_record.status}
            />
            <PermissionCard
              title="Facility Management"
              id={4}
              handleSelectChange={handleSelectChange}
              isPermitted={
                selectedEmployee.delegations.facility_management.status
              }
            />
            <PermissionCard
              title="Leave Management"
              id={5}
              handleSelectChange={handleSelectChange}
              isPermitted={selectedEmployee.delegations.leave_management.status}
            />
            <PermissionCard
              title="Quote Management"
              id={6}
              handleSelectChange={handleSelectChange}
              isPermitted={selectedEmployee.delegations.quote_management.status}
            />
            <PermissionCard
              title="Training Management"
              id={7}
              handleSelectChange={handleSelectChange}
              isPermitted={
                selectedEmployee.delegations.training_management.status
              }
            />
          </div>
          <br />
          <div className="col-md-2">
            <button className="btn btn-primary" onClick={onClickAssignTasksBtn}>
              Update Tasks
            </button>
          </div>
        </div>
      </InputLayout>
    </div>
  );
};

export default DelegationAssignment;
