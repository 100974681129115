import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import BreadCrumb from "../../components/layout/BreadCrumb";
import Footer from "../../components/layout/Footer";
import CustomDropzone from "../../components/requisition/CustomDropZone";
import PayrollService from "../../services/PayrollService";
import Loader from "../../components/Loader";
import DataTable from "../../components/tables/reactTable";
import Modal from 'react-bootstrap/Modal';
import FormSwitch from "../../components/form/formSwitch";
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { serverUrl } from "../../utils/constants";
import axios from "axios";
import TTLCountdown from "../../components/TTLCountdown";

const PayrollUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [payrolls, setPayrolls] = useState([]);
  const [payroll, setPayroll] = useState({});
  const [showEditPayroll, setShowEditPayroll] = useState(false);
  const [showCreatePayroll, setShowCreatePayroll] = useState(false);
  const [showDeletePayroll, setShowDeletePayroll] = useState(false);
  const [newPayroll, setNewPayroll] = useState({date_from: new Date(), date_to: new Date()});
  const [payrollToDelete, setPayrollToDelete] = useState({});
  const payrollService = PayrollService();
  const navigate = useNavigate();
  const dateFromPicker = useRef();
  const dateToPicker = useRef();
  const date = new Date();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await payrollService.all();
      setPayrolls(response?.data);
      setIsLoading(false);
    })();
  }, []);

  const handleClose = () => {
    setShowEditPayroll(false);
    setShowCreatePayroll(false);
    setShowDeletePayroll(false);
  };

  const handleShowEditPayroll = (event, payroll) => {
    setShowEditPayroll(true);
    setPayroll(payroll);
  };

  const handleShowCreatePayroll = () => {
    setShowCreatePayroll(true);
  }

  const createNewPayroll = async () => {
    if (newPayroll.date_from === null || newPayroll.date_to === null) {
      toast.error("date_from and date_to are required!");
      return;
    }

    if (typeof newPayroll.payroll === 'undefined') {
      toast.error("Please select the csv file to upload!");
      return;
    }

    handleClose();
    const accessToken = JSON.parse(sessionStorage.getItem("token"));
    const headers = { Authorization: `Bearer ${accessToken}`, };

    try {
      setIsLoading(true);
      const formDataToSend = new FormData();
      formDataToSend.append('date_from', new Date(newPayroll.date_from).toISOString().split('T')[0]);
      formDataToSend.append('date_to', new Date(newPayroll.date_to).toISOString().split('T')[0]);
      formDataToSend.append('payroll', newPayroll.payroll);
      const response = await axios.post(`${serverUrl}/payrolls/upload`, formDataToSend, { headers });
      setIsLoading(false);
    } catch (error) {
      for (const key of Object.keys(error?.response?.data?.errors)) {
        toast.error(error?.response?.data?.errors[key][0]);
      }

      setIsLoading(false);
    }

    window.location.reload();
  }

  const handleShowDeletePayroll = (event, payroll) => {
    setShowDeletePayroll(true);
    setPayrollToDelete(payroll);
  }

  const handleDeletePayroll = async () => {
    handleClose();
    setIsLoading(true);
    const response = await payrollService.destroy(payrollToDelete);
    setIsLoading(false);

    window.location.reload();
  }

  const handleNavigate = (payroll) => {navigate('../payroll/view', { replace: false, state: {payroll} })}

  const handleOnUpdate = async (payroll) => {
    handleClose();
    setIsLoading(true);
    await payrollService.update(payroll);
    window.location.reload();
    setIsLoading(false);
  }

  const columns = [
    {
      label: 'Date From',
      render: (payroll) => (new Date(payroll.date_from)).toDateString(),
    },
    {
      label: 'Date To',
      render: (payroll) => (new Date(payroll.date_to)).toDateString(),
    },
    {
      label: 'No Of Payslips',
      render: (payroll) => payroll.payslips.length,
    },
    {
      label: 'Status',
      render: (payroll) => (payroll.is_active) ? 'activated' : 'disabled',
    },
    {
      label: 'Uploaded By',
      render: (payroll) => payroll.uploaded_by,
    },
    {
      label: "Date Uploaded",
      render: (payroll) => (new Date(payroll.uploaded_at)).toDateString(),
    },
    {
      label: "Actions",
      render: (payroll) => (
        <div>
          <i
            className="i-Eye text-primary text-15"
            style={{ cursor: "pointer" }}
            title="View"
            onClick={(event) => handleNavigate(payroll)}
          />
          <i
            className="i-Pen-2 text-success text-15"
            style={{ cursor: "pointer", marginLeft: "10px" }}
            title="Edit"
            onClick={(event) => handleShowEditPayroll(event, payroll)}
          />
          <i
            className="i-Download text-success text-15"
            style={{ cursor: "pointer", marginLeft: "10px" }}
            title="Download"
            onClick={(event) => {window.open(payroll.file_path, "_blank")}}
          />
          <i
            className="i-Close-Window text-danger text-15"
            style={{ cursor: "pointer", marginLeft: "10px" }}
            title="Delete"
            onClick={(event) => handleShowDeletePayroll(event, payroll)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="main-content">
      <BreadCrumb
        title="Payroll Administration"
        parentLink="#"
        parentTitle="Pages"
        currentTitle="Upload Monthly Payroll"
      />
      <div className="separator-breadcrumb border-top"></div>
      <div className="row">
        {
          (isLoading) ? <Loader /> :
            <div className="col-md-12">
              <div className="card 0-hidden mb-4">
                <div className="card-header d-flex align-items-center">
                  <h5 className="w-50 float-start card-title m-0">Payroll Setup</h5>
                  <div className="dropdown dropleft text-end w-50 float-end">
                    <button className="btn bg-primary text-white" type="button" aria-haspopup="true"  onClick={handleShowCreatePayroll}>
                      <i className="nav-icon i-Upload text-white"></i> Add a payroll
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive mt-5">
                    <DataTable data={payrolls} columns={columns} />
                  </div>
                </div>
              </div>
            </div>
        }
      </div>

      {/**
       * Edit payroll modal
       */}
      <Modal show={showEditPayroll} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>Edit {payroll.date_from} - {payroll.date_to}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="form-group mb-3">
              <label htmlFor="date_from">Date From</label>
              <input
                id="date_from"
                name="date_from"
                value={payroll.date_from}
                onChange={event => setPayroll((prev) => ({...prev, date_from: event.target.value}))}
                className="form-control"
                dateformat="yyyy-MM-dd"
                type="date"
                min="1990-01-01" max="2090-12-31"
                ref={dateFromPicker}
                onClick={() => dateFromPicker.current.showPicker()}
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="dateTo">Date To</label>
              <input
                id="date_to"
                name="date_to"
                value={payroll.date_to}
                onChange={event => setPayroll((prev) => ({...prev, date_to: event.target.value}))}
                className="form-control"
                dateformat="yyyy-MM-dd"
                type="date"
                min="1990-01-01" max="2090-12-31"
                ref={dateToPicker}
                onClick={() => dateToPicker.current.showPicker()}
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="selfAppraisal">Status</label>
              <FormSwitch text="Activated" onChange={(event) => { setPayroll((prev) => ({ ...prev, is_active: !prev.is_active })) }} checked={payroll.is_active} />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={(event) => handleOnUpdate(payroll)}>Update</Button>
        </Modal.Footer>
      </Modal>

      {/**
       * Create payroll modal
       */}
      <Modal show={showCreatePayroll} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Create a new payroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="form-group mb-3">
              <label htmlFor="dateFrom">Date From</label>
              <DatePicker
                closeOnScroll={true}
                id="date_from"
                name="date_from"
                selected={new Date(newPayroll.date_from)}
                onChange={date => setNewPayroll((prev) => ({ ...prev, date_from: date }))}
                className="form-control"
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="dateTo">Date To</label>
              <DatePicker
                closeOnScroll={true}
                id="date_to"
                name="date_to"
                selected={new Date(newPayroll.date_to)}
                onChange={date => setNewPayroll((prev) => ({ ...prev, date_to: date }))}
                className="form-control"
                dateFormat="yyyy-MM-dd"
                minDate={new Date(newPayroll.date_from)}
                maxDate={new Date(`${newPayroll.date_from.getMonth() + 1}-28-${newPayroll.date_from.getFullYear()}`)}
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="description">Description</label>
              <textarea className="form-control form-control" id="message" placeholder="Type a description" name="description" cols="30" rows="3"></textarea>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="payroll">Status</label>
              <CustomDropzone
                onFilesChange={(validFile) => setNewPayroll((prev) => ({...prev, 'payroll': validFile[0]}))}
                maxFiles={1}
                allowedFormats=".csv, text/csv"
                maxSize={10000024}
                name="payroll"
              />
            </div>            
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={createNewPayroll}>Update</Button>
        </Modal.Footer>
      </Modal>

      {/**
       * Delete payroll modal
       */}
      <Modal show={showDeletePayroll} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h5>Are you sure you want to delete this payroll?</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>Note that once deleted, all associalted payslips will be deleted!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeletePayroll}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <TTLCountdown />
      <Footer />
    </div>
  );
};

export default PayrollUpload;
