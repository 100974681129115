import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userAppraisal: {},
  evaluationData: [],
  evalutaionColumns: [],
  loading: false,
  error: null,
  singeEvaluationData: [],
  appraisalList: [],
};

const appraisalSlice = createSlice({
  name: "appraisal",
  initialState,
  reducers: {
    isAppraisalLoading(state, action) {
      state.loading = true;
      state.error = null;
    },
    getEvaluation(state, action) {
      state.evaluationData = action.payload;
      state.loading = false;
      state.error = null;
    },
    getAppraisal(state, action) {
      state.userAppraisal = action.payload;
      state.loading = false;
      state.error = null;
    },
    getAppraisalError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateKeyPerformanceIndexRatings(state, action) {
      const { updatedObject, kpiIndex, cardinal, kra } = action.payload;
      state.userAppraisal.cardinals[cardinal].key_responsibility_areas[
        kra
      ].key_performance_indices[kpiIndex] = updatedObject;
    },
    resetEmployeeAppraisal(state) {
      state.loading = true;
      state.error = null;
      state.userAppraisal = {};
    },
    setSingleEvaluationData(state, action) {
      state.singeEvaluationData = action.payload;
      state.error = null;
      state.loading = false;
    },
    setAppraisalList(state, action) {
      const items = action.payload;
      state.appraisalList = items?.map((item, index) => ({...item, index: ++index}));
      state.loading = false;
      state.error = null;
    },
    updateAppraisalList(state, action) {
      const item = action.payload;
      const list = state.appraisalList?.map(appraisal => 
        (appraisal.id === item.id) ? ({...appraisal, ...item}) : appraisal
      );
      state.appraisalList = list;
      console.log(list);
      state.loading = false;
      state.error = null;
    }
  },
});

export const {
  isAppraisalLoading,
  getAppraisal,
  getEvaluation,
  getAppraisalError,
  updateKeyPerformanceIndexRatings,
  resetEmployeeAppraisal,
  setSingleEvaluationData,
  setAppraisalList,
  updateAppraisalList,
} = appraisalSlice.actions;

export default appraisalSlice.reducer;
