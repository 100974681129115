import { useState } from "react";

const DropdownComponentPropertiesForm = ({ attribute }) => {
  //initialise the attributes to the values on the form elements
  const [dropDownProperties, setDropDownProperties] = useState({
    isRequired: false,
    fieldName: "",
    choices: ["", ""],
  });

  const onClickDone = (e) => {
    //save the form elements to store
    //exit
  };

  const handleChangeFieldName = (e) => {
    let { value } = e.target;
    setDropDownProperties((ps) => {
      return { ...ps, fieldName: value };
    });
  };
  const onCheckRequired = (e) => {
    let { checked } = e.target;
    setDropDownProperties((ps) => {
      return { ...ps, isRequired: checked };
    });
  };
  const handleChangedChoice = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let newChoices = dropDownProperties.choices;
    newChoices[name] = value;
    setDropDownProperties((ps) => {
      return { ...ps, choices: newChoices };
    });
  };

  const handleAddNewChoice = (e) => {
    let { choices } = dropDownProperties;
    setDropDownProperties((ps) => {
      return { ...ps, choices: [...choices, ""] };
    });
  };

  const handleDeleteField = (e) => {
    //delete field from redux store here
  };
  const handleRemoveChoice = (e) => {
    const { name } = e.target;
    let { choices } = dropDownProperties;
    choices.splice(name, 1);
    setDropDownProperties((ps) => {
      return { ...ps, choices: choices };
    });
  };
  return (
    <div>
      <h5 className="mb-3">Behaviour</h5>
      <label class="checkbox checkbox-primary">
        <input
          type="checkbox"
          checked={dropDownProperties.isRequired}
          onChange={onCheckRequired}
        />
        <span>Required when submitting the form</span>
        <span class="checkmark"></span>
      </label>
      <div class="col-md-6 form-group mb-3">
        <label for="fieldLabel">Field Label</label>
        <input
          value={dropDownProperties.fieldName}
          name="fieldName"
          class="form-control"
          id="fieldLabel"
          type="text"
          placeholder="Enter details here"
          onChange={handleChangeFieldName}
        />
      </div>
      <div class="col-md-6 form-group mb-3">
        <label for="fieldLabel">Dropdown Choices</label>
        {dropDownProperties &&
          dropDownProperties?.choices?.map((c, i) => {
            return (
              <div className="row">
                <div className="col-md-2 mb-1">
                  <svg
                    onClick={handleRemoveChoice}
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.8006 23.8006C26.3468 21.2545 27.7772 17.8012 27.7772 14.2004C27.7772 10.5997 26.3468 7.14636 23.8006 4.60023C21.2545 2.0541 17.8012 0.623692 14.2004 0.623692C10.5996 0.623691 7.14635 2.05409 4.60021 4.60023C2.05408 7.14636 0.623673 10.5997 0.623673 14.2004C0.623673 17.8012 2.05408 21.2545 4.60021 23.8006C7.14635 26.3468 10.5996 27.7772 14.2004 27.7772C17.8012 27.7772 21.2545 26.3468 23.8006 23.8006ZM7.00026 12.5044C6.56081 12.5199 6.14453 12.7054 5.83912 13.0218C5.53372 13.3381 5.36304 13.7607 5.36304 14.2004C5.36304 14.6402 5.53372 15.0627 5.83912 15.3791C6.14453 15.6955 6.56081 15.881 7.00026 15.8965L21.4006 15.8965C21.84 15.881 22.2563 15.6955 22.5617 15.3791C22.8671 15.0627 23.0378 14.6402 23.0378 14.2004C23.0378 13.7607 22.8671 13.3381 22.5617 13.0218C22.2563 12.7054 21.84 12.5199 21.4006 12.5044L7.00026 12.5044Z"
                      fill="#FF0707"
                    />
                  </svg>
                </div>
                <div className="col-md-10 mb-3">
                  <input
                    class="form-control"
                    id="fieldLabel"
                    type="text"
                    name={i}
                    value={c}
                    onChange={handleChangedChoice}
                  />
                </div>
              </div>
            );
          })}

        <div className="row">
          <div className="col-md-2 mb-1">
            <svg
              onClick={handleAddNewChoice}
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                fill="#4CAF50"
              />
              <path d="M12 7.3335H16V20.6668H12V7.3335Z" fill="white" />
              <path d="M7.33301 12H20.6663V16H7.33301V12Z" fill="white" />
            </svg>
          </div>
          <div className="col-md-10 mb-3">
            <label>Add new choice</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <button
            onClick={handleDeleteField}
            class="btn btn-danger ripple m-1"
            type="button"
          >
            Delete Field
          </button>
        </div>
        <div className="col">
          <button class="btn btn-primary ripple m-1" type="button">
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropdownComponentPropertiesForm;
