import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isKRALoading: false,
    KRAs: [],
    error: null
}

const kraSlice = createSlice({
    name: 'kra',
    initialState,
    reducers: {
        isLoading(state) {
            state.isKRALoading = true;
            state.error = null;
        },
        setKRAs(state, action) {
            const items = action.payload;
            state.KRAs = items?.map((item, index) => ({ ...item, index: ++index }));
            state.isKRALoading = false;
            state.error = null;
        }
    }
});

export const { isLoading, setKRAs } = kraSlice.actions;

export default kraSlice.reducer;