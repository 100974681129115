import AppraisalService from "../../services/AppraisalService";

const SupervisorSingleKPI = ({ keyPerformanceIndex, cardinal, kra, kpiIndex }) => {
  const ratings = [1, 2, 3, 4, 5];
  const appraisalService = AppraisalService();
  const isKpiResponseSet = appraisalService.isKeyPerformanceIndexResponseSet(keyPerformanceIndex, 'supervisorAppraisal');

  const handleRadioOnChange = (e) => {
    appraisalService.updateKeyPerformanceIndex({...keyPerformanceIndex, supervisor_rating: parseInt(e.target.value)}, kpiIndex, cardinal, kra);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    appraisalService.updateKeyPerformanceIndex({...keyPerformanceIndex, supervisor_justification: value}, kpiIndex, cardinal, kra);
  };

  return (
    <div
      className="inbox-details perfect-scrollbar rtl-ps-none ps"
      data-suppress-scroll-x="true"
      key={keyPerformanceIndex.key_performance_index_id}
    >
      <div
        className="card mb-5"
        style={{
          border: (isKpiResponseSet) ? '1px solid #ff4c51' : "1px solid #E0E0E0",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="card-body">
          <strong style={{ color: "#000466" }}>
            {keyPerformanceIndex.key_performance_index_name}
          </strong>
          <form>
            <fieldset className="form-group">
              <div
                className="form-group row"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <div className="col-md-12 form-group d-flex align-items-center">
                  <h6 className="card-subtitle text-muted m-1">
                    Performance Expectation
                  </h6>
                  <span className="px-3 py-1 ml-3">
                    {keyPerformanceIndex.key_performance_index_performance_expectation}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 form-group">Rating</div>

                <div className="ul-form__radio-inline">
                  {ratings.map((r, i) => (
                    <label
                      className="ul-radio__position radio radio-primary m-1"
                      key={i}
                    >
                      <input
                        type="radio"
                        className="radio radio-reverse radio-success"
                        id={`gridRadios_${r}_${keyPerformanceIndex.id}`}
                        name={`gridRadios_${keyPerformanceIndex.key_performance_index_id}`}
                        value={r}
                        defaultChecked={keyPerformanceIndex?.supervisor_rating == r}
                        onChange={handleRadioOnChange}
                      />
                      <span
                        className="ul-form__radio-font"
                        style={{ marginRight: "50px", color: "#000466" }}
                      >
                        {r}
                      </span>
                      <span className="checkmark"></span>
                    </label>
                  ))}
                </div>
              </div>
            </fieldset>

            <div className="form-group row">
              <label className="col-md-12 form-group" htmlFor="inputEmail3">
                Justification
              </label>
              <div className="col-md-12 form-group">
                <textarea
                  className="form-control"
                  defaultValue={keyPerformanceIndex?.supervisor_justification}
                  aria-label="With textarea"
                  disabled={!keyPerformanceIndex?.supervisor_rating}
                  name={`supervisor_justification_${keyPerformanceIndex.key_performance_index_id}`}
                  onChange={handleInputChange}
                  placeholder="Kindly enter justification for ranking here"
                ></textarea>
              </div>
            </div>

            {keyPerformanceIndex?.employee_rating && (
              <div className="form-group row">
                <div className=" col-md-5 form-group">
                  <p>Employee self-appraisal rating: <strong>{keyPerformanceIndex?.employee_rating}</strong> </p>
                </div>
                <div className="col-md-7">
                  {keyPerformanceIndex?.employee_justification && ( <p>Employee justification: <strong>{`${keyPerformanceIndex?.employee_justification}`}</strong> </p> )}
                </div>
              </div>
            )}

            {keyPerformanceIndex?.supervisor_rating &&
              (keyPerformanceIndex.supervisor_justification ? (
                <div>
                  <i
                    className="text-20 text-white bg-secondary rounded-circle i-Yes m-1"
                    style={{ color: "#000466" }}
                  ></i>
                  <span style={{ color: "grey" }}>KPI completed</span>
                </div>
              ) : (
                <div>
                  <i
                    className="text-20 i-Yes m-1"
                    style={{ color: "#000466" }}
                  ></i>
                  <span style={{ color: "grey" }}>
                    kindly include justification for KPI
                  </span>
                </div>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupervisorSingleKPI;
