import InputLayout from "../layout/reusableIputLayout/InputLayout";

const DelegationList = ({ employees, handleDoubleClickEmployee, delNo }) => {
  return (
    <div class="col-lg-12 mb-2">
      <div class="card text-start">
        <div class="card-body">
          <div class="table-responsive">
            <table className="table table-responsive table-striped">
              <thead>
                <th>#</th>
                <th>Avatar</th>
                <th>Name</th>
                <th></th>
                <th></th>
              </thead>
              <tbody>
                {employees &&
                  employees.map((e, empNo) => {
                    return (
                      <tr
                        onDoubleClick={() =>
                          handleDoubleClickEmployee(delNo, empNo)
                        }
                      >
                        <td>{e.employee_id}</td>
                        <td>
                          <img
                            className="rounded-circle m-0 avatar-sm-table"
                            src={require("../../assets/images/faces/1.jpg")}
                            alt=""
                          />
                        </td>
                        <th>{`${e.first_name} ${e.last_name}`}</th>
                        <td>
                          <i
                            className="nav-icon i-Pen-2 fw-bold"
                            onClick={() =>
                              handleDoubleClickEmployee(delNo, empNo)
                            }
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DelegationList;
