import React from 'react';
import DataTable from '../tables/reactTable';
import NoRecordsFound from '../requisition/no-record';


const RequisitionTable = ({ data, columns, isLoading, onRowClick }) => {
return (
    <div className="table-responsive">
    {data.length === 0 ? <NoRecordsFound /> : <DataTable data={data} columns={columns} isLoading={isLoading} onRowClick={onRowClick} />}
    </div>
);
};

export default RequisitionTable;
