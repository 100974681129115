// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .rounded-input {
  border-radius: 8px;
  border: 1px solid gray;
  }
  .search-bar {
  text-align: right;
  margin-bottom: 10px;
  }
  .no-records-found {
  vertical-align: middle;
  text-align: center;
  }
  .mouse-pointer{
  cursor: pointer !important;
  }
  .sorting-icon-color{
    font-weight: bold;
    margin: 0
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/custom/table-styles.css"],"names":[],"mappings":"EAAE;EACA,kBAAkB;EAClB,sBAAsB;EACtB;EACA;EACA,iBAAiB;EACjB,mBAAmB;EACnB;EACA;EACA,sBAAsB;EACtB,kBAAkB;EAClB;EACA;EACA,0BAA0B;EAC1B;EACA;IACE,iBAAiB;IACjB;EACF","sourcesContent":["  .rounded-input {\n  border-radius: 8px;\n  border: 1px solid gray;\n  }\n  .search-bar {\n  text-align: right;\n  margin-bottom: 10px;\n  }\n  .no-records-found {\n  vertical-align: middle;\n  text-align: center;\n  }\n  .mouse-pointer{\n  cursor: pointer !important;\n  }\n  .sorting-icon-color{\n    font-weight: bold;\n    margin: 0\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
