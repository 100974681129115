export const AppraisalLegend = () => {
  const legendStyle = {
    color: "#7C93C3",
  };

  const p2Style = {
    backgroundColor: "#EEF5FF",
    margin: "5px",
    borderRadius: "10px",
  };

  const spanStyle = {
    color: "#7C93C3",
  };

  return (
    <div id="sectionE" className="mt-5">
      <span
        className="bolder m-2"
        style={{ fontSize: "16px", color: "#000466" }}
      >
        Legend
      </span>
      <div id="legend" style={legendStyle}>
        <div className="d-flex flex-column">
          <div className="p-2" style={p2Style}>
            <strong>5 </strong>
            <span style={spanStyle} className="ml-2">
              Consistently exceeds expectation
            </span>
          </div>
          <div className="p-2" style={p2Style}>
            <strong>4 </strong>{" "}
            <span style={spanStyle} className="ml-2">
              Exceeds expectation
            </span>
          </div>
          <div className="p-2" style={p2Style}>
            <strong>3 </strong>{" "}
            <span style={spanStyle} className="ml-2">
              Meet expectation
            </span>
          </div>
          <div className="p-2" style={p2Style}>
            <strong>2 </strong>
            <span style={spanStyle} className="ml-2">
              Needs improvement
            </span>
          </div>
          <div className="p-2" style={p2Style}>
            <strong>1 </strong>
            <span style={spanStyle} className="ml-2">
              Significantly below expectation
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
