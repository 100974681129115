import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { toast } from "react-toastify";

const DashboardService = () => {

    const getConfig = async () => {
        try {
            const response = await AxiosService.get( `${serverUrl}/dashboard-configs` );
            return response?.data;
        } catch (error) {
            toast.error('There was an error loading configuration');
        }
    }

    return { getConfig }
}

export default DashboardService;