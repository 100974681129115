import { useState, useEffect, useCallback } from "react";
import Footer from "../../../components/layout/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BreadCrumb from "../../../components/layout/BreadCrumb";
import EmployeeService from "../../../services/EmployeeService";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import FormSwitch from "../../../components/form/formSwitch";
import { uppercaseFirst, uppercaseWord } from "../../../utils/helper";

function EmployeeSetUp() {
    const employeeService = EmployeeService();
    const { loading = true } = useSelector((state) => state.employee);
    const [activeTab, setActiveTab] = useState(0);
    const [prevDisabled, setPrevDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [accesstypes, setAccesstypes] = useState([]);
    const [genders, setGenders] = useState([]);
    const [maritalStatuses, setMaritalStatuses] = useState({});
    const [employmentStatuses, setEmploymentStatuses] = useState({});
    const [locations, setLocations] = useState([]);
    const [employeeData, setEmployeeData] = useState(employeeService.reset());
    const [errors, setErrors] = useState({});
    const [teamMembersWithoutSupervisor, setTeamMembersWithoutSupervisor] = useState([]);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        if (activeTab === 1) {
            setPrevDisabled(true);
        } else {
            setPrevDisabled(false);
        }
        if (activeTab === 2) {
            setNextDisabled(true);
        } else {
            setNextDisabled(false);
        }
    };
    // allows navigation to the previous tab by updating the activeTab state. enables and
    // disables the Previous and Next buttons based on the current active tab.
    const handlePrevClick = (event) => {
        event.preventDefault();
        if (activeTab > 0) {
            setActiveTab(activeTab - 1);
            setNextDisabled(false);
        }
        if (activeTab === 1) {
            setPrevDisabled(true);
        }
    };

    const handleNextClick = (event) => {
        event.preventDefault();
        if (activeTab < 3) {
            setActiveTab(activeTab + 1);
            setPrevDisabled(false);
        }
        if (activeTab === 2) {
            setNextDisabled(true);
        }
    };

    const handleInputChange = (event) => setEmployeeData({ ...employeeData, [event.target.name]: event.target.value, });

    const validateForm = () => {
        const errors = employeeService.validate(employeeData);

        setErrors(errors);
        return (Object.keys(errors).length === 0);
    };

    const handleSubmit = async () => {
        setEmployeeData((prev) => ({...prev, is_supervisor: isSupervisor}));

        if (validateForm()) {
            const response = await employeeService.save(employeeData);

            if (Object.entries(response).length) {
                setEmployeeData(response);
            } else {
                setEmployeeData(employeeService.reset());
            }
        }
    };

    useEffect(() => {
        (async () => {
            const response = await employeeService.getEmployeeConfigurations();

            setGenders(response?.genders);
            setMaritalStatuses(response?.marital_statuses);
            setDepartments(response?.departments);
            setDesignations(response?.designations);
            setAccesstypes(response?.access_types);
            setEmploymentStatuses(response?.employment_statuses);
            setLocations(response?.locations);
            setTeamMembersWithoutSupervisor(response?.team_members_without_a_supervisor);
            setRoles(response?.roles);
        })();

    }, []);

    const handleRemoveTeamMember = (event, teamMember) => {
        const result = employeeData?.team_members.filter((team) => {
            return team.employee_id !== teamMember.employee_id
        });

        setEmployeeData((prev) => ({ ...prev, team_members: result }))
        setTeamMembersWithoutSupervisor(prev => [...prev, teamMember]);
    }

    const handleOnchangeTeamMemberWithoutSupervisor = (selectedTeamMemberId) => {
        if (typeof selectedTeamMemberId === 'string' && selectedTeamMemberId.length === 0) {
            return;
        }

        const selectedTeamMember = teamMembersWithoutSupervisor?.filter((team) => {
            return team.employee_id === parseInt(selectedTeamMemberId);
        });

        const result = teamMembersWithoutSupervisor.filter((team) => {
            return team.employee_id !== parseInt(selectedTeamMemberId)
        });

        setTeamMembersWithoutSupervisor(result);

        const newteamMembers = [...employeeData.team_members, selectedTeamMember[0]];

        setEmployeeData((prev) => ({ ...prev, team_members: newteamMembers }));
    }

    const handleIsSupervisorSwitch = () => {
        setIsSupervisor(!isSupervisor);

        setEmployeeData((prev) => ({ ...prev, is_supervisor: !isSupervisor }));
    }

    const handleOnClickSetUserRoles = (roleId) => {
        let currentRoles = [...employeeData.roles];

        if (selectedRoles?.includes(parseInt(roleId))) {
            const unSelected = selectedRoles.filter((selectedRole) => { return selectedRole !== parseInt(roleId) });
            currentRoles.pop(parseInt(roleId));
            setEmployeeData((prev) => ({ ...prev, roles: currentRoles }));
            setSelectedRoles(unSelected);
            return;
        }

        let selected = [...selectedRoles];
        selected.push(parseInt(roleId));
        setSelectedRoles(selected);

        currentRoles.push(parseInt(roleId));
        setEmployeeData((prev) => ({ ...prev, roles: currentRoles }));
    }

    return (
        <div className="main-content">
            <BreadCrumb title="Add Employee" parentLink="#" parentTitle="Employee Records" currentTitle="Add Employee" />
            <div className="separator-breadcrumb border-top"></div>

            {
                loading ? <Loader /> :
                    <>
                        <div className="btn-toolbar mt-4 sw-toolbar sw-toolbar-bottom justify-content-end">
                            <div className="btn-group me-2 sw-btn-group" role="group">
                                <button type="button" className={`btn btn-secondary btn-prev ${prevDisabled ? "disabled" : ""}`} onClick={handlePrevClick} >
                                    Previous
                                </button>
                                <button type="button" className={`btn btn-secondary btn-next ${nextDisabled ? "disabled" : ""}`} onClick={handleNextClick} >
                                    Next
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div id="smartwizard" className="sw-main sw-theme-default">
                                <ul className="nav nav-tabs step-anchor">
                                    <li className="nav-item ">
                                        <a href="#0" className={`nav-link ${activeTab === 0 ? "active" : ""}`} onClick={() => handleTabClick(0)}>Personal Details </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a href="#1" className={`nav-link ${activeTab === 1 ? "active" : ""}`} onClick={() => handleTabClick(1)}>Employment Information</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a href="#2" className={`nav-link ${activeTab === 2 ? "active" : ""}`} onClick={() => handleTabClick(2)}>Address & Contact Informataion</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a href="#3" className={`nav-link ${activeTab === 3 ? "active" : ""}`} onClick={() => handleTabClick(3)}>Emergency</a>
                                    </li>
                                </ul>

                                <div className="sw-container tab-content" style={{ minHeight: "126.75px" }} >
                                    <div id="tab-1" className={`tab-pane step-content ${activeTab === 0 ? "active" : ""}`}>
                                        <div className="col-lg-12">
                                            <div className="card mb-4">
                                                <div className="card-body">
                                                    <div className="card-title mb-3">Personal Details</div>
                                                    <div className="row">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="firstName1">First name <span className="text-danger">*</span></label>
                                                            <input className="form-control" id="firstName" type="text" name="first_name" value={employeeData.first_name} onChange={handleInputChange} />
                                                            {errors.first_name && <span className="error" style={{ color: 'red' }}>{errors.first_name}</span>}
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="lastName1">Middle name</label>
                                                            <input className="form-control" id="middleNme" type="text" name="middle_name" value={employeeData.middle_name} onChange={handleInputChange} />
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="lastName1">Last name <span className="text-danger">*</span></label>
                                                            <input className="form-control" id="lastName" type="text" name="last_name" value={employeeData.last_name} onChange={handleInputChange} />
                                                            {errors.last_name && <span className="error" style={{ color: 'red' }}>{errors.last_name}</span>}
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="picker2">Birth date <span className="text-danger">*</span></label>
                                                            <DatePicker
                                                                closeOnScroll={true}
                                                                id="date_of_birth"
                                                                name="date_of_birth"
                                                                selected={employeeData.date_of_birth}
                                                                onChange={date => setEmployeeData({ ...employeeData, date_of_birth: date, })}
                                                                className="form-control"
                                                                dateFormat="yyyy-MM-dd"
                                                            />
                                                            {errors.date_of_birth && <span className="error" style={{ color: 'red' }}>{errors.date_of_birth}</span>}
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="picker1">Gender <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="gender" value={employeeData?.gender} onChange={handleInputChange}>
                                                                <option value="">Select Gender</option>
                                                                {                                                                    
                                                                    Object.entries(genders || {})?.map(([key, value]) => {
                                                                        return (<option key={key} value={key}> {uppercaseFirst(value)} </option>)
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.gender && <span className="error" style={{ color: 'red' }}>{errors.gender}</span>}
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="picker1">Marital Status <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="marital_status" value={employeeData.marital_status} onChange={handleInputChange}>
                                                                <option>Select status </option>
                                                                {
                                                                    Object.entries(maritalStatuses || {}).map(([key, value]) => {
                                                                        return (<option key={key} value={key}> {uppercaseFirst(value)} </option>)
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.marital_status && <span className="error" style={{ color: 'red' }}>{errors.marital_status}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="tab-1" className={`tab-pane step-content ${activeTab === 1 ? "active" : ""}`}>
                                        <div className="col-lg-12">
                                            <div className="card mb-4">
                                                <div className="card-body">
                                                    <div className="card-title mb-3">Employment Information</div>
                                                    <div className="row">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="hiredOn">Hired On <span className="text-danger">*</span></label>
                                                            <DatePicker
                                                                id="hired_on"
                                                                name="hired_on"
                                                                selected={employeeData.hired_on}
                                                                onChange={date => setEmployeeData({ ...employeeData, hired_on: date })}
                                                                className="form-control"
                                                                dateFormat="yyyy-MM-dd"
                                                            />
                                                            {errors.hired_on && <span className="error" style={{ color: 'red' }}>{errors.hired_on}</span>}
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="department">Department <span className="text-danger">*</span></label>
                                                            <select className="form-control" id="department" name="department" onChange={handleInputChange}>
                                                                <option> Select</option>
                                                                {
                                                                    departments?.map((department) => {
                                                                        return <option key={department.id} value={department.id}> {department.name} </option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.department && <span className="error" style={{ color: 'red' }}>{errors.department}</span>}
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="designation">Designation <span className="text-danger">*</span></label>
                                                            <select className="form-control" id="designation" name="designation" value={employeeData.designation} onChange={handleInputChange}>
                                                                <option> Select designation</option>
                                                                {
                                                                    designations?.map((designation) => {
                                                                        return <option key={designation.id} value={designation.id}> {designation.name} </option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.designation && <span className="error" style={{ color: 'red' }}>{errors.designation}</span>}
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="picker2">Access Type <span className="text-danger">*</span></label>
                                                            <select className="form-control" id="access_type" name="access_type" value={employeeData?.access_type} onChange={handleInputChange}>
                                                                <option> Select</option>
                                                                {
                                                                    accesstypes?.map((accessType) => {
                                                                        return <option key={accessType.id} value={accessType.id}> {accessType.name} </option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.access_type && <span className="error" style={{ color: 'red' }}>{errors.access_type}</span>}
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="location">Location <span className="text-danger">*</span></label>
                                                            <select className="form-control" id="location" name="location" value={employeeData.location} onChange={handleInputChange}>
                                                                <option> Select</option>
                                                                {
                                                                    locations?.map((location) => {
                                                                        return <option key={location.id} value={location.id}> {uppercaseFirst(location.name)} </option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.location && <span className="error" style={{ color: 'red' }}>{errors.location}</span>}
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="picker1">Employment Status <span className="text-danger">*</span></label>
                                                            <select className="form-control" id="employment_status" name="employment_status" value={employeeData.employment_status} onChange={handleInputChange}>
                                                                <option> Select</option>
                                                                {
                                                                    Object.entries(employmentStatuses || {}).map(([key, value]) => {
                                                                        return (<option key={key} value={key}> {uppercaseFirst(value)} </option>)
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.employment_status && <span className="error" style={{ color: 'red' }}>{errors.employment_status}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="tab-1" className={`tab-pane step-content ${activeTab === 2 ? "active" : ""}`}>
                                        <div className="col-lg-12">
                                            <div className="card mb-4">
                                                <div className="card-body">
                                                    <div className="card-title mb-3">Address And Contact Informataion</div>
                                                    <div className="row">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="firstName1">Street</label>
                                                            <input className="form-control" id="street" type="text" name='street' value={employeeData.street} onChange={handleInputChange} />
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="lastName1">City</label>
                                                            <input className="form-control" id="city" type="text" name='city' value={employeeData.city} onChange={handleInputChange} />
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="lastName1">State</label>
                                                            <input className="form-control" id="state" type="text" name='state' value={employeeData.state} onChange={handleInputChange} />
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="picker2">Zip Code</label>
                                                            <input className="form-control" id="zipCode" type="text" name='zip_code' value={employeeData.zip_code} onChange={handleInputChange} />
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="picker1">Personal Email</label>
                                                            <input className="form-control" id="email" type="email" name='personal_email' value={employeeData.personal_email} onChange={handleInputChange} />
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="picker1">Work Email</label>
                                                            <input className="form-control" id="workEmail" type="email" name='work_email' value={employeeData.work_email} onChange={handleInputChange} />
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="picker1">Mobile Phone <span className="text-danger">*</span></label>
                                                            <input className="form-control" id="mobileNumber" type="text" name='mobile_phone' value={employeeData.mobile_phone} onChange={handleInputChange} />
                                                            {errors.mobile_phone && <span className="error" style={{ color: 'red' }}>{errors.mobile_phone}</span>}
                                                        </div>
                                                        <div className="col-md-6 form-group mb-3">
                                                            <label htmlFor="picker1">Work Phone</label>
                                                            <input className="form-control" id="workPhone" type="text" name='work_phone' value={employeeData.work_phone} onChange={handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="tab-1" className={`tab-pane step-content ${activeTab === 3 ? "active" : ""}`}>
                                        <div className="col-lg-12">
                                            <div className="card mb-4">
                                                <div className="card-body">
                                                    <div className="card-title mb-3">Emergency Contact Information</div>
                                                    <form>
                                                        <div className="row">
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="firstName1">Full Name</label>
                                                                <input className="form-control" id="fullName" type="text" name="emergency_contact_full_name" value={employeeData.emergency_contact_full_name} onChange={handleInputChange} />
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="lastName1">Relationship</label>
                                                                <input className="form-control" id="relationship" type="text" name="emergency_contact_relationship" value={employeeData.emergency_contact_relationship} onChange={handleInputChange} />
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="lastName1">Phone</label>
                                                                <input className="form-control" id="phone" type="text" name="emergency_contact_phone" value={employeeData.emergency_contact_phone} onChange={handleInputChange} />
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="picker2">Email</label>
                                                                <input className="form-control" id="email" type="text" name="emergency_contact_email" value={employeeData.emergency_contact_email} onChange={handleInputChange} />
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="picker1">Address</label>
                                                                <input className="form-control" id="address" type="text" name="emergency_contact_address" value={employeeData.emergency_contact_address} onChange={handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="card mb-4" style={{ padding: '1rem' }}>
                                    <div className="card-body">
                                        <div className="card-title mb-3">Set roles</div>
                                        <>
                                            {
                                                (isSupervisor) ?
                                                    <div>
                                                        <label className='mb-3'>Activate supervisor role</label>
                                                        <FormSwitch text={'Supervisor role enabled'} onChange={handleIsSupervisorSwitch} checked={isSupervisor} />
                                                        <fieldset className='mt-3'>
                                                            <label>Team members</label>
                                                            <div className='row'>
                                                                <div className="col-lg-6 form-group mt-3">
                                                                    <div className="tagBox case-sensitive tagging editable" data-no-duplicate="true" data-pre-tags-separator="," data-no-duplicate-text="Duplicate tags" data-type-zone-class="type-zone" data-case-sensitive="true" data-tag-box-class="tagging">
                                                                        {
                                                                            employeeData?.team_members?.map((teamMember) => {
                                                                                return (
                                                                                    <div className="tag">
                                                                                        <span>#</span>{teamMember.name}<input type="hidden" name="tag[]" value={teamMember.employee_id} />
                                                                                        <a role="button" className="tag-i" key={teamMember.employee_id} onClick={(event) => { handleRemoveTeamMember(event, teamMember) }}>×</a>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        <input className="type-zone" contentEditable="true" /></div>
                                                                    <p className="text-muted">
                                                                        Click <code>x</code> to remove team members
                                                                    </p>
                                                                </div>

                                                                <div className="col-lg-6 form-group mb-3">
                                                                    <label htmlFor="selectTeamMember">Select team members</label>
                                                                    <select
                                                                        className="form-control"
                                                                        onChange={event => handleOnchangeTeamMemberWithoutSupervisor(event.target.value)}
                                                                        defaultValue="Select a team member"
                                                                    >
                                                                        <option value="Select a team member"></option>
                                                                        {
                                                                            teamMembersWithoutSupervisor?.map((member) => (<option key={member.employee_id} value={member.employee_id}>{member.name}</option>))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div> :
                                                    <div></div>
                                            }

                                            {
                                                (!isSupervisor) ?
                                                    <div className="mb-3">
                                                        <label className='mb-3'>Activate supervisor role</label>
                                                        <FormSwitch text={'Supervisor role is disabled'} onChange={handleIsSupervisorSwitch} checked={false} />
                                                    </div> :
                                                    <div></div>
                                            }
                                        </>

                                        <>
                                            {
                                                roles?.map((role) => (
                                                    <label className="checkbox checkbox-outline-primary">
                                                        <input type="checkbox" name={role.name} key={role.id} value={role.id} onChange={(e) => handleOnClickSetUserRoles(e.target.value)} checked={selectedRoles.includes(role.id)} />
                                                        <span>{role.name}</span>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                ))
                                            }
                                        </>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end text-align-center">
                                <button className="btn btn-primary btn-submit col-md-1" type="button" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>

                        </div>
                    </>
            }

            <Footer />
        </div>
    );
}

export default EmployeeSetUp;
