import Select from "react-select";

const RequisitionCategoriesCard = ({
  setShowNewCategoryForm,
  setShowNewGroupForm,
}) => {
  return (
    <div className="card mb-12">
      <div className="card-body">
        <strong>Requisition Categories</strong>
        <h6 className="card-subtitle mb-1 mt-2 text-muted">
          Categories <span className="text-danger">*</span>
        </h6>
        <div className="row">
          <div className="col-md-11">
            <Select
              options={[
                { value: "Expense", label: "Expense Request" },
                { value: "Service", label: "Service Request" },
              ]}
            />
          </div>
          <div className="col-md-1">
            <svg
              onClick={() => setShowNewCategoryForm(true)}
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                fill="#4CAF50"
              />
              <path d="M12 7.3335H16V20.6668H12V7.3335Z" fill="white" />
              <path d="M7.33301 12H20.6663V16H7.33301V12Z" fill="white" />
            </svg>
          </div>
        </div>
        <h6
          className="card-subtitle mb-1 mt-4 text-muted"
          aria-label="Groups (required)"
          style={{ position: "relative" }}
        >
          Groups <span className="text-danger ">*</span>
        </h6>

        <div className="row">
          <div className="col-md-11">
            <Select
              options={[
                { value: "GroupA", label: "Group A" },
                { value: "GroupB", label: "Group B" },
              ]}
            />
          </div>
          <div className="col-md-1">
            <svg
              onClick={() => setShowNewGroupForm(true)}
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                fill="#4CAF50"
              />
              <path d="M12 7.3335H16V20.6668H12V7.3335Z" fill="white" />
              <path d="M7.33301 12H20.6663V16H7.33301V12Z" fill="white" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequisitionCategoriesCard;
