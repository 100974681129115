import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
    isLoading,
    getRequests,
    getRequestError,
    resetRequest,
    removeItemFromRequestList,
    getRequestTypes,
    getRequisitionRequest,
    getRequestList,
    isRequestFormLoading,
    getRequestListForAdmin,
} from  "../store/slices/requestSlice";

const RequestService = () => {
    const dispatch = useDispatch();

    const getRequestByEmployeeIdAndCategory = async (employeeId, requestCategoryId) => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.get(serverUrl + "/requests/employee/" + employeeId + "/request-category/" + requestCategoryId,);
            toast.success("Request fetched successfully!");
            dispatch(getRequestList(response.data));
            return response;
        } catch (error) {
            toast.error("Error fetching requests");
            dispatch(getRequestError(error.message));
        }
    }

    const getRequestByName = async (requestName) => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.get(serverUrl + "/requests?name=" + requestName);
            toast.success("Request fetched successfully!");
            dispatch(getRequestTypes(response.data));
            return response.data;
        } catch (error) {
            toast.error("Error fetching requests");
            dispatch(getRequestError(error.message));
        }
    }

    const getRequestForm = async (requestId) => {
        try {
            dispatch(isRequestFormLoading(true));
            const response = await AxiosService.get(serverUrl + "/requests/" + requestId);
            toast.success("Request form fetched successfully!");
            dispatch(getRequisitionRequest(response.data));
            return response.data;
        } catch (error) {
            toast.error("Error fetching requests");
            dispatch(getRequestError(error.message));
        }
    }

    const validate = (attributes, fileUploadedState, isClaimSelected = false) => {
        const validationConfig = {};

        for (const field of attributes) {
            const fieldName = field.html_name;
            const fieldConfig = {};

            if (field.is_required || (field.name === 'Upload a document to support your request' && isClaimSelected)) {
                fieldConfig.required = !fileUploadedState[fieldName];
            }

            validationConfig[fieldName] = fieldConfig;
        }

        return validationConfig;
    }

    const setUpFormData = async (employeeId, requestId, uploadedFiles, formValues) => {
        const formDataToSend = new FormData();
        formDataToSend.append('employee_id', employeeId);
        formDataToSend.append('request_id', requestId);

        for (const attributeName of Object.keys(formValues)) {
            const value = formValues[attributeName];

            if (Array.isArray(value)) {
                formDataToSend.append(attributeName, value.map((item) => {return item.value}).join(', '));
            }

            if (typeof value === 'object' && 'value' in value) {
                formDataToSend.append(attributeName, value.value);
            }

            if (!Array.isArray(value) && !(typeof value === 'object' && 'value' in value)) {
                formDataToSend.append(attributeName, value);
            }
        }

        for (const attributeName of Object.keys(uploadedFiles)) {
            const files = uploadedFiles[attributeName];
            for (const file of files) {
                formDataToSend.append(attributeName, file);
            }
        }

        try {
            dispatch(isLoading(true));
            const response = await AxiosService.post(serverUrl + "/requests", formDataToSend);
            toast.success("Request saved successfully!");
            dispatch(getRequests(response));
            return response;
        } catch (error) {
            toast.error("There was an error saving the request");
            dispatch(getRequestError(error.message));
        }
    }

    const save = async (formDataTosend) => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.post(serverUrl + "/requests", formDataTosend);
            toast.success("Request saved successfully!");
            dispatch(getRequests(response));
            return response;
        } catch (error) {
            toast.error(error.message);
            dispatch(getRequestError(error.message));            
        }
    }

    const flattenRequisitionRequest = (requests) => {
        if(typeof requests === 'object' && Object.entries(requests).length > 0){
            return Object.values(requests).flatMap(item => 
                Object.values(item).flatMap(category => 
                Object.values(category).flatMap(requests => requests)
                )
            );
        }
        return []
    }

    const getEmployeeRequestForApproval = async (stateId, requestCategoryId = 2) => {
        try {
            dispatch(isLoading());
            const response = await AxiosService.get(`${serverUrl}/employee-requests/request-category/${requestCategoryId}/state/${stateId}`);
            toast.success("Request fetched successfully!");
            dispatch(getRequestList(response.data));
            return response;
        } catch (error) {
            toast.error("Error fetching requests");
            dispatch(getRequestError(error.message));
        }
    }

    const clear = () => {
        dispatch(isLoading());
        dispatch(resetRequest())
    }

    const updateEmployeeRequest = async (employeeRequestId, stateId, comment) => {
        try {
            dispatch(isLoading());
            const response = await AxiosService.patch(`${serverUrl}/employee-requests/${employeeRequestId}/state/${stateId}`, {comment:comment});
            toast.success("Request updated successfully!");
            dispatch(removeItemFromRequestList(response.data));
            return response;
        } catch (error) {
            toast.error(error.message);
            dispatch(getRequestError(error.message));
        }
    }

    const revertEmployeeRequest = async (employeeRequestId, modificationRequired, comment) => {
        try {
            dispatch(isLoading());

            const response = await AxiosService.patch(
                `${serverUrl}/employee-requests/${employeeRequestId}`,
                {
                    comment: comment,
                    require_modification: true
                }
            );
            toast.success("Request updated successfully!");
            dispatch(isLoading(false));
            return response;
        } catch (error) {
            dispatch(isLoading(false));
        }
    }

    const removeItemFromRequests = (request) => {
        dispatch(isLoading());
        dispatch(removeItemFromRequestList(request));
    }

    const getRequestByCategoryName = async () => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.get(serverUrl + "/admin/requests");
            toast.success("Request fetched successfully!");
            dispatch(getRequestListForAdmin(response.data));
            return response.data;
        } catch (error) {
            toast.error("Error fetching requests");
            dispatch(getRequestError(error.message));
        }
    }

    const getRequestConfiguration = async () => {
        try {
            const response = await AxiosService.get(`${serverUrl}/admin/request-configurations`);
            return response;
        } catch (error) {
            toast.error("Error fetching configurations!");
        }
    }

    const adminCreateRequisition = async (formData) => {
        const data = _getData(formData);

        try {
            dispatch(isLoading(true));
            await AxiosService.post(`${serverUrl}/admin/requests`, data);
            toast.success("Request created successfully!");
            getRequestByCategoryName();
        } catch (error) {
            toast.error(error.message);
            dispatch(getRequestError(error.message));
        }
    }

    const adminUpdateRequisition = async (formData) => {
        const data = _getData(formData);

        try {
            dispatch(isLoading(true));
            await AxiosService.patch(`${serverUrl}/admin/requests/${data.request_id}`, data);
            toast.success("Request created successfully!");
            getRequestByCategoryName();
        } catch (error) {
            toast.error(error.message);
            dispatch(getRequestError(error.message));
        }
    }

    const _getData = (formData) => {
        return {
            request_id: formData?.requestId,
            request_name: formData?.requestName,
            process_id: formData.process?.value,
            request_group_id: formData.requestGroup?.value,
            request_type_id: formData.requestType?.value,
            attributes: formData.attributes,
            leave_allocation: formData.leaveAllocation,
            request_type: formData.requestType?.label
        }
    }
    
    return {
        getRequestByEmployeeIdAndCategory,
        getRequestByName,
        getRequestForm,
        validate,
        setUpFormData,
        save,
        flattenRequisitionRequest,
        getEmployeeRequestForApproval,
        clear,
        updateEmployeeRequest,
        removeItemFromRequests,
        revertEmployeeRequest,
        getRequestByCategoryName,
        getRequestConfiguration,
        adminCreateRequisition,
        adminUpdateRequisition,
    }
}

export default RequestService;