const Loader = () => {
    return (
      <>
        <div className="row">
          <div className="text-center mb-3 col-sm-2">
            <div
              className="spinner-bubble spinner-bubble-info m-5"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
            </div>
          </div>
        </div>
      </>
    );
}

export default Loader;