import { useState } from "react";

const NewGroupForm = () => {
  //initialise the attributes to the values on the form elements
  const [formProperties, setFormProperties] = useState({
    groupName: "",
    description: "",
  });

  const handleChangeInput = (e) => {
    // e.preventDefault(e);
    let { name, value } = e.target;
    setFormProperties((ps) => {
      return { ...ps, [name]: value };
    });
  };

  const onClickSaveBtn = (e) => {
    e.preventDefault(e);
    //TODO: save new group name to server
  };

  return (
    <div>
      <div className="col-md-10 form-group mb-3">
        <label htmlFor="fieldLabel">Name</label>
        <span className="text-danger m-1">*</span>
        <input
          className="form-control"
          id="fieldLabel"
          type="text"
          name="groupName"
          placeholder="Enter group name here"
          value={formProperties.groupName}
          onChange={handleChangeInput}
        />
      </div>
      <div className="col-md-10 form-group mb-3">
        <label htmlFor="fieldLabel">Description</label>
        <span className="text-danger m-1">*</span>
        <input
          className="form-control"
          id="fieldLabel"
          name="description"
          value={formProperties.description}
          type="text"
          placeholder="Enter group description here"
          onChange={handleChangeInput}
        />
      </div>
      <div className="row">
        <button
          className="col-md-2 btn btn-primary ripple m-1"
          type="button"
          onClick={onClickSaveBtn}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default NewGroupForm;
