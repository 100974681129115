import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workflowStates: [],
    isWorkflowStateLoading: false,
    error: null,
};

const workflowStateSlice = createSlice({
    name: "workflowStates",
    initialState,
    reducers: {
        isLoading(state) {
            state.isWorkflowStateLoading = true;
        },
        setWorkflowStates(state, action) {
            state.isWorkflowStateLoading = false;
            state.workflowStates = action.payload;
        }
    }
});

export const { isLoading, setWorkflowStates } = workflowStateSlice.actions;

export default workflowStateSlice.reducer;