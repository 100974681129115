import React, { useState } from "react";
import Dropzone from "react-dropzone";

const CustomDropzone = ({
  onFilesChange,
  maxFiles,
  allowedFormats,
  maxSize,
  name,
}) => {
  const [validationError, setValidationError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    // Check the number of files
    if (
      maxFiles &&
      selectedFiles.length + acceptedFiles.length + rejectedFiles.length >
        maxFiles
    ) {
      setValidationError(`You can upload a maximum of ${maxFiles} files.`);
      return;
    }

    // Check file types
    const validFiles = acceptedFiles.filter((file) =>
      allowedFormats.includes(file.type)
    );
    if (validFiles.length !== acceptedFiles.length) {
      setValidationError(
        `Invalid file format. Allowed formats: ${allowedFormats}`
        // 'Invalid file format. Allowed formats: png, jpg, jpeg, doc, docx, pdf, xls, xlsx'
      );
      return;
    }

    // Check file size
    const oversizedFiles = acceptedFiles.filter((file) => file.size > maxSize);

    if (oversizedFiles.length > 0) {
      setValidationError(
        `File size exceeds the limit of ${maxSize / 1024 / 1024} MB.`
      );
      return;
    }

    // Check for duplicates
    const duplicates = acceptedFiles.filter((file) =>
      selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );

    if (duplicates.length > 0) {
      setValidationError(
        `Duplicate files detected: ${duplicates
          .map((file) => file.name)
          .join(", ")}`
      );
      return;
    }

    // Clear validation error
    setValidationError("");

    // Notify the parent component of the valid files
    onFilesChange([...selectedFiles, ...validFiles]);
    setSelectedFiles([...selectedFiles, ...validFiles]);
  };

  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter(
      (selectedFile) => selectedFile !== file
    );
    setSelectedFiles(updatedFiles);

    // Notify the parent component of the removed file
    onFilesChange(updatedFiles);
  };

  return (
    <div>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} name={name} />
            <p>Drag & drop some files here, or click to select files</p>
          </div>
        )}
      </Dropzone>
      {validationError && <p style={{ color: "red" }}>{validationError}</p>}

      {selectedFiles.length > 0 && (
        <div>
          <h6 style={{ paddingTop: "10px" }}>Selected Files:</h6>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>
                {file.name}
                <span
                  className="badge sm m-1"
                  style={{
                    padding: "2px !important",
                    background: "#fff",
                    borderRadius: "50%",
                    border: "1px solid #ff4c51",
                    color: "#ff4c51",
                    lineHeight: "13px",
                    cursor: "pointer",
                  }}
                  data-toggle="tooltip"
                  title="Remove"
                  onClick={() => handleRemoveFile(file)}
                >
                  X
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropzone;
