import React, { useState, useEffect } from 'react';
import BreadCrumb from "../../../components/layout/BreadCrumb";
import Footer from "../../../components/layout/Footer";
import DataTable from '../../../components/tables/reactTable';
import ModalComponent from '../../../components/modals/modal';
import Select from 'react-select';
import TableDropDown from '../../../components/requisition/dropDown';
import NoRecords from '../../../components/requisition/not-found';
import CardinalService from '../../../services/CardinalService';
import Loader from '../../../components/Loader';
import { useSelector } from 'react-redux';

const KRASetup = () => {
  const cardinalService = CardinalService();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCardinal, setSelectedCardinal] = useState('');
  const [kraDetails, setKraDetails] = useState([]);
  const { isCardinalLoading = false, cardinals = [] } = useSelector((state) => state.cardinal );
 
  const handleAddKra = () => {
    setIsAddModalOpen(true);
  };

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  useEffect(() => {
    (async () => { await cardinalService.getCardinals(); })();
  }, []);

  const handleCardinalChange = (selectedOption) => {
    setSelectedCardinal(selectedOption);
    const selectedCardinal = cardinals.find((cardinal) => cardinal.id === selectedOption.value);
    const kraDetails = selectedCardinal?.key_responsibility_areas || [];
    setKraDetails(kraDetails);
  };
  
  const KRAData = kraDetails.map((kra, index) => ({ ...kra, index: ++index, }));
  
  const columns = [
    {
      label: "S/N",
      render: (kra) => kra.index,
      sortFunction: () => (item) => item.index,
    },
    
    {
      label: "Name",
      render: (kra) => `${kra.name}`,
      sortFunction: () => (item) => item.name,
    },
    {
      label: "Description",
      render: (kra) => `${kra?.description || ''}`,
      sortFunction: () => (item) => item.description,
    },
    {
      label: "Actions",
      render: (kra) => (
        <TableDropDown
        options={[
          { label: "Edit", action: () => handleEditClick(kra) },
          { label: "Delete", action: () => {} },
        ]}
        />
      ),
    }
  ];

  const getTableRecord = () => {
    if (KRAData?.length === 0 ) {
      return <NoRecords />
    }

    return <DataTable data={KRAData} columns={columns} />
  }

  return (
    <div className="main-content">
      <BreadCrumb
        title="Appraisal"
        parentLink="#"
        parentTitle="Pages"
        currentTitle="KRA Setup"
      />

      <div className="separator-breadcrumb border-top"></div>
      <div className="row">
        <div className="col-md-12">
        <div className="card mb-4">
          <div className="card-header d-flex align-items-center">
            <h3 className="w-50 float-start card-title m-0">List of Key Responsibility Areas</h3>
              <div className="dropdown dropleft text-end w-50 float-end">
                <button className="btn bg-primary text-white" type="button" aria-haspopup="true" onClick={handleAddKra}>
                  <i className="nav-icon i-Upload text-white"></i> Add Key Responsibility Area
                </button>
              </div>
          </div>
          <div className="card-body">
          <div className="row">
              <div className="form-group mb-5 col-md-6">
              <label htmlFor="cardinalType" className="form-label">
                Select Cardinal:
              </label>
              <Select
                id="cardinalType"
                options={cardinals?.map((cardinal) => ({ value: cardinal.id, label: cardinal.name }))}
                onChange={handleCardinalChange}
                value={selectedCardinal}
                isSearchable
                styles={{ overflow: 'auto',maxHeight: '1000px' }}
              />
              </div>
              <div className="table-responsive">
                {isCardinalLoading ? <Loader /> : getTableRecord()}
              </div>
          </div>            
          </div>
        </div>
      </div>
      </div>
      {/* end of main-content */}
      {/* Modal Component */}
      <ModalComponent
          isOpen={isAddModalOpen}
          onRequestClose={() => setIsAddModalOpen(false)}
          title={`Add New KRA`}
          content={(
            <>
              <form>
                <div className="form-group">
                  <label htmlFor="cardinal">Select Cardinal: *</label>
                  <Select
                    id="addcardinalType"
                    options={cardinals?.map((cardinal) => ({ value: cardinal.id, label: cardinal.name }))}
                    isSearchable
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Name: *</label>
                  <input type="text" id="name" name="name" className="form-control" required />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description: </label>
                  <input type="text" id="description" name="description" className="form-control" required />
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Save</button>
                </div>
              </form>
            </>
          )}
        />
      {selectedRecord && (
        <ModalComponent
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          title={`Edit KRA`}
          content={(
            <>
              <form>
                <div className="form-group">
                  <label htmlFor="editCardinal">Select Cardinal: *</label>
                  <Select
                  options={cardinals?.map((cardinal) => ({ value: cardinal.id, label: cardinal.name }))}
                  defaultValue={selectedCardinal}
                  isSearchable
                />
                </div>
                <div className="form-group">
                  <label htmlFor="editName">Name: *</label>
                  <input 
                  type="text" 
                  id="editName" 
                  name="editName" 
                  className="form-control" 
                  defaultValue={selectedRecord.name} 
                  required />
                </div>
                <div className="form-group">
                  <label htmlFor="editDescription">Description: </label>
                  <input 
                  type="text" 
                  id="editDescription" 
                  name="editDescription" 
                  className="form-control"
                  defaultValue={selectedRecord.description} 
                  required />
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Update</button>
                </div>
              </form>
            </>
          )}
        />
      )}
      {/* Footer Start */}
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default KRASetup;
