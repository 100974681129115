const DropDownNotification = () => {
    return (
      <div
        className="dropdown-menu dropdown-menu-right notification-dropdown rtl-ps-none"
        aria-labelledby="dropdownNotification"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <div className="dropdown-item d-flex">
          <div className="notification-icon">
            <i className="i-Speach-Bubble-6 text-primary me-1"></i>
          </div>
          <div className="notification-details flex-grow-1">
            <p className="m-0 d-flex align-items-center">
              <span>New message</span>
              <span className="badge rounded-pill text-bg-primary ms-1 me-1">
                new
              </span>
              <span className="flex-grow-1"></span>
              <span className="text-small text-muted ms-auto">10 sec ago</span>
            </p>
            <p className="text-small text-muted m-0">James: Hey! are you busy?</p>
          </div>
        </div>
        <div className="dropdown-item d-flex">
          <div className="notification-icon">
            <i className="i-Receipt-3 text-success me-1"></i>
          </div>
          <div className="notification-details flex-grow-1">
            <p className="m-0 d-flex align-items-center">
              <span>New order received</span>
              <span className="badge rounded-pill text-bg-success ms-1 me-1">
                new
              </span>
              <span className="flex-grow-1"></span>
              <span className="text-small text-muted ms-auto">2 hours ago</span>
            </p>
            <p className="text-small text-muted m-0">1 Headphone, 3 iPhone x</p>
          </div>
        </div>
        <div className="dropdown-item d-flex">
          <div className="notification-icon">
            <i className="i-Empty-Box text-danger me-1"></i>
          </div>
          <div className="notification-details flex-grow-1">
            <p className="m-0 d-flex align-items-center">
              <span>Product out of stock</span>
              <span className="badge rounded-pill text-bg-danger ms-1 me-1">3</span>
              <span className="flex-grow-1"></span>
              <span className="text-small text-muted ms-auto">10 hours ago</span>
            </p>
            <p className="text-small text-muted m-0">
              Headphone E67, R98, XL90, Q77
            </p>
          </div>
        </div>
        <div className="dropdown-item d-flex">
          <div className="notification-icon">
            <i className="i-Data-Power text-success me-1"></i>
          </div>
          <div className="notification-details flex-grow-1">
            <p className="m-0 d-flex align-items-center">
              <span>Server Up!</span>
              <span className="badge rounded-pill text-bg-success ms-1 me-1">
                3
              </span>
              <span className="flex-grow-1"></span>
              <span className="text-small text-muted ms-auto">14 hours ago</span>
            </p>
            <p className="text-small text-muted m-0">
              Server rebooted successfully
            </p>
          </div>
        </div>
      </div>
    );
}
 
export default DropDownNotification;