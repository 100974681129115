import { useEffect, useState } from "react";
import BreadCrumb from "../../../components/layout/BreadCrumb";
import Footer from "../../../components/layout/Footer";
import { useSelector } from "react-redux";
import AppraisalService from "../../../services/AppraisalService";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";

const AppraisalEvaluation = () => {
  const appraisalService = AppraisalService();
  const { evaluationData, loading } = useSelector((state) => state.appraisal);
  const [openTableOptions, setOpenTableOptions] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await appraisalService.getEvaluationData();
    })();
  }, []);

  const handleOpenTableOptions = () => {
    if (openTableOptions === '') {
      return setOpenTableOptions('open');
    }

    return setOpenTableOptions('');
  }

  const handleOnRowClick = (employeeId) => {
    const appraisalId = evaluationData.id;
    navigate('../appraisal/evaluation/employee', { replace: false, state: { employeeId, appraisalId } });
  }

  const getTableRows = (evaluationData) => {
    let counter = 0;

    return evaluationData?.evaluations.map((evaluation) => {
      return (
        <tr key={evaluation['employee_id']} style={{cursor: 'pointer'}} onClick={(event) => handleOnRowClick(evaluation['employee_id'])}>
          <td>{++counter}</td>
          <td style={{ position: 'sticky', zIndex: '10', left: '0', backgroundColor: 'green', color: 'white', textAlign: 'left' }}>{evaluation['employee_name']}</td>
          <td  className="text-bg-light" style={{ textAlign: 'left' }}>{evaluation['supervisor']}</td>
          <td>{evaluation['Self Review']}</td>
          <td>{evaluation['Actual Score Technical']}</td>

          <td>{(typeof evaluation['People'] === 'undefined') ? 0 : evaluation['People']['Actual Score']}</td>
          <td>{(typeof evaluation['People'] === 'undefined') ? 0 : evaluation['People']['Total Score']}</td>
          <td>{(typeof evaluation['People'] === 'undefined') ? 0 : evaluation['People']['Weight']}</td>
          <td style={tdStyle}>{(typeof evaluation['People'] === 'undefined') ? 0 : evaluation['People']['Final Percentage Score']}</td>

          <td>{(typeof evaluation['Finance'] === 'undefined') ? 0 : evaluation['Finance']['Actual Score']}</td>
          <td>{(typeof evaluation['Finance'] === 'undefined') ? 0 : evaluation['Finance']['Total Score']}</td>
          <td>{(typeof evaluation['Finance'] === 'undefined') ? 0 : evaluation['Finance']['Weight']}</td>
          <td style={tdStyle}>{(typeof evaluation['Finance'] === 'undefined') ? 0 : evaluation['Finance']['Final Percentage Score']}</td>

          <td>{(typeof evaluation['Internal Process'] === 'undefined') ? 0 : evaluation['Internal Process']['Actual Score']}</td>
          <td>{(typeof evaluation['Internal Process'] === 'undefined') ? 0 : evaluation['Internal Process']['Total Score']}</td>
          <td>{(typeof evaluation['Internal Process'] === 'undefined') ? 0 : evaluation['Internal Process']['Weight']}</td>
          <td style={tdStyle}>{(typeof evaluation['Internal Process'] === 'undefined') ? 0 : evaluation['Internal Process']['Final Percentage Score']}</td>

          <td>{(typeof evaluation['Client Satisfaction/Development'] === 'undefined') ? 0 : evaluation['Client Satisfaction/Development']['Actual Score']}</td>
          <td>{(typeof evaluation['Client Satisfaction/Development'] === 'undefined') ? 0 : evaluation['Client Satisfaction/Development']['Total Score']}</td>
          <td>{(typeof evaluation['Client Satisfaction/Development'] === 'undefined') ? 0 : evaluation['Client Satisfaction/Development']['Weight']}</td>
          <td style={tdStyle}>{(typeof evaluation['Client Satisfaction/Development'] === 'undefined') ? 0 : evaluation['Client Satisfaction/Development']['Final Percentage Score']}</td>

          <td>{(typeof evaluation['Technical Score Weighting'] === 'undefined') ? 0 : evaluation['Technical Score Weighting']}</td>
          <td>{(typeof evaluation['Behavioral Competence'] === 'undefined') ? 0 : evaluation['Behavioral Competence']['Actual Score']}</td>
          <td>{(typeof evaluation['Behavioral Competence'] === 'undefined') ? 0 : evaluation['Behavioral Competence']['Total Score']}</td>
          <td>{(typeof evaluation['Behavioral Competence'] === 'undefined') ? 0 : evaluation['Behavioral Competence']['Weight']}</td>
          <td style={tdStyle}>{(typeof evaluation['Behavioral Competence'] === 'undefined') ? 0 : evaluation['Behavioral Competence']['Final Percentage Score']}</td>

          <td style={{ backgroundColor: 'green', color: 'white' }}>{evaluation['Overall Score']}</td>
        </tr>
      )
    })
  };

  const tdStyle = {
    backgroundColor: 'yellow',
  }

  return (
    <div className="main-content">
      <BreadCrumb
        title="Appraisal"
        parentLink="#"
        parentTitle="Pages"
        currentTitle="Evaluation"
      />
      <div className="separator-breadcrumb border-top"></div>
      
      <div className="row">
        <div className="col-lg-12">
          <div className="card 0-hidden mb-4">
            <div className="card-header d-flex align-items-center border-0">
              <h5 className="w-50 float-start card-title m-0">{evaluationData?.name}</h5>
            </div>

            {
              (loading) ? <Loader /> :
                <div className="card-body">
                  <div className="table-responsive mt-3" style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', height: '600px', textAlign: 'center' }}>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col" style={{ position: 'sticky', zIndex: '9', left: '0', backgroundColor: 'green', color: 'white' }}>Name</th>
                          <th scope="col" className="text-bg-light">Supervisor</th>
                          <th scope="col">Self Review</th>
                          <th scope="col">Actual Score Technical</th>
                          <th scope="col">People Actual Score</th>
                          <th scope="col">People Total Score</th>
                          <th scope="col">People Weight</th>
                          <th scope="col" style={tdStyle}>People Final Percentage Score</th>
                          <th scope="col">Financial Actual Score</th>
                          <th scope="col">Financial Total Score</th>
                          <th scope="col">Financial Weight</th>
                          <th scope="col" style={tdStyle}>Financial Final Percentage Score</th>
                          <th scope="col">Internal Process Actual Score</th>
                          <th scope="col">Internal Process Total Score</th>
                          <th scope="col">Internal Process Weight</th>
                          <th scope="col" style={tdStyle}>Internal Process Final Percentage Score</th>
                          <th scope="col">Client Satisfaction Actual Score</th>
                          <th scope="col">Client Satisfaction Total Score</th>
                          <th scope="col">Client Satisfaction Weight</th>
                          <th scope="col" style={tdStyle}>Client Satisfaction Final Percentage Score</th>
                          <th scope="col">Technical Score Weighting</th>
                          <th scope="col">Actual Score Behavioral</th>
                          <th scope="col">Behavioral Competence Total Score</th>
                          <th scope="col">Behavioral Competence Weight</th>
                          <th scope="col" style={tdStyle}>Behavioral Competence Percentage Score</th>
                          <th scope="col" style={{ backgroundColor: 'green', color: 'white' }}>Overall Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (typeof evaluationData === 'object' && Object.entries(evaluationData).length > 0) &&
                          getTableRows(evaluationData)
                        }
                      </tbody>
                      <tfoot>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col" style={{ position: 'sticky', zIndex: '9', left: '0', backgroundColor: 'green', color: 'white' }}>Name</th>
                          <th scope="col" className="text-bg-light">Supervisor</th>
                          <th scope="col">Self Review</th>
                          <th scope="col">Actual Score Technical</th>
                          <th scope="col">People Actual Score</th>
                          <th scope="col">People Total Score</th>
                          <th scope="col">People Weight</th>
                          <th scope="col" style={tdStyle}>People Final Percentage Score</th>
                          <th scope="col">Financial Actual Score</th>
                          <th scope="col">Financial Total Score</th>
                          <th scope="col">Financial Weight</th>
                          <th scope="col" style={tdStyle}>Financial Final Percentage Score</th>
                          <th scope="col">Internal Process Actual Score</th>
                          <th scope="col">Internal Process Total Score</th>
                          <th scope="col">Internal Process Weight</th>
                          <th scope="col" style={tdStyle}>Internal Process Final Percentage Score</th>
                          <th scope="col">Client Satisfaction Actual Score</th>
                          <th scope="col">Client Satisfaction Total Score</th>
                          <th scope="col">Client Satisfaction Weight</th>
                          <th scope="col" style={tdStyle}>Client Satisfaction Final Percentage Score</th>
                          <th scope="col">Technical Score Weighting</th>
                          <th scope="col">Actual Score Behavioral</th>
                          <th scope="col">Behavioral Competence Total Score</th>
                          <th scope="col">Behavioral Competence Weight</th>
                          <th scope="col" style={tdStyle}>Behavioral Competence Percentage Score</th>
                          <th scope="col" style={{ backgroundColor: 'green', color: 'white' }}>Overall Score</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
            }
            
          </div>
        </div>
      </div>
      {/* end of main-content */}
      {/* Footer Start */}
      <Footer />
      {/* fotter end */}

      <div className={`customizer ${openTableOptions}`}>
        <div className="handle" onClick={handleOpenTableOptions}><i className="i-Gear spin"></i></div>
        <div className="customizer-body ps" data-perfect-scrollbar="" data-suppress-scroll-x="true">
          <div className="accordion" id="accordionCustomizer">

          <div className="card">
            <div className="card-header" id="headingOne">
              <p className="mb-0">Settings</p>
            </div>
            <div className="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionCustomizer">
              <div className="card-body">
                <div className="colors sidebar-colors">
                <a className="dropdown-item" href="#">Download Appraisal</a>
                </div>
              </div>
            </div>
          </div>

            <div className="card">
              <div className="card-header" id="headingOne">
                <p className="mb-0">Table Options</p>
              </div>
              <div className="collapse show" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
                <div className="card-body">
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Supervisor</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>People Actual Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>People Total Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>People Weight</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Financial Actual Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Financial Total Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Financial Weight</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Internal Process Actual Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Internal Process Total Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Internal Process Weight</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Client Satisfaction Actual Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Client Satisfaction Total Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Client Satisfaction Weight</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Technical Score Weighting</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Actual Score Behavioral</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Behavioral Competence Total Score</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Behavioral Competence Weight</span><span className="checkmark"></span>
                  </label>
                  <label className="checkbox checkbox-primary">
                    <input id="rtl-checkbox" type="checkbox" /><span>Overall Score</span><span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="ps__rail-x" style={{left: "0px", bottom: "0px"}}>
            <div className="ps__thumb-x" tabIndex="0" style={{left: "0px", width: "0px"}}>
            </div>
          </div>
          <div className="ps__rail-y" style={{top: "0px", right: "0px"}}>
            <div className="ps__thumb-y" tabIndex="0" style={{top: "0px", height: "0px"}}>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppraisalEvaluation;
