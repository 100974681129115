import DropDownNotification from "./DropDownNotification";

const HeaderNotification = () => {
    return (
      <div className="dropdown">
        <div
          className="badge-top-container"
          role="button"
          id="dropdownNotification"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="badge bg-primary">3</span>
          <i className="i-Bell text-muted header-icon"></i>
        </div>

        {/* Notification dropdown */}
        <DropDownNotification />
      </div>
    );
}
 
export default HeaderNotification;