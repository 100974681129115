import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../Loader";
import AppraisalService from "../../services/AppraisalService";
import SingleKPI from "./SingleKPI";
import isObjectEmpty from "../../utils/functions";
import { AppraisalLegend } from "./AppraisalLegend";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AppraisalTabs() {
  const [activeCardinalPos, setActiveCardinalPos] = useState(0);
  const [activeKRAPos, setActiveKRAPos] = useState(0);
  const { loading = true, userAppraisal = {} } = useSelector( (state) => state.appraisal );
  const appraisalService = AppraisalService();
  const [show, setShow] = useState(false);
  const cardinals = appraisalService.getCardinals(userAppraisal);
  const keyResponsibilityAreas = appraisalService.getKeyResponsibilityArea(userAppraisal, cardinals[activeCardinalPos]);
  const keyPerformanceIndices = appraisalService.getKeyPerformanceIndices(
    userAppraisal, 
    cardinals[activeCardinalPos], 
    keyResponsibilityAreas[activeKRAPos]
  );
  const ratedKeyPerformanceIndices = appraisalService.countRatedKeyPerformanceIndices(userAppraisal);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const countAllKeyPerformanceIndices = appraisalService.countKeyPerformanceIndices(userAppraisal);

  //fetch the user appraisal once
  useEffect(() => {
    (async () => {
      await appraisalService.getEmployeeAppraisal(user?.data.employee.id);
    })();

  }, []);

  const handleOnSave = (e) => {
    (async () => {
      await appraisalService.saveEmployeeAppraisal(userAppraisal, user?.data.employee.id, 'saveEmployeeRatings') }
    )();
  }

  const handleOnSubmit = (e) => {
    handleClose();

    (async () => {
      await appraisalService.submitEmployeeAppraisal(userAppraisal?.id, user?.data.employee.id) }
    )();
  }

  const handleShowModal = () => setShow(true);
  const handleClose = () => setShow(false);

  const onSelectCardinal = (e, index, c) => {
    e.preventDefault();
    setActiveCardinalPos(index);
  };

  const onSelectKra = (e, index, k) => {
    e.preventDefault();
    setActiveKRAPos(index);
  };

  return (
    <div id="SectionC" className="col-lg-12">
      {
        loading ? (<Loader />) : (
          <div className="col-md-12 mb-4">
            <div className="card text-start">
              <div className="card-body">
                <div className="card-title">
                  {
                    (userAppraisal.is_self_appraisal_closed) &&
                    <div className="alert alert-dismissible fade show alert-warning" role="alert">
                      <strong className="text-capitalize">Warning!</strong> <span>Self appraisal is closed.</span>
                      <button className="btn btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                  }
                </div>
                <ul className="nav nav-tabs mb-3" id="myIconTab" role="tablist">
                  {
                    cardinals &&
                    cardinals?.map((c, index) => {
                      return (
                        <li
                          className="nav-item"
                          key={index}
                          onClick={(e) => onSelectCardinal(e, index, c)}
                        >
                          <a
                            className={`nav-link ${index === activeCardinalPos ? "active" : ""}`}
                            id="profile-icon-tab"
                            data-bs-toggle="tab"
                            href="#profileIcon"
                            role="tab"
                            aria-controls="profileIcon"
                            aria-selected="false"
                          >
                            <i className="nav-icon i-Home1 me-1"></i> {c}
                          </a>
                        </li>
                      );
                    })
                  }
                </ul>
                <div className="tab-content" id="myIconTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="homeIcon"
                    role="tabpanel"
                    aria-labelledby="home-icon-tab"
                  >
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="mb-4">
                          <span className={`badge ${((countAllKeyPerformanceIndices - ratedKeyPerformanceIndices) > (countAllKeyPerformanceIndices / (countAllKeyPerformanceIndices - ratedKeyPerformanceIndices))) ? 'bg-danger' : 'indigo-700'} p-2 m-1`}>
                            {`${ratedKeyPerformanceIndices} of ${countAllKeyPerformanceIndices} KPIs rated`}
                          </span>
                        </div>
                        <strong className="mb-3">
                          Select Key Responsibility Area (KRA)
                        </strong>
                        <div className="list-group">
                          {
                            userAppraisal &&
                            keyResponsibilityAreas?.map((kra, index) => {
                              return (
                                <button
                                  className={`list-group-item list-group-item-action ${index === activeKRAPos ? "active" : ""
                                    }`}
                                  type="button"
                                  key={index}
                                  onClick={(event) =>
                                    onSelectKra(event, index, kra)
                                  }
                                >
                                  {kra}
                                </button>
                              );
                            })
                          }
                        </div>
                        <AppraisalLegend />
                      </div>

                      <div className="col-lg-10">
                        <>
                          {
                            (userAppraisal.is_self_appraisal_closed) ? <></> :
                            (
                              <div className="row row-xs" style={{ marginBottom: "25px" }}>
                                <div className="col-md-5">
                                  <span>Please evaluate<strong> ALL </strong>the following KPIs</span>
                                </div>
                                <div className="col-md-5 mt-3 mt-md-0">
                                  <button className="btn btn-md btn-primary btn-icon" type="button" onClick={(e) => handleOnSave(e)} disabled={(userAppraisal.submission_status?.is_self_submitted) || (userAppraisal.is_self_appraisal_closed)}>
                                    <span className="ul-btn__icon"><i className="i-Disk"></i></span><span className="ul-btn__text"> Save</span>
                                  </button>
                                </div>
                                <div className="col-md-2 mt-3 mt-md-0">
                                  <button className="btn btn-md btn-danger btn-icon text-white" type="button" onClick={handleShowModal} disabled={(countAllKeyPerformanceIndices > ratedKeyPerformanceIndices) || (userAppraisal.submission_status?.is_self_submitted) || (userAppraisal.is_self_appraisal_closed)}>
                                    <span className="ul-btn__icon"><i className="i-Data-Save"></i></span><span className="ul-btn__text"> Submit</span>
                                  </button>
                                </div>
                              </div>
                            )
                          }
                        </>
                        <>
                          {
                            keyPerformanceIndices.map((keyPerformanceIndex, kpiIndex) => (
                              <SingleKPI
                                key={kpiIndex}
                                kpiIndex={kpiIndex}
                                keyPerformanceIndex={keyPerformanceIndex}
                                cardinal={cardinals[activeCardinalPos]}
                                kra={keyResponsibilityAreas[activeKRAPos]}
                              />
                            ))
                          }
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Modal */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title><h5>Are you sure you want to submit your appraisal?</h5></Modal.Title>
              </Modal.Header>
              <Modal.Body>Note that once submitted you cannot edit your appraisal again!</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleOnSubmit}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </div>         

        )}
    </div>    
  )
  
}

export default AppraisalTabs;
