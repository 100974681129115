import Footer from "../components/layout/Footer";
import AppraisalTabs from "../components/appraisal/AppraisalTabs";
import BreadCrumb from "../components/layout/BreadCrumb";
import TTLCountdown from "../components/TTLCountdown";

const SelfAppraisal = () => {
  return (
    <div className="main-content">
      <BreadCrumb
        title="Appraisal"
        parentLink="#"
        parentTitle="Pages"
        currentTitle="Appraisal"
      />
      <div className="separator-breadcrumb border-top"></div>
      <div data-sidebar-content="secondary" className="sidebar-content">
        <div className="inbox-secondary-sidebar-content position-relative" style={{ minHeight: "500px" }} >
          {/* Appraisal Tab  */}
          <AppraisalTabs />
        </div>
      </div>

      {/* Footer Start */}
      <TTLCountdown />
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default SelfAppraisal;
