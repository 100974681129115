import React from 'react';

const TabSwitcher = ({ activeTab, setActiveTab, children }) => {
  return (
    <div className="col-md-12 p-2 mt-2">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
            onClick={() => setActiveTab('pending')}
            style={{ cursor: 'pointer' }}
          >
            Pending Approvals
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'approval' ? 'active' : ''}`}
            onClick={() => setActiveTab('approval')}
            style={{ cursor: 'pointer' }}
          >
            Approval Requests
          </a>
        </li>
      </ul>

      {/* Render the content of the active tab */}
      <div className="tab-content" id="profileTabContent">
        {children.map((child, index) => (
          <div key={index} className={`tab-pane ${activeTab === child.props.label ? 'active' : ''}`}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabSwitcher;

