import React, { useState, useEffect } from 'react';
import AxiosService from '../../../services/AxiosService';
import BreadCrumb from "../../../components/layout/BreadCrumb";
import Footer from "../../../components/layout/Footer";
import DataTable from '../../../components/tables/reactTable';
import ModalComponent from '../../../components/modals/modal';
import { serverUrl } from '../../../utils/constants';
import Select from 'react-select';
import TableDropDown from '../../../components/requisition/dropDown';
import NoRecords from '../../../components/requisition/not-found';
import CardinalService from '../../../services/CardinalService';
import Loader from '../../../components/Loader';
import { useSelector } from 'react-redux';

const KPISetup = () => {
  const cardinalService = CardinalService();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCardinal, setSelectedCardinal] = useState('');
  const [kraDetails, setKraDetails] = useState([]);
  const [selectedKra, setSelectedKra] = useState('');
  const [designationDetails, setDesignationDetails] = useState([]);
  const [kpiDetails, setKpiDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isCardinalLoading = false, cardinals = [] } = useSelector((state) => state.cardinal );

  const handleAddKpi = () => {
    setIsAddModalOpen(true);
  };

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  useEffect(() => {
    (async () => { await cardinalService.getCardinals(); })();
  }, []);

  const handleCardinalChange = (selectedOption) => {
    setSelectedCardinal(selectedOption);
    const selectedCardinal = cardinals?.find((cardinal) => cardinal.id === selectedOption.value);
    setSelectedKra('');
    const kraDetails = selectedCardinal?.key_responsibility_areas || [];
    setKraDetails(kraDetails);
  };

  const handleKraChange = async (selectedOption) => {
    setLoading(true);
    setSelectedKra(selectedOption);
    const chosenCardinal = selectedCardinal?.label;
    const kra = selectedOption?.label;
    const cardinal = cardinals?.filter((cardinal) => {
      return cardinal.name === chosenCardinal;
    });

    const keyResponsibilityArea = cardinal[0]?.key_responsibility_areas.filter((item) => {
      return item.name === kra;
    });

    // Fetch designation options
    const designationResponse = await AxiosService.get(`${serverUrl}/admin/designations`);
    const designationOptions = designationResponse.data;
    setDesignationDetails(designationOptions);

    setLoading(false);

    return setKpiDetails(keyResponsibilityArea[0]?.key_performance_indices);
  };

  const isKraDropdownDisabled = !selectedCardinal;
  const isDesignationDropdownDisabled = !selectedKra;

  const KPIData = kpiDetails.map((kpi, index) => ({ ...kpi, index: ++index, }));

  const getTableRecord = () => {
    if (KPIData?.length === 0) {
      return <NoRecords />
    }

    return <DataTable data={KPIData} columns={columns} />
  }

  const columns = [
    {
      label: "S/N",
      render: (kpi) => kpi.index,
      sortFunction: () => (item) => item.index,
    },

    {
      label: "Name",
      render: (kpi) => `${kpi.name}`,
      sortFunction: () => (item) => item.name,
    },
    {
      label: "Designation",
      render: (kpi) => `${kpi?.designation.name || ''}`,
      sortFunction: () => (item) => item.designation.name,
    },
    {
      label: "Performance Expectation",
      render: (kpi) => `${kpi?.performance_expectation || ''}`,
      sortFunction: () => (item) => item.performance_expectation,
    },
    {
      label: "Description",
      render: (kpi) => `${kpi?.description || ''}`,
      sortFunction: () => (item) => item.description,
    },
    {
      label: "Actions",
      render: (kpi) => (
        <TableDropDown
          options={[
            { label: "Edit", action: () => handleEditClick(kpi) },
            { label: "Delete", action: () => { } },
          ]}
        />
      ),
    }
  ];

  return (
    <div className="main-content">
      <BreadCrumb
        title="Appraisal"
        parentLink="#"
        parentTitle="Pages"
        currentTitle="KPI Setup"
      />

      <div className="separator-breadcrumb border-top"></div>
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-header d-flex align-items-center">
              <h3 className="w-50 float-start card-title m-0">List of Key Performance Indices</h3>
              <div className="dropdown dropleft text-end w-50 float-end">
                <button className="btn bg-primary text-white" type="button" aria-haspopup="true" onClick={handleAddKpi}>
                  <i className="nav-icon i-Upload text-white"></i> Add Key Responsibility Area
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group mb-5 col-md-6">
                  <label htmlFor="cardinalType" className="form-label">
                    Select Cardinal:
                  </label>
                  <Select
                    id="cardinalType"
                    options={cardinals?.map((cardinal) => ({ value: cardinal.id, label: cardinal.name }))}
                    onChange={handleCardinalChange}
                    value={selectedCardinal}
                    isSearchable
                  />
                </div>
                <div className="form-group mb-5 col-md-6">
                  <label htmlFor="krasType" className="form-label">
                    Select KRA:
                  </label>
                  <Select
                    id="krasType"
                    options={kraDetails.map((kra) => ({ value: kra.id, label: kra.name }))}
                    isSearchable
                    isDisabled={isKraDropdownDisabled}
                    onChange={handleKraChange}
                    value={selectedKra}
                  />
                </div>
                <div className="table-responsive">
                  <div className="table-responsive">
                    {isCardinalLoading || loading ? <Loader /> : getTableRecord()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of main-content */}
      {/* Modal Component */}
      <ModalComponent
        isOpen={isAddModalOpen}
        onRequestClose={() => setIsAddModalOpen(false)}
        title={`Add New KPI`}
        content={(
          <>
            <form>
              <div className="form-group">
                <label htmlFor="cardinal">Select Cardinal: *</label>
                <Select
                  id="addcardinalType"
                  options={cardinals?.map((cardinal) => ({ value: cardinal.id, label: cardinal.name }))}
                  isSearchable
                  onChange={handleCardinalChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="kra">Select KRA: *</label>
                <Select
                  id="addkrasType"
                  options={kraDetails.map((kra) => ({ value: kra.id, label: kra.name }))}
                  isSearchable
                  isDisabled={isKraDropdownDisabled}
                  onChange={handleKraChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="designation">Select Designation: *</label>
                <Select
                  id="adddesignationType"
                  options={designationDetails?.map((designation) => ({
                    value: designation.id,
                    label: designation.name,
                  }))}
                  isSearchable
                  isDisabled={isDesignationDropdownDisabled}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Name: *</label>
                <input type="text" id="name" name="name" className="form-control" required />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description: </label>
                <input type="text" id="description" name="description" className="form-control" required />
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">Save</button>
              </div>
            </form>
          </>
        )}
      />
      {selectedRecord && (
        <ModalComponent
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          title={`Edit Kpi`}
          content={(
            <>
              <form>
                <div className="form-group">
                  <label htmlFor="editcardinal">Select Cardinal: *</label>
                  <Select
                    options={cardinals?.map((cardinal) => ({ value: cardinal.id, label: cardinal.name }))}
                    defaultValue={selectedCardinal}
                    isSearchable
                  />

                </div>
                <div className="form-group">
                  <label htmlFor="editkra">Select KRA: *</label>
                  <Select
                    options={kraDetails.map((kra) => ({ value: kra.id, label: kra.name }))}
                    defaultValue={selectedKra}
                    isSearchable
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editdesignation">Select Designation: *</label>
                  <Select
                    options={designationDetails.map((designation) => ({ value: designation.id, label: designation.name }))}
                    defaultValue={{ value: selectedRecord.designation.id, label: selectedRecord.designation.name }}
                    isSearchable
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editName">Name: *</label>
                  <input
                    type="text"
                    id="editName"
                    name="editName"
                    className="form-control"
                    defaultValue={selectedRecord.name}
                    required />
                </div>
                <div className="form-group">
                  <label htmlFor="editDescription">Description: </label>
                  <input
                    type="text"
                    id="editDescription"
                    name="editDescription"
                    className="form-control"
                    defaultValue={selectedRecord.description}
                    required />
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Update</button>
                </div>
              </form>
            </>
          )}
        />
      )}
      {/* Footer Start */}
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default KPISetup;
