export default function getBadgeColorByStatus( status ) {
    switch (status) {
        case "pending":
          return "warning";
        case "declined":
          return "danger";
        case "approved":
          return "success"
        default:
          return "info";
      }
}