// Function to check if a value is numeric
const isNumeric = (value) => {
    const numericRegex = /^[0-9]+$/;
    return numericRegex.test(value);
};

const validateForm = (formData, validationConfig) => {
  const newValidationErrors = {};

  for (const field in validationConfig) {
    const config = validationConfig[field];
    const value = formData[field];

    if (config.required && !value) {
      newValidationErrors[field] = config.errorMessage ? config.errorMessage : 'Required';
    }

    if (config.type === 'number') {
      // Check if the value is not empty
      if (!value || value.trim() === '') {
        newValidationErrors[field] = 'Enter numbers';
      } else {
        // Check if the value contains only numeric characters
        const numericRegex = /^[0-9]+$/;
        if (!numericRegex.test(value)) {
          newValidationErrors[field] = 'Enter valid number';
        }
      }
    }

    // Handle 'dropzone' type validation
    if (config.type === 'dropzone') {
      if (Array.isArray(value) && value.length === 0) {
        newValidationErrors[field] = config.errorMessage;
      }
    }
  }

  return newValidationErrors;
};


const validationUtils = {
    isNumeric,
    validateForm,
};

export default validationUtils;
