import React from 'react';
import Modal from 'react-modal';

const ModalComponent = ({ isOpen, onRequestClose, title, content }) => {

    
return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    className="modal-container"
    overlayClassName="modal-overlay"
    ariaHideApp={false}
    >
    <div>
        <div className="modal-header">
        <h4 className="modal-title">
            {title}
        </h4>
        <button className="close-button" onClick={onRequestClose}>
            Close 
        </button>
        </div>
        <div className="modal-content">
        {content}
        </div>
    </div>
    </Modal>
);

};

export default ModalComponent;
