import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../utils/constants";
import { toast } from "react-toastify";
import validationUtils from "../../components/validation/FormValidation";
import Footer from "../../components/layout/Footer";
import RequestService from "../../services/RequestService";
import Loader from "../../components/Loader";
import BreadCrumb from "../../components/layout/BreadCrumb";
import { useSelector } from "react-redux";
import FieldElements from "../../components/requisition/FieldElements";
import { CardHeader } from "../../components/cards/Headers";
import TTLCountdown from "../../components/TTLCountdown";

const RequestForm = () => {
  const { id } = useParams();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const accessToken = JSON.parse(sessionStorage.getItem("token"));
  const [fileUploadedState, setFileUploadedState] = useState({});
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { isRequestLoading = true, requisitionRequest = [] } = useSelector((state) => state.request);
  const navigate = useNavigate();
  const requestService = RequestService();
  const [isClaimSelected, setIsClaimSelected] = useState(false);

  useEffect(() => {
    (async () => {
      await requestService.getRequestForm(id);
      setIsLoading(false);
    })();
  }, []);

  const handleInputChange = (fieldId, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldId]: value, }));
    isClaim(fieldId, value);

    setValidationErrors((prevErrors) => ({ ...prevErrors, [fieldId]: '', }));
  };

  const handleFilesChange = (fieldId, validFiles) => {
    setUploadedFiles((prevUploadedFiles) => ({ ...prevUploadedFiles, [fieldId]: validFiles, }));

    setFileUploadedState((prevState) => ({ ...prevState, [fieldId]: validFiles.length > 0, }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationConfig = requestService.validate(requisitionRequest.attributes, fileUploadedState, isClaimSelected);

    const validationErrors = validationUtils.validateForm(formValues, validationConfig);
    setValidationErrors(validationErrors);


    if (Object.keys(validationErrors).length === 0) {
      try {
        setIsLoading(true);
        const headers = { Authorization: `Bearer ${accessToken}`, };

        const formDataToSend = new FormData();

        formDataToSend.append('employee_id', user.data.employee.id);

        if (requisitionRequest && requisitionRequest.id) {
          formDataToSend.append('request_id', requisitionRequest.id);
        }

        for (const fieldId of Object.keys(uploadedFiles)) {
          const files = uploadedFiles[fieldId];
          for (const file of files) {
            formDataToSend.append(fieldId, file);
          }
        }

        for (const fieldId of Object.keys(formValues)) {
          const value = formValues[fieldId];
          if (Array.isArray(value)) {
            formDataToSend.append(fieldId, value.map((item) => item.value).join(', '));
          }

          if (typeof value === 'object' && 'value' in value) {
            formDataToSend.append(fieldId, value.value);
          }

          if (!Array.isArray(value) && !(typeof value === 'object' && 'value' in value)) {
            formDataToSend.append(fieldId, value);
          }
        }

        const response = await axios.post(`${serverUrl}/requests`, formDataToSend, { headers });

        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          toast.success("Request saved successfully!");
          navigate('/requisition');
        } else {
          setValidationErrors(validationErrors);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      setIsLoading(false);
    }
  }

  const isClaim = (fieldId, value) => {
    const item = requisitionRequest.attributes.filter((item) => {
      return item.html_name === fieldId;
    });

    if (item[0] && item[0].name === 'Request type' && value.label === 'claim') return setIsClaimSelected(true);

    if (item[0] && item[0].name === 'Request type' && value.label === 'advance') return setIsClaimSelected(false);
  }

  return (
    <div className="main-content">
      <BreadCrumb
        title="Requests"
        parentLink="#"
        parentTitle="Pages"
        currentTitle="Requests Form"
      />

      <div className="separator-breadcrumb border-top"></div>
      <div className="row"></div>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="d-flex justify-content-between align-items-center mb-3"></div>
          {
            (isRequestLoading || isLoading) ? (<Loader />) :

              <div className="card mb-4">
                <div className="card-body">
                  <form encType="multipart/form-data">
                    <div className="row">
                      <div className="card-title mb-3"><CardHeader title={requisitionRequest?.name} /></div>
                      {
                        requisitionRequest?.attributes?.map((field) => (
                          <>
                            {
                              (field.name !== 'Comment') ?
                                <div className="col-lg-12 col-md col-sm form-group mb-3 mt-10" key={field.id}>
                                  <label htmlFor={`attribute_id_${field.id}`}>
                                    {field.name}{" "}
                                    {field?.is_required && (<span className="text-danger">*</span>)}

                                    {(field.name === 'Upload a document to support your request' && isClaimSelected) && (<span className="text-danger">*</span>)}

                                  </label>
                                  <FieldElements
                                    field={field}
                                    formValues={formValues}
                                    validationErrors={validationErrors}
                                    handleInputChange={handleInputChange}
                                    handleFilesChange={handleFilesChange}
                                    fileUploadedState={fileUploadedState}
                                  />
                                </div> :
                                <></>
                            }

                          </>
                        ))
                      }
                    </div>
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary m-1"
                        type="submit"
                        style={{ float: "right" }}
                        onClick={handleSubmit}
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
          }
        </div>
      </div>

      <TTLCountdown />
      <Footer />
    </div>
  );
};

export default RequestForm;
