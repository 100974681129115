import AppraisalService from "../../services/AppraisalService";

const SingleKPI = ({ keyPerformanceIndex = {}, cardinal, kra, kpiIndex }) => {
  const ratings = [1, 2, 3, 4, 5];
  const appraisalService = AppraisalService();
  const isKpiResponseSet = appraisalService.isKeyPerformanceIndexResponseSet(
    keyPerformanceIndex,
    "employeeAppraisal"
  );

  /*
  This method updates the store with the selected option
  */
  const handleRadioOnChange = (event) => {
    let value = event.target.value;
    appraisalService.updateKeyPerformanceIndex(
      { ...keyPerformanceIndex, employee_rating: parseInt(value) },
      kpiIndex,
      cardinal,
      kra
    );
  };

  /*
  This method updates the store with the justification by the employee
  */
  const handleInputChange = (event) => {
    const { value } = event.target;
    appraisalService.updateKeyPerformanceIndex(
      { ...keyPerformanceIndex, employee_justification: value },
      kpiIndex,
      cardinal,
      kra
    );
  };

  return (
    <div className="inbox-details perfect-scrollbar rtl-ps-none ps" data-suppress-scroll-x="true" key={keyPerformanceIndex.key_performance_index_id} >

      <div className="card mb-5" style={{ border: isKpiResponseSet ? "1px solid #ff4c51" : "1px solid #E0E0E0", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", }} >
        <div className="card-body">
          <p style={{ color: "#000466" }}>
            <strong>{keyPerformanceIndex.key_performance_index_name}</strong>
          </p>

          <form style={{overflow: "auto", boxSizing: "border-box", display: "block"}}>
            <>
              {keyPerformanceIndex.key_performance_index_performance_expectation && (
                <p className="text-muted">
                  Performance Expectation -{" "}
                  {keyPerformanceIndex.key_performance_index_performance_expectation ||
                    "-"}
                </p>
              )}

              <p>Rating</p>

              <div className="d-flex flex-row align-items-center">
                {keyPerformanceIndex &&
                  ratings.map((val, index) => (
                    <label className="ul-radio__position radio radio-primary" key={index}>
                      <input
                        type="radio"
                        className="radio radio-reverse radio-success"
                        id={`gridRadios_${val}_${keyPerformanceIndex?.key_performance_index_id}`}
                        name={`gridRadios_${val}`}
                        value={val}
                        checked={keyPerformanceIndex?.employee_rating == val}
                        onChange={handleRadioOnChange}
                      />
                      <span className="ul-form__radio-font ml-2" style={{ marginRight: "50px", color: "#000466" }}> {val} </span>
                      <span className="checkmark"></span>
                    </label>
                  ))}
              </div>

            </>

            <div className="mt-3">
              <label className="form-group" htmlFor="inputEmail3"> Justification </label>
              <div className="form-group">
                <textarea
                  className="form-control"
                  defaultValue={keyPerformanceIndex.employee_justification}
                  aria-label="With textarea"
                  disabled={!keyPerformanceIndex?.employee_rating}
                  name={`employee_justification_${keyPerformanceIndex.key_performance_index_id}`}
                  onChange={handleInputChange}
                  placeholder="Please enter justification for ranking here"
                  row={10}
                ></textarea>
              </div>
            </div>
          </form>

          {keyPerformanceIndex?.employee_rating &&
            (keyPerformanceIndex.employee_justification ? (
              <div>
                <i
                  className="text-20 text-white bg-secondary rounded-circle i-Yes m-2"
                  style={{ color: "#000466" }}
                ></i>
                <span style={{ color: "grey" }}>KPI completed</span>
              </div>
            ) : (
              <>
                <i className="text-20 i-Yes rounded-circle m-1" style={{ color: "#000466" }} ></i>
                <span style={{ color: "grey" }}> kindly include justification for KPI </span>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SingleKPI;
