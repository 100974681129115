import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { toast } from 'react-toastify';

const PayrollService = () => {

    const all = async () => {
        try {
            const response = await AxiosService.get(serverUrl + '/payrolls');
            toast.success("Payrolls fetched successfully!");
            return response;
        } catch (error) {
            toast.error("An error occurred!");
        }
    }

    const destroy = async (payroll) => {
        try {
            const response = await AxiosService.delete(serverUrl + '/payrolls/' + payroll.id);
            toast.success("Payroll deleted successfully!");
            return response;
        } catch (error) {
            toast.error("An error occurred!");
        }
    }

    const update = async ({ id, date_from, date_to, is_active, description }) => {
        try {
            const response = await AxiosService.patch(`${serverUrl}/payrolls/${id}`, { date_from, date_to, is_active, description });
            toast.success('Payroll updated successfully!');
        } catch (error) {
            toast.error("An error occurred!");
        }
    }

    return {
        all,
        destroy,
        update
    }
}

export default PayrollService;