import { renderers } from "./fields";
function getRenderer(type) {
  if (type === "spacer") {
    return () => {
      return (
        <div
          style={{
            background: "black",
            width: "100%",
            opacity: 0.25,
            height: "100px",
          }}
        >
          spacer
        </div>
      );
    };
  }

  return renderers[type] || (() => <div>No renderer found for {type}</div>);
}

const Field = (props) => {
  const { field, overlay, ...rest } = props;
  const { type } = field;

  const Component = getRenderer(type);

  let className = "canvas-field";
  if (overlay) {
    className += " overlay";
  }

  return (
    <div className={className}>
      <Component {...rest} />
    </div>
  );
};

export default Field;
