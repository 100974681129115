import { useEffect, useState } from 'react';
import Footer from '../components/layout/Footer';
import BreadCrumb from '../components/layout/BreadCrumb';
import HolidayService from '../services/HolidayService';
import { useSelector } from "react-redux";
import Loader from '../components/Loader';
import NoRecords from '../components/requisition/not-found';
import DataTable from '../components/tables/reactTable';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "../assets/css/custom/datepicker-styles.css";
import 'react-datepicker/dist/react-datepicker.css';

const AddHoliday = () => {
    const holidayService = HolidayService();
    const { isHolidayLoading = false, holidays = [] } = useSelector((state) => state.holiday);
    const [holiday, setHoliday] = useState({
        id: null,
        name: null,
        date: new Date(),
        isActive: false,
    });
    const [showEdit, setShowEdit] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        (async () => {
            await holidayService.get();
        })();
    }, []);

    const columns = [
        {
            label: "S/N",
            render: (holiday) => holiday.index,
            sortFunction: () => (item) => item.index,
        },

        {
            label: "Name",
            render: (holiday) => `${holiday.name}`,
            sortFunction: () => (item) => item.name,
        },
        {
            label: "Date",
            render: (holiday) => `${holiday.date}`,
            sortFunction: () => (item) => item.date,
        },
        {
            label: "is Active",
            render: (holiday) => (holiday?.is_active) ? 'Yes' : 'No',
            sortFunction: () => (item) => item.is_active,
        },
        {
            label: "Actions",
            render: (holiday) => (
                <>
                    <i
                        className="text-15 i-Pen-5 text-success"
                        style={{ cursor: "pointer" }}
                        title="Edit"
                        onClick={() => handleEdit(holiday)}
                    />
                </>
            ),
        },
    ];

    const getTableRecords = () => {
        if (holidays?.length === 0) return <NoRecords />;

        return <DataTable data={holidays} columns={columns} />
    }

    const handleClose = () => {
        setShowEdit(false);
    }

    const handleEdit = (holiday) => {
        setHoliday({
            id: holiday.id,
            name: holiday.name,
            date: holiday.date,
            isActive: holiday.is_active
        });

        setShowEdit(true);
    }

    const handleUpdate = () => {
        holidayService.update({...holiday, is_active: holiday.isActive});

        setShowEdit(false);
    }

    const handleCreate = () => {
        holidayService.create({...holiday, is_active: holiday.isActive});
        setShow(false);
    }

    const handleShowCreate = () => setShow(true);

    return (
        <div className="main-content">
            <BreadCrumb title="Add Holiday" parentLink="#" parentTitle="Settings" currentTitle="Add Holiday" />
            <div className="separator-breadcrumb border-top"></div>

            {
                isHolidayLoading ? <Loader /> :
                    (<div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="card o-hidden mb-4">
                                <div className="card-header d-flex align-items-center">
                                    <h3 className="w-50 float-start card-title m-0">Manage Holidays</h3>
                                    <div className="dropdown dropleft text-end w-50 float-end">
                                        <button
                                            className="btn bg-gray-100"
                                            id="dropdownMenuButton1"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            onClick={handleShowCreate}
                                        >
                                            <i className="nav-icon text-20 i-Add"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {getTableRecords()}
                                </div>
                            </div>
                        </div>
                    </div>)
            }

            {/* Modal to Edit requisition */}
            <Modal show={showEdit} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{holiday?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="form-group mb-3">
                            <label htmlFor="holidayName">name</label>
                            <input
                                className="form-control"
                                id="requestName"
                                type="text"
                                placeholder="Enter a request name"
                                onChange={e => setHoliday(prev => ({ ...prev, name: e.target.value }))}
                                value={(holiday?.name === null) ? '' : holiday?.name}
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="holidayName">name</label>
                            <DatePicker
                                selected={new Date(holiday?.date) ?? new Date() }
                                onChange={(selectedDate) => setHoliday(prev => ({...prev, date: selectedDate}))}
                                dateFormat="MMMM d, yyyy"
                                className="form-control"
                                name="holidayName"
                                selectsStart
                                startDate={new Date()}
                                isClearable
                                placeholderText="Please select a date"
                                closeOnScroll={true}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={50}
                                dropdownMode="select"
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label className="checkbox checkbox-primary">
                                <input 
                                    type="checkbox" 
                                    checked={holiday.isActive ? "checked" : ''} 
                                    onChange={(selected) => setHoliday(prev => ({...prev, isActive: selected.target.checked}))} 
                                />
                                <span>Activate</span>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleUpdate}> Update </Button>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to Create requisition */}
            <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Create a holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="form-group mb-3">
                            <label htmlFor="holidayName">name</label>
                            <input
                                className="form-control"
                                id="holidayName"
                                type="text"
                                placeholder="Enter a holiday name"
                                onChange={e => setHoliday(prev => ({ ...prev, name: e.target.value }))}
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="holidayName">name</label>
                            <DatePicker
                                selected={new Date() }
                                onChange={(selectedDate) => setHoliday(prev => ({...prev, date: selectedDate}))}
                                dateFormat="MMMM d, yyyy"
                                className="form-control"
                                name="holidayName"
                                selectsStart
                                startDate={new Date()}
                                isClearable
                                placeholderText="Please select a date"
                                closeOnScroll={true}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={50}
                                dropdownMode="select"
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label className="checkbox checkbox-primary">
                                <input 
                                    type="checkbox" 
                                    checked={holiday.isActive ? "checked" : ''} 
                                    onChange={(selected) => setHoliday(prev => ({...prev, isActive: selected.target.checked}))} 
                                />
                                <span>Activate</span>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCreate}> Create </Button>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                </Modal.Footer>
            </Modal>

            <Footer />
        </div>
    );

};

export default AddHoliday;
