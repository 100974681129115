export default function isObjectEmpty( obj ) {
    return Object.keys(obj).length > 0
}
// Formats date by removing time stamp, dd/mm/yyyy

export function formatDateWithoutTime(fullDate) {
    const parts = fullDate.split(' ');
    const datePart = parts[0];
    const [year, month, day] = datePart.split('-');
    return `${day}/${month}/${year}`;
}