import Footer from "../components/layout/Footer";
import BreadCrumb from "../components/layout/BreadCrumb";
import SettingsButton from "../components/commons/SettingsButton";
import TTLCountdown from "../components/TTLCountdown";

const Settings = () => {
  return (
    <div className="main-content">
      <BreadCrumb
        title="Settings"
        parentLink="#"
        parentTitle="Pages"
        currentTitle="Settings"
      />

      <div className="separator-breadcrumb border-top"></div>
      <div className="row">
        {/* Appraisal  settings*/}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Appraisal</div>
              <p className="text-small text-muted">
                Manage Appraisal settings across the application.
              </p>
              <div className="row">
                <SettingsButton
                  to="/settings/appraisal/setup"
                  title="Appraisal Setup"
                  subTitle="Manage Appraisal Setup"
                />
                <SettingsButton
                  to="/settings/appraisal/evaluation"
                  title="Appraisal Evaluation"
                  subTitle="Evaluate Employee Appraisals"
                />
                <SettingsButton
                  to="/settings/appraisal/cardinal/setup"
                  title="Appraisal Cardinals"
                  subTitle="Manage Appraisal Cardinals"
                />

                <SettingsButton
                  to="/settings/appraisal/key-responsibility-area/setup"
                  title="Key Responsibility Areas"
                  subTitle="Manage Key Responsibility areas"
                />

                <SettingsButton
                  to="/settings/appraisal/key-performance-index/setup"
                  title="Key Performance Indices"
                  subTitle="Manage Key Performance Indices"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Events */}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Events</div>
              <p className="text-small text-muted">
                Manage events settings across the application.
              </p>
              <div className="row">
                <SettingsButton title="Event Setup" subTitle="Manage Events" />
              </div>
            </div>
          </div>
        </div>

        {/* Quotes */}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Quotes</div>
              <p className="text-small text-muted">
                Manage quotes settings across the application.
              </p>
              <div className="row">
                <SettingsButton title="Quotes Setup" subTitle="Manage Quotes" />
              </div>
            </div>
          </div>
        </div>

        {/* Assets */}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Asset Management</div>
              <p className="text-small text-muted">
                Discover and manage assets and their related information.
              </p>
              <div className="row">
                <SettingsButton
                  title="Assets"
                  subTitle="View the list of assets"
                />
                <SettingsButton
                  title="Asset Types"
                  subTitle="Manage Asset types"
                />
                <SettingsButton
                  title="Ticket Priority"
                  subTitle="Setup ticket priority"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Tasks */}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Task Management</div>
              <p className="text-small text-muted">
                Manage tasks across the application.
              </p>
              <div className="row">
                <SettingsButton
                  to="/settings/delegation"
                  title="Ticket Delegation"
                  subTitle="Delegate tasks"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Employee Management */}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Employee</div>
              <p className="text-small text-muted">
                Manage employee records across application.
              </p>
              <div className="row">
                <SettingsButton
                  to="/settings/employee/list"
                  title="Employees"
                  subTitle="Manage all employees"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Holiday Setting */}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Holiday</div>
              <p className="text-small text-muted">
                Manage holiday setup across application.
              </p>
              <div className="row">
                <SettingsButton
                  to="/settings/holiday"
                  title="Holiday"
                  subTitle="Holiday List"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Leave Setting */}
        {/* <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Leave</div>
              <p className="text-small text-muted">
                Manage leave setup across application.
              </p>
              <div className="row">
                <SettingsButton
                  to="/leave-entitlement"
                  title="Leave"
                  subTitle="Leave Entitlement"
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* Requisition Setting */}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Requisition</div>
              <p className="text-small text-muted">
                Manage requisition setup across application.
              </p>
              <div className="row">
                <SettingsButton
                  to="/settings/requisition/list"
                  title="All requisitions"
                  subTitle="Manage All Requisitions"
                />
              </div>
            </div>
          </div>          
        </div>
        {/* Request Setting */}
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-title m-0">Request</div>
              <p className="text-small text-muted">
                Manage request inputs across the application.
              </p>
              <div className="row">
                <SettingsButton
                  to="/settings/request/input"
                  title="Request Inputs"
                  subTitle="Manage Inputs"
                />
              </div>
            </div>
          </div>          
        </div>

      </div>
      {/* end of main-content */}
      {/* Footer Start */}

      <TTLCountdown />
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default Settings;
