import { useEffect, useState } from "react";
import DelegationAssignment from "../components/delegation/DelegationAssignment";
import DelegationList from "../components/delegation/DelegationList";
import Footer from "../components/layout/Footer";
import Cookies from "js-cookie";
import axios from "axios";
import { serverUrl } from "../utils/constants";
import BreadCrumb from "../components/layout/BreadCrumb";

const Delegation = () => {
  const [showDelegationDetail, setShowDelegationModal] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState(0);
  const [delegations, setDelegations] = useState([]);
  useEffect(() => {
    let token = Cookies.get("access_token");
    //prepare header
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    async function fetchDelegations() {
      try {
        const response = await axios.get(serverUrl + "/delegations", config);
        const { data } = response.data;
        setDelegations(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDelegations();
  }, []);

  const handleDoubleClickEmployee = (delNo, empNo) => {
    setActiveEmployee(delegations[delNo].employees[empNo]);
    setShowDelegationModal(true);
  };

  const handleUpdateTaskBtn = () => {
    setShowDelegationModal(false);
  };

  return (
    <div className="main-content">
      <BreadCrumb
        title="Delegation"
        parentLink="#"
        parentTitle="Pages"
        currentTitle="Task Assignment"
      />
      <div className="separator-breadcrumb border-top"></div>
      <div className="row">
        {delegations &&
          !showDelegationDetail &&
          delegations.map((d, delNo) => {
            return (
              <div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {/* <div className="col-lg-3">
                        <strong>{d.name}</strong>
                        <p>{d.description}</p>
                      </div> */}
                      <div className="col-md-4 mb-3">
                        <div
                          class="tab-pane active show"
                          id="__g-widget-s7-tab1-content"
                        >
                          <div class="ul-widget-s7n">
                            <div class="ul-widget-s7__items mb-4">
                              <div class="ul-widget-s7__item-circle">
                                <p class="ul-vertical-line bg-primary"></p>
                              </div>
                              <div class="ul-widget-s7__item-text">
                                <strong>{d.name}</strong>
                                <p>{d.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        {
                          <DelegationList
                            delNo={delNo}
                            employees={d.employees}
                            handleDoubleClickEmployee={
                              handleDoubleClickEmployee
                            }
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="separator-breadcrumb border-top"></div>
              </div>
            );
          })}
        {/* display hidden form */}
        {showDelegationDetail && (
          <DelegationAssignment
            handleUpdateTaskBtn={handleUpdateTaskBtn}
            activeEmployee={activeEmployee}
          />
        )}
      </div>
      {/* end of main-content */}
      {/* Footer Start */}

      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default Delegation;
