import React, { useState, useEffect } from 'react';
import Footer from "../../../components/layout/Footer";
import DataTable from '../../../components/tables/reactTable';
import BreadCrumb from '../../../components/layout/BreadCrumb';
import Loader from '../../../components/Loader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormSwitch from "../../../components/form/formSwitch";
import { useSelector } from "react-redux";
import AppraisalService from '../../../services/AppraisalService';
import { uppercaseWord } from '../../../utils/helper';
import TTLCountdown from '../../../components/TTLCountdown';

const AppraisalSetup = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [appraisal, setAppraisal] = useState({});
    const [showMenu, setShowMenu] = useState({ dropLeft: '', ariaExpanded: false, dropdown: '' });
    const [show, setShow] = useState(false);
    const [newAppraisalName, setNewAppraisalName] = useState('');
    const {loading = false, appraisalList = []} = useSelector((state) => state.appraisal);
    const appraisalService = AppraisalService();

    const handleClose = () => {
        setShow(false);
        setIsModalOpen(false);
    }

    const handleShow = (event, appraisal) => {
        setShow(true);
        setAppraisal(appraisal);
    };

    useEffect(() => {
        (async () => {
            await appraisalService.getAppraisalList();
        })();
    }, []);

    const handleShowMenu = () => {
        if ((typeof showMenu.dropLeft === 'string' && showMenu.dropLeft.length !== 0)) {
            return setShowMenu({ dropLeft: '', ariaExpanded: false, dropdown: '' });
        }

        return setShowMenu({ dropLeft: 'show', ariaExpanded: true, dropdown: 'show' });
    }

    const handleAddClick = () => setIsModalOpen(!isModalOpen);

    const handleUpdateAppraisal = () => {
        handleClose();

        (async () => {
            await appraisalService.updateAppraisal(appraisal);
        })();
    }

    const handleCreateNewAppraisal = () => {
        if (typeof newAppraisalName === 'string' && newAppraisalName.length !== 0) {
            handleClose();

            (async () => {
                await appraisalService.store(newAppraisalName);
            })();
        }
    }

    const columns = [
        {
            label: 'S/N',
            render: (appraisal) => appraisal.index,
        },
        {
            label: 'Name',
            render: (appraisal) => uppercaseWord(appraisal.name),
        },
        {
            label: 'Date Created',
            render: (appraisal) => (new Date(appraisal.created_at)).toDateString(),
        },
        {
            label: 'Start Date',
            render: (appraisal) => appraisal.startDate,
        },
        {
            label: 'End Date',
            render: (appraisal) => appraisal.endDate,
        },
        {
            label: "Status",
            render: (appraisal) => (appraisal.is_active) ? 'Enabled' : 'Disabled',
        },
        {
            label: "Actions",
            render: (appraisal) => (
                <div>
                    <i
                        className="i-Pen-5 text-success"
                        style={{ cursor: "pointer" }}
                        title="Edit"
                        onClick={(event) => handleShow(event, appraisal)}
                    />
                    <i
                        className="i-Close-Window text-danger"
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        title="Delete"
                    />
                </div>
            ),
        },
    ];

    return (
        <div className="main-content">
            <BreadCrumb title="Settings" parentLink="#" parentTitle="Appraisal" currentTitle="Appraisal Setup" />
            <div className="separator-breadcrumb border-top"></div>
            <div className="row"></div>

            {
                (loading === true) ? <Loader /> :
                    <div className="col-md-12">
                        <div className="card 0-hidden mb-4">
                            <div className="card-header d-flex align-items-center border-0">
                                <h5 className="w-50 float-start card-title m-0">Appraisal Setup</h5>
                                <div className={`dropdown dropleft text-end w-50 float-end ${showMenu.dropLeft}`}>
                                    <button className="btn bg-primary" id="dropdownMenuButton1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded={showMenu.ariaExpanded} onClick={handleShowMenu}>
                                        <i className="nav-icon i-Gear-2 text-white"></i>
                                    </button>
                                    <div className={`dropdown-menu ${showMenu.dropdown}`} aria-labelledby="dropdownMenuButton1" x-placement="left-start" style={{ position: "absolute", willChange: "transform", top: "0px", left: "0px", transform: "translate3d(500px, 0px, 0px)" }}>
                                        <a className="dropdown-item" href="#" onClick={handleAddClick}>Add new appraisal</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive mt-5">
                                    <DataTable data={appraisalList} columns={columns} />
                                </div>
                            </div>
                        </div>
                    </div>
            }

            <Modal
                show={isModalOpen}
                onHide={handleAddClick}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{`Set up a new appraisal`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form>
                            <div className="form-group">
                                <label htmlFor="name">Name: <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="name" required onChange={(event) => { setNewAppraisalName(event.target.value) }} />
                            </div>
                        </form>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleAddClick}>Close</Button>
                    <Button variant="primary" onClick={handleCreateNewAppraisal}>Create</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{appraisal.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="form-group mb-3">
                            <label htmlFor="appraisalName">Appraisal name</label>
                            <input className="form-control" id="appraisalName" name="name" type="text" value={appraisal.name} onChange={(event) => { setAppraisal((prev) => ({ ...prev, name: event.target.value })) }} />
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="activateAppraisal">Activate appraisal</label>
                            <FormSwitch text="Activated" onChange={(event) => { setAppraisal((prev) => ({ ...prev, is_active: !prev.is_active })) }} checked={appraisal.is_active} />
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="selfAppraisal">Close self appraisal</label>
                            <FormSwitch text="Closed" onChange={(event) => { setAppraisal((prev) => ({ ...prev, is_self_appraisal_closed: !prev.is_self_appraisal_closed })) }} checked={appraisal.is_self_appraisal_closed} />
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="selfAppraisal">Close supervisor appraisal</label>
                            <FormSwitch text="Closed" onChange={(event) => { setAppraisal((prev) => ({ ...prev, is_supervisor_appraisal_closed: !prev.is_supervisor_appraisal_closed })) }} checked={appraisal.is_supervisor_appraisal_closed} />
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={handleUpdateAppraisal}>Update</Button>
                </Modal.Footer>
            </Modal>

            <TTLCountdown />
            <Footer />
        </div>
    );
};

export default AppraisalSetup;

