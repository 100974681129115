// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-card {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    min-height: 500px;
    position: relative;
    padding: 20px;
}
.form-input {
    font-size: 16px;
    width: 100%;
}
.error-text {
    color: red;
}

.custom-icon-input {
    padding-right: 25px; 
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px; 
    color: gray;
  }
  
  .custom-placeholder-icon {
    position: absolute;
    top: 50%;
    left: 10px; 
    transform: translateY(-50%);
    font-size: 20px; 
  }
  .card-min-height {
    min-height: 125px !important; 
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/custom/requisition-styles.css"],"names":[],"mappings":"AAAA;IACI,0EAA0E;IAC1E,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,yDAA+C;IAC/C,4BAA4B;IAC5B,iCAAiC;IACjC,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,2BAA2B;IAC3B,eAAe;EACjB;EACA;IACE,4BAA4B;EAC9B","sourcesContent":[".form-card {\n    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);\n    min-height: 500px;\n    position: relative;\n    padding: 20px;\n}\n.form-input {\n    font-size: 16px;\n    width: 100%;\n}\n.error-text {\n    color: red;\n}\n\n.custom-icon-input {\n    padding-right: 25px; \n    background-image: url('../../fonts/search.svg');\n    background-repeat: no-repeat;\n    background-position: right center;\n    background-size: 16px; \n    color: gray;\n  }\n  \n  .custom-placeholder-icon {\n    position: absolute;\n    top: 50%;\n    left: 10px; \n    transform: translateY(-50%);\n    font-size: 20px; \n  }\n  .card-min-height {\n    min-height: 125px !important; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
