import { useEffect, useState } from "react";
import DataTable from "../../components/tables/reactTable";
import Footer from "../../components/layout/Footer";
import RequestService from "../../services/RequestService";
import { useSelector } from "react-redux";
import getBadgeColorByStatus from "../../utils/badgeColor";
import Loader from "../../components/Loader";
import BreadCrumb from "../../components/layout/BreadCrumb";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import NoRecords from "../../components/requisition/not-found";
import getIconByFileURL from "../../utils/FileIcons";
import face1 from "../../assets/images/faces/1.jpg";
import EditRequestForm from "./EditRequestForm";
import { useNavigate } from "react-router-dom";
import { excerpt, uppercaseWord } from "../../utils/helper";
import TTLCountdown from "../../components/TTLCountdown";

const RequisitionHistory = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const requestService = RequestService();
  const { isRequestLoading = true, requestList = [] } = useSelector((state) => state.request);
  const [show, setShow] = useState(false);
  const [requisition, setRequisition] = useState({});
  const [requestValue, setRequestValue] = useState({});
  const [comment, setComment] = useState(null);
  const [isCommentEmpty, setIsCommentEmpty] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
  };

  const handleView = (requisition) => {
    setShow(true);
    setRequisition(requisition);
    parseRequisitionValue(requisition);
    setIsCommentEmpty((requisition?.comments?.length !== 0) ? true : false);
    setComment(null);
  }

  useEffect(() => {
    requestService.clear();

    (async () => {
      await requestService.getRequestByEmployeeIdAndCategory(user?.data.employee.id, 2);
    })();
  }, []);

  const handleEdit = (requisition) => {
    setComment(null);
    parseRequisitionValue(requisition);
    navigate(
      '/requisition/edit', 
      {
        state : {
          id:requisition.request_id,
          requestValues: JSON.parse(requisition?.request_value),
          employeeRequestId: requisition.employee_request_id,
        }
      }
  );
  }

  const parseRequisitionValue = (requisition) => {
    setRequestValue((Object.keys(requisition?.request_value).length !== 0) ? JSON.parse(requisition?.request_value) : requisition?.request_value);
  }

  const columns = [
    {
      label: "S/N",
      render: (requisition) => requisition.index,
      sortFunction: () => (item) => item.index,
    },
    {
      label: "Request Title",
      render: (requisition) => {
        return (typeof requisition?.request_value === 'string') ? excerpt(JSON.parse(requisition?.request_value)?.[0].value) : '';
      },
      sortFunction: () => (item) => item.name,
    },
    {
      label: "Policy",
      render: (requisition) => {
        if (requisition.require_modification) return <><i className="text-20 i-Danger text-danger"></i> {requisition.request_name} </>;
        return `${requisition.request_name}`;
      },
      sortFunction: () => (item) => item.name,
    },
    {
      label: "Request Type",
      render: (requisition) => `${requisition.request_type_name}`,
      sortFunction: () => (item) => item.name,
    },
    {
      label: "Status",
      render: (requisition) => (
        <span className={`badge bg-${getBadgeColorByStatus(requisition.state_type_name)}`}>
          {uppercaseWord(requisition.state_name)}
        </span>
      ),
      sortFunction: () => (item) => item.status,
    },
    {
      label: "Date/Time",
      render: (requisition) => {
        const isoDate = requisition.created_at;
        const dateObj = new Date(isoDate);
        const formattedDate = `${dateObj.toLocaleString("en-US", {
          month: "long",
        })} ${dateObj.getDate()}, ${dateObj.getFullYear()}, ${dateObj.toLocaleTimeString(
          "en-US"
        )}`;
        return formattedDate;
      },
      sortFunction: () => (item) => new Date(item.date),
    },
    {
      label: "Actions",
      render: (requisition) => (
        <>
          <i className="text-20 i-Windows-2 text-primary" style={{ cursor: "pointer", marginRight: '10px' }} title="View" onClick={() => handleView(requisition)}></i>         
          {
            (requisition && typeof requisition?.request_value === 'string') ?
            JSON.parse(requisition?.request_value)?.map(item => {
              if (item.value === 'advance' && requisition.state_name === 'paid') {
                return <i className="text-20 i-Pen-2 text-primary" style={{ cursor: "pointer", marginRight: '10px' }} title="Retire" onClick={() => handleEdit(requisition)}></i>
              }
            }) :
            <></>
          }          
        </>
      ),
    },
  ];

  return (
    <div className="main-content">
      <BreadCrumb
        title="Requisition"
        parentLink="/Requisition"
        parentTitle="Requisition Request"
        currentTitle="My Requisitions"
      />
      <div className="separator-breadcrumb border-top"></div>
      <div className="row"></div>
      {/* start of main-content */}

      <div>
        <div id="SectionB">
          <div className="card col-md-12">
            <div className="card-body">
              <h5 className="card-title" style={{ marginTop: "10px" }}>
                My Requisitions{" "}
              </h5>
              {
                isRequestLoading ? <Loader /> : <DataTable data={requestList} columns={columns} />
              }
            </div>
          </div>
        </div>
      </div>

      {/* Modal to view Requisition */}
      <Modal show={show} onHide={handleClose} fullscreen={isCommentEmpty} size={(!isCommentEmpty) ? 'lg' : ''}>
        <Modal.Header closeButton>
          <Modal.Title><div className="card-title">{requisition.request_name}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4 col-6">
              <div className="mb-4">
                <p className="text-primary mb-1">
                  <i className="i-Calendar text-16 me-1"></i> Created
                </p>
                <span>{requisition.created_at}</span>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="mb-4">
                <p className="text-primary mb-1">
                  <i className="i-Edit-Map text-16 me-1"></i> Status
                </p>
                <span>{uppercaseWord(requisition.state_name)}</span>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="mb-4">
                <p className="text-primary mb-1">
                  <i className="i-Face-Style-4 text-16 me-1"></i> Type
                </p>
                <span>{requisition.request_type_name}</span>
              </div>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className={(isCommentEmpty) ? "col-md-6" : "col-md-12"}>
              <div className="row">

                {
                  requisition?.request_value ? (
                    requestValue?.map((item, index) => (
                      <div className="col-sm-12 mb-4">
                        <h2 className="card-title mb-2 text-primary">{item.name}</h2>
                        {(!Array.isArray(item.value)) && <p style={{ background: "#f3f4f6", padding: "1rem", borderRadius: "10px" }}>{item.value}</p>}

                        {(Array.isArray(item.value)) &&

                          <li className="list-group-item border-0">
                            {item.value.map((selected) => <span className="badge rounded-pill badge-outline-primary p-2 m-1">{selected.name}</span>)}
                          </li>
                        }

                        {(item.attribute_type === 'file') &&
                          <div className="col-md-12">
                            <div className="row">
                              {item.value.map((file, index) =>
                                <div className="col-md-4" key={index}>
                                  <a href={file} target="_blank" rel="noopener noreferrer">
                                    <img src={getIconByFileURL(file)} alt={`File ${index + 1}`} width="50" height="50" />
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        }

                      </div>
                    ))

                  ) : (<div><NoRecords /></div>)
                }

              </div>
            </div>

            {/* Comments */}
            {
              (requisition?.comments?.length === 0) ? <></> :
                (
                  <div className="col-md-6">
                    <div className="card chat-sidebar-container sidebar-container" data-sidebar-container="chat">
                      <div className="chat-sidebar-wrap sidebar" data-sidebar="chat" style={{ left: "0px" }}>
                        <div className="border-end">
                          <div className="pt-2 pb-2 ps-3 pe-3 d-flex align-items-center o-hidden box-shadow-1 chat-topbar">
                            <a className="link-icon d-md-none" data-sidebar-toggle="chat"><i className="icon-regular ms-0 me-3 i-Left"></i></a>
                            <div className="form-group m-0 flex-grow-1">
                              <p>Comments</p>
                            </div>
                          </div>
                          <div className="contacts-scrollable perfect-scrollbar ps">
                            <div className="mt-4 pb-2 ps-3 pe-3 fw-bold text-muted border-bottom">
                              Recent
                            </div>
                            
                            {
                              requisition.comments?.map(comment => (
                                <div className="p-3 d-flex align-items-center border-bottom online contact">
                                  <>
                                    <img className="avatar-sm rounded-circle me-3" src={face1} id="userDropdown" alt="" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                    <div onClick={() => setComment(comment)}>
                                      <h6 className="m-0">{comment.created_by['name']}</h6>
                                      <span className="text-muted text-small">{comment.created_at}</span>
                                    </div>

                                  </>
                                </div>
                              ))
                            }

                            
                            <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
                              <div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}>
                              </div>
                            </div>
                            <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
                              <div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "0px" }}>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="chat-content-wrap sidebar-content" data-sidebar-content="chat" style={{ marginLeft: "260px" }}>
                        <div className="d-flex ps-3 pe-3 pt-2 pb-2 o-hidden box-shadow-1 chat-topbar">
                          <a className="link-icon d-md-none" data-sidebar-toggle="chat"><i className="icon-regular i-Right ms-0 me-3"></i></a>
                          <div className="d-flex align-items-center">
                            <img className="avatar-sm rounded-circle me-3" src={face1} id="userDropdown" alt="" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <p className="m-0 text-title text-16 flex-grow-1">
                              {(!comment) ? '' : comment?.created_by['name']}
                            </p>
                          </div>
                        </div>
                        <div className="chat-content perfect-scrollbar ps" data-suppress-scroll-x="true">
                          <div className="d-flex mb-4">
                            <div className="message flex-grow-1">
                              <div className="d-flex">
                                <p className="mb-1 text-title text-16 flex-grow-1">
                                  {(!comment) ? '' : comment?.created_by['name']}
                                </p>

                              </div>
                              <p className="m-0">
                                {comment?.comment}
                              </p>
                            </div>
                            <img className="avatar-sm rounded-circle me-3" src={face1} id="userDropdown" alt="" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                          </div>
                          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
                            <div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}>
                            </div>
                          </div>
                          <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
                            <div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "0px" }}>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            }
          </div>          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to edit requisition */}
      
      <Modal show={showEdit} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{requisition.request_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <EditRequestForm id={requisition.request_id} requestValue={requestValue} />
        </Modal.Body>
      </Modal>

      {/* end of main-content */}
      {/* Footer Start */}
      <TTLCountdown />
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default RequisitionHistory;
