const DropDownGrid = () => {
    return (
      <div className="dropdown">
        <i
          className="i-Safe-Box text-muted header-icon"
          role="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        ></i>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div className="menu-icon-grid">
            <a href="#">
              <i className="i-Shop-4"></i> Home
            </a>
            <a href="#">
              <i className="i-Library"></i> UI Kits
            </a>
            <a href="#">
              <i className="i-Drop"></i> Apps
            </a>
            <a href="#">
              <i className="i-File-Clipboard-File--Text"></i> Forms
            </a>
            <a href="#">
              <i className="i-Checked-User"></i> Sessions
            </a>
            <a href="#">
              <i className="i-Ambulance"></i> Support
            </a>
          </div>
        </div>
      </div>
    );
}
 
export default DropDownGrid;