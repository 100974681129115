import { Routes, Route } from "react-router-dom";
import EmployeeProfile from "../../views/EmployeeProfile";

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EmployeeProfile />} />
    </Routes>
  );
};

export default ProfileRoutes;
