import { useEffect, useState } from "react";
import NoRecords from "../../components/requisition/not-found";
import RequestService from "../../services/RequestService";
import { useSelector } from "react-redux";
import DataTable from "../../components/tables/reactTable";
import Footer from "../../components/layout/Footer";
import BreadCrumb from "../../components/layout/BreadCrumb";
import Loader from "../../components/Loader";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from "react-select";
import { excerpt } from "../../utils/helper";


const RequisitionList = () => {
    const [requisition, setRequisition] = useState([]);
    const requestService = RequestService();
    const { isRequestLoading = false, adminRequestList = [] } = useSelector((state) => state.request);
    const [show, setShow] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [configurations, setConfigurations] = useState([]);
    const [formData, setFormData] = useState({
        requestName: null,
        process: {label: null, value: null},
        requestType: {label: null, value: null},
        requestGroup: {label: null, value: null},
        attributes: [],
        leaveAllocation: 0,
        requestCategoryName: null,
    });

    useEffect(() => {
        (async () => { 
            await requestService.getRequestByCategoryName(); 
        })();

        (async () => {
            const response = await requestService.getRequestConfiguration();
            setConfigurations(response);
        })();
    }, []);

    const handleEdit = (requisition) => {
        const data = {
            requestId: requisition.id,
            requestName: requisition.name,
            process: (requisition.process !== null) ? { label: requisition.process.name, value: requisition.process.id } : '',
            requestType: { label: requisition.request_type.name, value: requisition.request_type.id },
            requestGroup: (requisition.request_group !== null) ? { label: requisition.request_group.name, value: requisition.request_group.id } : '',
            attributes: requisition.attributes,
            leaveAllocation: requisition.leave_allocation ?? 0,
            requestCategoryName: requisition.request_category_name ?? null,
        }

        setFormData(data);
        setShowEdit(true);
    };

    const handleView = (requisition) => {
        setShow(true);
        setRequisition(requisition);
    }

    const columns = [
        {
            label: "S/N",
            render: (requisition) => requisition.index,
            sortFunction: () => (item) => item.index,
        },

        {
            label: "Name",
            render: (requisition) => `${requisition.name}`,
            sortFunction: () => (item) => item.name,
        },
        {
            label: "Category",
            render: (requisition) => `${requisition.request_type.name}`,
            sortFunction: () => (item) => item.name,
        },
        {
            label: "Group",
            render: (requisition) => (requisition?.request_group !== null) ? `${requisition?.request_group?.name}` : '',
            sortFunction: () => (item) => item.name,
        },
        {
            label: "Date Created",
            render: (requisition) => `${requisition.date_created}`,
            sortFunction: () => (item) => new Date(item.date_created),
        },

        {
            label: "Actions",
            render: (requisition) => (
                <>
                    <i 
                        className="text-20 i-Windows-2 text-primary" 
                        style={{ cursor: "pointer", marginRight: '10px' }} 
                        title="View" 
                        onClick={() => handleView(requisition)} 
                    />
                    <i
                        className="text-15 i-Pen-5 text-success"
                        style={{ cursor: "pointer" }}
                        title="Edit"
                        onClick={() => handleEdit(requisition)}
                    />
                </>
            ),
        },
    ];

    const getTableRecords = () => {
        if (adminRequestList?.length === 0) return <NoRecords />;

        return <DataTable data={adminRequestList} columns={columns} />
    }

    const handleClose = () => {
        setShow(false);
        setShowCreate(false);
        setShowEdit(false);
        setFormData({
            requestName: null,
            process: { label: null, value: null },
            requestType: { label: null, value: null },
            requestGroup: { label: null, value: null },
            attributes: []
        });
    }

    const handleShowCreate = () => setShowCreate(true);

    const getProcessOptions = () => {
        return configurations?.processes?.map(process => ({label: process.name, value: process.id}));
    }

    const getRequestTypeOptions = () => {
        return configurations?.request_types?.map(requestType => ({label: requestType.name, value: requestType.id}));
    }

    const getRequestGroupOptions = () => {
        return configurations?.request_groups?.map(requestGroup => ({label: requestGroup.name, value: requestGroup.id}));
    }

    const handleAddInputs = (selectedOption) => {
        const copy = formData?.attributes?.map(item => {return item});
        copy.push({id: selectedOption.value, name: selectedOption.label, is_required: false});
        setFormData(prev => ({...prev, attributes: copy}));

        const filtered = configurations?.attributes?.filter(attribute => {return attribute.id !== selectedOption.value});
        setConfigurations(prev => ({...prev, attributes: filtered}));
    }

    const getInputOptions = () => {
        return configurations?.attributes?.map(attribute => ({label: attribute.name, value: attribute.id}));
    }

    const hanldeRemoveFromSelectedInputs = (e, input) => {
        const filtered = formData?.attributes?.filter(selected => {return input.id !== selected.id});
        setFormData(prev => ({...prev, attributes: filtered}));

        const copy = configurations?.attributes?.map(attribute => {return attribute});
        copy.push({id: input.id, name: input.name});
        setConfigurations(prev => ({...prev, attributes: copy}));
    }

    const handleUpdateIsRequired = (e, input) => {
        let copy = formData?.attributes?.map(attribute => { 
            if (input.id === attribute.id) attribute = ({...attribute, is_required: !input.is_required});            

            return attribute;                
         });
        
        setFormData(prev => ({...prev, attributes: copy}));
    }

    const handleUpdate = () => {
        if (! _isValidated()) return;

        handleClose();
        requestService.adminUpdateRequisition(formData);
    }

    const handleCreate = () => {
        if (! _isValidated()) return;

        handleClose();
        requestService.adminCreateRequisition(formData);
    }

    const _isValidated = () => {
        if 
        (
            formData.requestName === null ||
            formData.requestName === '' ||
            formData.requestType === null ||
            formData.requestType.label === '' || 
            formData.attributes.length === 0
        ) return false;

        return true;
    }

    return (
        <div className="main-content">
            <BreadCrumb title="Requisition" parentLink="#" parentTitle="Requisition Request" currentTitle="All Requisitions" />
            <div className="separator-breadcrumb border-top"></div>  
            {/* start of main-content */}

            {
                isRequestLoading ?
                    <Loader /> :
                    (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card o-hidden mb-4">
                                    <div className="card-header d-flex align-items-center">
                                        <h3 className="w-50 float-start card-title m-0">Manage Requisitions</h3>
                                        <div className="dropdown dropleft text-end w-50 float-end">
                                            <button
                                                className="btn bg-gray-100"
                                                id="dropdownMenuButton1"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                onClick={handleShowCreate}
                                            >
                                                <i className="nav-icon text-20 i-Add"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {getTableRecords()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }

            {/* Modal to show requisition */}
            <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{requisition?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="mb-1">
                            <strong className="me-1">{requisition?.name}</strong>
                            <p className="text-muted">{requisition?.date_created}</p>
                        </div>

                        {
                            (requisition?.process !== null) ? (<p>Workflow Process: <strong className="me-1">{requisition?.process?.name}</strong></p>) : <></>
                        }

                        <p>Request Type: <strong className="me-1">{requisition?.request_type?.name}</strong></p>

                        {
                            (requisition?.request_group !== null) ? (<p className="mb-3">Request Group: <strong className="me-1">{requisition?.request_group?.name}</strong></p>) : <></>
                        }

                        {
                            (requisition?.request_category_name === 'leave') ? (<p className="mb-3">Leave Allocation: <strong className="me-1">{requisition?.leave_allocation} Days</strong></p>) : <></>
                        }


                        <div className="table-responsive">

                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Required</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        requisition?.attributes?.map(attribute =>
                                        (
                                            <tr key={attribute?.id}>
                                                <td>{attribute?.name}</td>
                                                <td>{(attribute?.is_required) ? 'Yes' : 'No'}</td>
                                            </tr>
                                        )
                                        )
                                    }

                                </tbody>
                            </table>
                            <p>Requisition form input</p>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to create requisition */}
            <Modal show={showCreate} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{requisition?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="form-group mb-3">
                            <label htmlFor="requestName">Request name <span className='text-danger'>*</span></label>
                            <input
                                className="form-control"
                                id="requestName"
                                type="text"
                                placeholder="Enter a request name"
                                onChange={e => setFormData(prev => ({ ...prev, requestName: e.target.value }))}
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="process">Processes</label>
                            <Select
                                id='process_id'
                                name='process_id'
                                options={getProcessOptions()}
                                onChange={(selectedOption) => setFormData(prev => ({ ...prev, process: selectedOption }))}
                                placeholder='Please select a workflow process'
                                type="text"
                                defaultValue={formData?.process?.value}
                                isClearable={true}
                            ></Select>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="requestType">Request type <span className='text-danger'>*</span></label>
                            <Select
                                id='request_type_id'
                                name='request_type_id'
                                options={getRequestTypeOptions()}
                                placeholder='Please select a request type'
                                onChange={(selectedOption) => setFormData(prev => ({ ...prev, requestType: selectedOption }))}
                                defaultValue={formData?.requestType?.value}
                                isClearable
                            ></Select>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="requestGroup">Request group</label>
                            <Select
                                id='request_group_id'
                                name='request_group_id'
                                options={getRequestGroupOptions()}
                                placeholder='Please select a request group'
                                onChange={(selectedOption) => setFormData(prev => ({ ...prev, requestGroup: selectedOption }))}
                                defaultValue={formData?.requestGroup?.value}
                                isClearable
                            ></Select>
                        </div>

                        {
                            formData?.requestType?.label?.includes('Leave') ?
                                (<div className="form-group mb-3">
                                    <label htmlFor="leaveAllocation">Leave Allocation</label>
                                    <input
                                        className="form-control"
                                        id="leaveAllocation"
                                        type="number"
                                        placeholder="Enter leave allocation"
                                        onChange={e => setFormData(prev => ({ ...prev, leaveAllocation: e.target.value }))}
                                        value={formData?.leaveAllocation ?? 0}
                                    />
                                </div>) :
                                <></>
                        }

                        <div className="form-group mb-3">
                            <label htmlFor="addInputs">Add Inputs <span className='text-danger'>*</span></label>
                            <Select
                                id='request_inputs'
                                name='request_inputs'
                                options={getInputOptions()}
                                placeholder='Please select a request input'
                                onChange={(selectedOption) => handleAddInputs(selectedOption)}
                                value={''}
                            ></Select>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData?.attributes?.map(input => (

                                            <tr key={input.id}>
                                                <td data-html="true" data-bs-toggle="tooltip" data-bs-placement="left" title={input.name} data-original-title={input.name}>{excerpt(input.name)}</td>
                                                <td>
                                                    <a className="text-danger me-2" href="#">
                                                        <input id="gridCheck1" type="checkbox" onChange={e => handleUpdateIsRequired(e, input)} /></a>

                                                    <a className="text-danger me-2" href="#" onClick={e => hanldeRemoveFromSelectedInputs(e, input)}>
                                                        <i className="nav-icon i-Close-Window fw-bold"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCreate}> Create </Button>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to Edit requisition */}
            <Modal show={showEdit} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{requisition?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="form-group mb-3">
                            <label htmlFor="requestName">Request name</label>
                            <input
                                className="form-control"
                                id="requestName"
                                type="text"
                                placeholder="Enter a request name"
                                onChange={e => setFormData(prev => ({ ...prev, requestName: e.target.value }))}
                                value={(formData?.requestName === null) ? '' : formData?.requestName}
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="process">Processes</label>
                            <Select
                                id='process_id'
                                name='process_id'
                                options={getProcessOptions()}
                                placeholder='Please select a workflow process'
                                onChange={(selectedOption) => setFormData(prev => ({ ...prev, process: selectedOption }))}
                                defaultValue={formData?.process}
                                isClearable={true}
                            ></Select>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="requestType">Request type</label>
                            <Select
                                id='request_type_id'
                                name='request_type_id'
                                options={getRequestTypeOptions()}
                                placeholder='Please select a request type'
                                onChange={(selectedOption) => setFormData(prev => ({ ...prev, requestType: selectedOption }))}
                                defaultValue={formData.requestType}
                                isClearable={true}
                            ></Select>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="requestGroup">Request group</label>
                            <Select
                                id='request_group_id'
                                name='request_group_id'
                                options={getRequestGroupOptions()}
                                placeholder='Please select a request group'
                                onChange={(selectedOption) => setFormData(prev => ({ ...prev, requestGroup: selectedOption }))}
                                defaultValue={formData.requestGroup}
                                isClearable={true}
                            ></Select>
                        </div>

                        {
                            formData?.requestType?.label?.includes('Leave') ?
                                (<div className="form-group mb-3">
                                    <label htmlFor="leaveAllocation">Leave Allocation</label>
                                    <input
                                        className="form-control"
                                        id="leaveAllocation"
                                        type="number"
                                        placeholder="Enter leave allocation"
                                        onChange={e => setFormData(prev => ({ ...prev, leaveAllocation: e.target.value }))}
                                        value={formData?.leaveAllocation ?? 0}
                                    />
                                </div>) :
                                <></>
                        }

                        <div className="form-group mb-3">
                            <label htmlFor="addInputs">Add Inputs</label>
                            <Select
                                id='request_inputs'
                                name='request_inputs'
                                options={getInputOptions()}
                                placeholder='Please select a request input'
                                onChange={(selectedOption) => handleAddInputs(selectedOption)}
                                defaultValue={''}
                            ></Select>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData?.attributes?.map(input => (

                                            <tr key={input.id}>
                                                <td data-html="true" data-bs-toggle="tooltip" data-bs-placement="left" title={input.name} data-original-title={input.name}>{excerpt(input.name)}</td>
                                                <td>
                                                    <a className="text-danger me-2" href="#">
                                                        <input id="gridCheck1" type="checkbox" checked={input.is_required} onChange={e => handleUpdateIsRequired(e, input)} /></a>

                                                    <a className="text-danger me-2" href="#" onClick={e => hanldeRemoveFromSelectedInputs(e, input)}>
                                                        <i className="nav-icon i-Close-Window fw-bold"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleUpdate}> Update </Button>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                </Modal.Footer>
            </Modal>

            {/* end of main-content */}
            {/* Footer Start */}
            <Footer />
        </div>
    );
};

export default RequisitionList;
