import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import logo from "../../assets/images/Kenna Logo.png";

const PayslipTable = (payslips) => {
    const componentRef = useRef();
    const employee = {
        name: payslips[0]?.employee_name,
        designation: payslips[0]?.designation,
        hired_date: payslips[0]?.hire_date,
        department: payslips[0]?.department
    }

    const printPayslip = useReactToPrint({
        content: () => componentRef.current,
    });

    const getPayslip = (payslips) => {
        let count = 0;

        return Object.values(payslips)?.map(payslip => (
            (
                <>
                    {(count > 0) && (count % 2 === 0) ? <div style={{ height: "131px" }}><input type="hidden" value={count++} /></div> : <div><input type="hidden" value={count++} /></div>}
                    <p>Payslip for {payslip.payroll_date}</p>
                    <div className="row">
                        <div className="col-xl-12 table-responsive">
                            <table className="table table-hover table-bordered mb-4" key={payslip.id}>
                                <thead className="bg-gray-300">
                                    <tr>
                                        <th scope="col">Particulars</th>
                                        <th scope="col">Rate (&#x20A6;)</th>
                                        <th scope="col">Earnings (&#x20A6;)</th>
                                        <th scope="col">Particulars</th>
                                        <th scope="col">Rate</th>
                                        <th scope="col">Deduction (&#x20A6;)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={payslip.basic}>
                                        <td>BASIC</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.basic}</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.basic}</td>
                                        <td>PAYE (Graduated Tax Table)</td>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}>{payslip.monthly_tax_payable}</td>
                                    </tr>
                                    <tr key={payslip.housing}>
                                        <td>HOUSING</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.housing}</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.housing}</td>
                                        <td>PENSION  - 8% X (B+H+T)</td>
                                        <td style={{ textAlign: 'right' }}>8%</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.employee_pension_contribution}</td>
                                    </tr>
                                    <tr key={payslip.transportation}>
                                        <td>TRANSPORT</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.transportation}</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.transportation}</td>
                                        <td>STAFF CO-OPERATIVE DEDUCTION</td>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}>{payslip.coperative_contributions}</td>
                                    </tr>
                                    <tr key={payslip.thirteenth_month}>
                                        <td>13TH MONTH</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.thirteenth_month}</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.thirteenth_month}</td>
                                        <td colSpan="3"></td>
                                    </tr>
                                    <tr>
                                        <td>OTHERS</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.others}</td>
                                        <td style={{ textAlign: 'right' }}>{payslip.others}</td>
                                        <td colSpan="3"></td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Total Earnings</td>
                                        <td colSpan="2" style={{ textAlign: 'right' }}>{payslip.monthly_gross}</td>
                                        <td className="fw-bold">Total Deductions</td>
                                        <td colSpan="2" style={{ textAlign: 'right' }}>{payslip?.total_deductions}</td>
                                    </tr>
                                    <tr>
                                        <td>Net Pay</td>
                                        <td colSpan="2" className="fw-bold" style={{ textAlign: 'right' }}>{payslip?.net_pay}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: 'center' }}>Employee Net Pay: NGN{payslip?.net_pay} ({payslip?.net_pay_in_words})</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )
        ))
    }

    return (
        <div className="col-md-12">

            {/* <!-- -===== Print Area =======--> */}
            {
                (Object.keys(payslips).length === 0) ? <></> :
                    <>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <button className="btn btn-primary print-invoice" id="dropdownMenuButton1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => printPayslip()} style={{ float: 'right' }}>
                                    Print
                                </button>
                            </div>
                        </div>

                        <div id="print-area" ref={componentRef}>
                            <div className="row">
                                <div className="card o-hidden">
                                    <div className="card-title">
                                        <img className="m-0" src={logo} alt="KP Logo" style={{ height: "90px", width: "150px" }} />
                                        <div className="mt-3 mb-4 border-top"></div>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 mb-3 mb-sm-0">
                                                <h5 className="fw-bold">Employee Name: {employee.name} <span style={{ float: "right" }}>Department: {employee.department}</span></h5>
                                                <p>Date of joining: {employee.hired_date} <span style={{ float: "right" }}>Designation: {employee.designation}</span></p>
                                            </div>
                                        </div>
                                        {getPayslip(payslips)}
                                    </div>
                                </div>
                            </div>

                            <div style={{ whiteSpace: "pre-line", textAlign: "center", marginTop: "10px" }}>
                                This is a computer generated salary slip. Signature not required
                            </div>
                        </div>

                    </>
            }

            {/* <!-- ==== / Print Area =====--> */}
        </div>
    );
}

export default PayslipTable;