import { useEffect, useState } from "react";
import NoRecords from "../../components/requisition/not-found";
import RequestInputService from "../../services/RequestInputService";
import { useSelector } from "react-redux";
import DataTable from "../../components/tables/reactTable";
import Footer from "../../components/layout/Footer";
import BreadCrumb from "../../components/layout/BreadCrumb";
import Loader from "../../components/Loader";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from "react-select";


const RequestInput = () => {
    const [requestInput, setRequestInput] = useState([]);
    const requestInputService = RequestInputService();
    const {isRequestInputLoading = false, requestInputs = [], attributeTypes = []} = useSelector((state) => state.requestInput);
    const [show, setShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [formData, setFormData] = useState({});
    const [newAttributeValue, setNewAttributeValue] = useState({value: ''});
    const [showAddInput, setShowAddInput] = useState(false);
    const [attributeValues, setAttributeValues] = useState([]);
    const [createFormData, setCreateFormData] = useState({attribute_name: ''});
    const [createNewAttributeValue, setCreateNewAttributeValue] = useState({value: ''});
    const [createSelectedOption, setCreateSelectedOption] = useState([]);

    useEffect(() => {
        (async () => {
             await requestInputService.get();
        })();
    }, []);

    const handleView = (requestInput) => {
        setShow(true);
        setRequestInput(requestInput);
        const option = attributeTypes?.filter(attributeType => {
            if (attributeType.name === requestInput.type) return attributeType;
        });

        if (option.length > 0) {
            setSelectedOption({label: option[0].name, value: option[0].id});
            setFormData({attribute_id: requestInput.id, attribute_name: requestInput.name, attribute_type_id: option[0].id});  
        }              
    }

    const columns = [
        {
            label: "S/N",
            render: (requestInput) => requestInput?.index,
            sortFunction: () => (item) => item?.index,
        },

        {
            label: "Name",
            render: (requestInput) => `${requestInput?.name}`,
            sortFunction: () => (item) => item?.name,
        },
        {
            label: "Type",
            render: (requestInput) => `${requestInput?.type}`,
            sortFunction: () => (item) => item?.name,
        },
        {
            label: "Actions",
            render: (requestInput) => (
                <>
                    <i 
                        className="text-20 i-Windows-2 text-primary" 
                        style={{ cursor: "pointer", marginRight: '10px' }} 
                        title="View" 
                        onClick={() => handleView(requestInput)}>
                    </i>
                </>
            ),
        },
    ];

    const getTableRecords = () => {
        if (requestInputs?.length === 0) return <NoRecords />;

        return <DataTable data={requestInputs} columns={columns} />
    }

    const handleClose = () => {
        setShow(false);
        setShowAddInput(false);
        setAttributeValues([]);
        setCreateFormData({attribute_name: ''});
        setCreateNewAttributeValue({value: ''});
        setCreateSelectedOption([]);
    }

    const handleSelectChange = (option) => {
        setSelectedOption(option);
        setFormData(prev => ({...prev, attribute_type_id: option.value, attribute_type_name: option.label}));
    }

    const getOptions = () => {
        if (!Array.isArray(attributeTypes) || (Array.isArray(attributeTypes) && !attributeTypes.length)) return [];
        
        return attributeTypes?.map(attributeType => ({label: attributeType.name, value: attributeType.id}));
    }

    const handleInputChange = (input) => {
        setFormData(prev => ({...prev, attribute_name: input}));
    }

    const handleUpdate = async () => {
        handleClose();
        await requestInputService.update(formData, requestInputs);
    }

    const handleRemoveAttributeValue = (event, attributeValue) => {
        const results = requestInput?.attribute_values?.filter(item => {
            if (item.id !== attributeValue.id) {
                return item;
            }
        });

        setRequestInput((prev) => ({...prev, attribute_values: results}));
        setFormData(prev => ({...prev, attribute_values: results}));
    }

    const handleAttributeInputChange = (value) => {
        setNewAttributeValue(prev => ({...prev, value: value}));
    }

    const handleIncludeToAttributeValues = () => {
        if (!newAttributeValue.value || newAttributeValue.value === '') return;

        let prevAttributeValues = [];

        if (requestInput && Array.isArray(requestInput?.attribute_values)) {
            prevAttributeValues = requestInput?.attribute_values?.map(item => {return item;});
            prevAttributeValues.push(newAttributeValue);
            setRequestInput(prev => ({...prev, attribute_values: prevAttributeValues}));
            setFormData(prev => ({...prev, attribute_values: prevAttributeValues}));
            return;
        }

        if (requestInput && typeof requestInput?.attribute_values === 'object') {
            prevAttributeValues = Object.entries(requestInput?.attribute_values);
            prevAttributeValues.push(newAttributeValue)
            setRequestInput(prev => ({...prev, attribute_values: prevAttributeValues}));
            setFormData(prev => ({...prev, attribute_values: prevAttributeValues}));
            return;
        }
    }

    const handleShowAddInput = () => setShowAddInput(true);

    const handleCreateAttributeValueForInputChange = (value) => {
        setCreateNewAttributeValue(prev => ({...prev, value: value}));
    }

    const handleCreateRemoveAttributeValue = (event, attributeValue) => {
        const results = attributeValues?.filter(item => {
            if (item.id !== attributeValue.id) {
                return item;
            }
        });

        setAttributeValues(results);
        setCreateFormData(prev => ({...prev, attribute_values: results}));
    }

    const handleCreateInputChange = (input) => {
        setCreateFormData(prev => ({...prev, attribute_name: input}));
    }

    const handleCreateIncludeToAttributeValues = () => {
        if (!createNewAttributeValue.value || createNewAttributeValue.value === '') return;

        let prevAttributeValues = [];

        prevAttributeValues = attributeValues?.map(item => { return item; });
        prevAttributeValues.push(createNewAttributeValue);
        setAttributeValues(prevAttributeValues);
        setCreateFormData(prev => ({ ...prev, attribute_values: prevAttributeValues }));

    }

    const handleCreateSelectChange = (option) => {
        setCreateSelectedOption(option);
        setCreateFormData(prev => ({...prev, attribute_type_id: option.value, attribute_type_name: option.label}));
    }

    const handleCreate = async () => {
        handleClose();
        await requestInputService.create(createFormData);
    }

    return (
        <div className="main-content">
            <BreadCrumb title="Request Inputs" parentLink="#" parentTitle="Inputs" currentTitle="All Request Inputs" />
            <div className="separator-breadcrumb border-top"></div>
            {/* start of main-content */}

            {
                isRequestInputLoading ?
                    <Loader /> :
                    (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card o-hidden mb-4">
                                    <div className="card-header d-flex align-items-center border-0">
                                        <h3 className="w-50 float-start card-title m-0">Manage Request Input</h3>
                                        <div className="dropdown dropleft text-end w-50 float-end">
                                            <button 
                                                className="btn bg-gray-100" 
                                                id="dropdownMenuButton1" 
                                                type="button" 
                                                data-bs-toggle="dropdown" 
                                                aria-haspopup="true" 
                                                aria-expanded="false" 
                                                onClick={handleShowAddInput}
                                            >
                                                <i className="nav-icon text-20 i-Add"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {getTableRecords()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }

            {/* Modal to show requisition */}
      
            <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{requestInput?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form>
                            <div className="d-flex flex-column">
                                <div className="form-group mb-3">
                                    <input className="form-control" type="text" placeholder="Enter a value" value={formData?.attribute_name} onChange={(e => handleInputChange(e.target.value))} />
                                </div>
                                <div className="form-group">
                                    <Select
                                        id='attribute_type_id'
                                        name='attribute_type_id'
                                        options={getOptions()}
                                        placeholder='Please select an option'
                                        onChange={(selectedOption) => handleSelectChange(selectedOption)}
                                        value={selectedOption}
                                    ></Select>
                                </div>
                                {
                                    (selectedOption.label === 'drop_down') ?
                                        <>
                                            <div className="col-md-12 form-group mt-3">
                                                <div className="tagBox case-sensitive tagging editable" data-no-duplicate="true" data-pre-tags-separator="," data-no-duplicate-text="Duplicate tags" data-type-zone-class="type-zone" data-case-sensitive="true" data-tag-box-class="tagging" data-edit-on-delete="false" data-no-enter="false">
                                                    {
                                                        (Array.isArray(requestInput.attribute_values)) ?
                                                            requestInput?.attribute_values?.map((attributeValue) => {
                                                                return (
                                                                    <div className="tag" key={attributeValue.id}>
                                                                        <span>#</span>{attributeValue.value}<input type="hidden" name="tag[]" value={attributeValue.id} />
                                                                        <a role="button" className="tag-i" onClick={(e) => handleRemoveAttributeValue(e, attributeValue)}>×</a>
                                                                    </div>
                                                                )
                                                            }) :
                                                            <></>
                                                    }
                                                    <input className="type-zone" contentEditable="true" />
                                                </div>
                                                <p className="text-muted">
                                                    Click <code>x</code> to remove a value
                                                </p>

                                                <div className="row row-xs">
                                                    <div className="col-md-6">
                                                        <input className="form-control" type="text" placeholder="Enter a drop down menu item" onChange={event => handleAttributeInputChange(event.target.value)}/>
                                                    </div>
                                                    <div className="col-md-6 mt-3 mt-md-0">
                                                        <input type="button" className="btn btn-primary w-100" onClick={handleIncludeToAttributeValues} value='Include Option' />
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <></>
                                }
                                <div className="col-md-12 mt-3 mb-5">
                                    <input type="button" className="btn btn-primary float-end" onClick={handleUpdate} value="Update" />
                                </div>
                            </div>
                        </form>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to add a new requisition */}
      
            <Modal show={showAddInput} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add a new request input</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form>
                            <div className="d-flex flex-column">
                                <div className="form-group mb-3">
                                    <input className="form-control" type="text" placeholder="Enter a value" value={createFormData?.attribute_name} onChange={(e => handleCreateInputChange(e.target.value))} />
                                </div>
                                <div className="form-group">
                                    <Select
                                        id='attribute_type_id'
                                        name='attribute_type_id'
                                        options={getOptions()}
                                        placeholder='Please select an option'
                                        onChange={(selectedOption) => handleCreateSelectChange(selectedOption)}
                                        value={createSelectedOption}
                                    ></Select>
                                </div>

                                {
                                    (createSelectedOption.label === 'drop_down') ?
                                        <div className="col-md-12 form-group mt-3">
                                            <div className="forbidden-words tagging editable" data-no-duplicate="true" data-pre-tags-separator="," data-no-duplicate-text="Duplicate tags" data-type-zone-class="type-zone" data-tag-box-class="tagging" data-edit-on-delete="true">
                                                {
                                                    attributeValues?.map((attributeValue, index) => {
                                                        return (
                                                            <div className="tag" key={index}>
                                                                <span>#</span>{attributeValue.value}
                                                                <input type="hidden" name="tag[]" value={index} />
                                                                <a role="button" className="tag-i" onClick={(e) => handleCreateRemoveAttributeValue(e, attributeValue)}>×</a>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <input className="type-zone" contentEditable="true" />
                                            </div>
                                            <p className="text-muted">
                                                Click <code>x</code> to remove a value
                                            </p>

                                            <div className="row row-xs">
                                                <div className="col-md-6">
                                                    <input className="form-control" type="text" placeholder="Enter a drop down menu item" onChange={event => handleCreateAttributeValueForInputChange(event.target.value)} />
                                                </div>
                                                <div className="col-md-6 mt-3 mt-md-0">
                                                    <input type="button" className="btn btn-primary w-100" onClick={handleCreateIncludeToAttributeValues} value='Include Option' />
                                                </div>
                                            </div>
                                        </div> :
                                        <></>
                                }                                   
                                
                                <div className="col-md-12 mt-3 mb-5">
                                    <input type="button" className="btn btn-primary float-end" onClick={handleCreate} value="Create" />
                                </div>
                            </div>
                        </form>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            
            {/* end of main-content */}
            {/* Footer Start */}
            <Footer />
        </div>
    );
};

export default RequestInput;
