import { useState } from "react";
import face1 from "../../assets/images/faces/1.jpg";
import { Link, useNavigate } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
//import { startLoader, stopLoader } from "../../store/slices/LoaderSlice";

const DropDownAvatar = ({ username }) => {
  const [isOpen, setIsOpen] = useState(false);
  const authenticationService = AuthenticationService();
  const navigate = useNavigate();
  //const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (event) => {
    if (!isOpen) {
      event.currentTarget.classList.add("show");
      document.getElementById("userDropdown").ariaExpanded = "true";
      document
        .querySelector("[aria-labelledby=userDropdown")
        .classList.add("show");

      setIsOpen(true);
    } else {
      event.currentTarget.classList.remove("show");
      document.getElementById("userDropdown").ariaExpanded = "false";
      document
        .querySelector("[aria-labelledby=userDropdown")
        .classList.remove("show");

      setIsOpen(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await authenticationService.logout();
    } catch (error) {
      setIsLoading(false);
    }    
  }

  if (isLoading) return <Loader />

  return (
    <div className="dropdown">
      <div
        className="user col align-self-end"
        onClick={(event) => handleClick(event)}
      >
        <img
          src={face1}
          id="userDropdown"
          alt=""
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        />
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="userDropdown"
          x-placement="bottom-end"
          style={{
            position: "absolute",
            willChange: "transform",
            top: "0px",
            left: "0px",
            transform: "translate3d(-124px, 36px, 0px)",
          }}
        >
          <div className="dropdown-header">
            <Link to="/Profile">
              <i className="i-Lock-User me-1"></i>
              {username}
            </Link>
          </div>
          <a className="dropdown-item">Account settings</a>

          <Link to='#' className="dropdown-item" onClick={handleSignOut} >
            Sign out
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DropDownAvatar;
