import { useState } from "react";
import SidebarMainMenu from "./SideBarMainMenu";
import SideBarSubMenu from "./SideBarSubMenu";
import Menus from "../../utils/Menus";

const SideBar = () => {
  const menu = Menus();
  const menusList = menu.list() ?? [];
  const [activeItem, setActiveItem] = useState();
  const [isActiveSubMenu, setIsActiveSubMenu] = useState(false);

  const handleMenuHover = (menu) => {
    //show the sub menu on mouse over
    setIsActiveSubMenu(true);
    //set the contents of the sub Menus
    let newActiveMenu = menusList.filter((m) => m?.mainMenu === menu);
    setActiveItem(newActiveMenu);
  };

  return (
    <div className="side-content-wrap">
      <div
        className="sidebar-left open rtl-ps-none"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul className="navigation-left">
          {menusList?.map((m, index) => {
            return (
              <SidebarMainMenu
                key={index}
                icon={m?.icon}
                display={m?.display}
                name={m?.mainMenu}
                link={m?.link}
                handleMenuHover={handleMenuHover}
              />
            );
          })}
        </ul>
        <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
          <div
            className="ps__thumb-x"
            tabIndex="0"
            style={{ left: "0px", width: "0px" }}
          ></div>
        </div>
        <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
          <div
            className="ps__thumb-y"
            tabIndex="0"
            style={{ top: "0px", height: "0px" }}
          ></div>
        </div>
      </div>
      <SideBarSubMenu
        subMenu={activeItem ? activeItem[0] : ''}
        isActiveSubMenu={isActiveSubMenu}
      />
    </div>
  );
};

export default SideBar;
