import Footer from "../components/layout/Footer";
import { useEffect, useState } from "react";
import SupervisorSingleKPI from "../components/appraisal/SupervisorSingleKPI";
import { useSelector } from "react-redux";
import { AppraisalLegend } from "../components/appraisal/AppraisalLegend";
import AppraisalService from "../services/AppraisalService";
import Loader from "../components/Loader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TTLCountdown from "../components/TTLCountdown";

const SVAppraisal = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { team_members } = user?.data?.employee ?? [];
  const [activeEmployee, setActiveEmployee] = useState({});
  const [activeCardinalPos, setActiveCardinalPos] = useState(0);
  const [activeKRAPos, setActiveKRAPos] = useState(0);
  const { loading = true, userAppraisal = {} } = useSelector((state) => state.appraisal);
  const appraisalService = AppraisalService();
  const countAllKeyPerformanceIndices = appraisalService.countKeyPerformanceIndices(userAppraisal);
  const ratedKeyPerformanceIndices = appraisalService.countRatedKeyPerformanceIndices(userAppraisal, "supervisor");
  const [show, setShow] = useState(false);

  const cardinals = appraisalService.getCardinals(userAppraisal);
  const keyResponsibilityAreas = appraisalService.getKeyResponsibilityArea(
    userAppraisal,
    cardinals[activeCardinalPos]
  );

  const keyPerformanceIndices = appraisalService.getKeyPerformanceIndices(
    userAppraisal,
    cardinals[activeCardinalPos],
    keyResponsibilityAreas[activeKRAPos]
  );

  useEffect(() => {
    appraisalService.resetEmployeeAppraisalObjects();
  }, []);

  const onClickSaveBtn = async (e) => {
    e.preventDefault();
    (async () => {
      await appraisalService.saveEmployeeAppraisal(
        userAppraisal,
        activeEmployee.employee_id,
        "updateEmployeeRatings"
      );
    })();
  };

  const onClickTeamMember = (teamMember_emp_id) => {
    let newActiveEmp = JSON.parse(sessionStorage.getItem(teamMember_emp_id));
    setActiveEmployee(newActiveEmp);

    (async () => {
      await appraisalService.getEmployeeAppraisal(newActiveEmp.employee_id);
    })();
  };

  const onSelectCardinal = (e, index) => {
    e.preventDefault();
    setActiveCardinalPos(index);
  };

  const onSelectKRA = (e, index) => {
    e.preventDefault();
    setActiveKRAPos(index);
  };

  const handleOnSubmit = () => {
    handleClose();

    (async () => {
      await appraisalService.submitSupervisorAppraisal(
        userAppraisal.id,
        activeEmployee.employee_id,
        user.data.employee.id
      );
    })();
  };

  const handleShowModal = () => setShow(true);
  const handleClose = () => setShow(false);

  const getTeamAppraisal = () => {
    if (userAppraisal.is_supervisor_appraisal_closed) {
      return (
        <div className="alert alert-dismissible fade show alert-warning" role="alert">
          <strong className="text-capitalize">Warning!</strong> HR has closed supervisor's appraisal.
          <button className="btn btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      );
    }

    if (!userAppraisal.is_self_appraisal_closed) {
      return (
        <div className="alert alert-dismissible fade show alert-warning" role="alert">
          <strong className="text-capitalize">Warning!</strong> HR is yet to close self appraisal.
          <button className="btn btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      );
    }

    return (
      <div className="col-md-12 mb-4">
        <div className="card text-start">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h4 className="card-title mb-3">{activeEmployee.name}</h4>
              </div>
            </div>
            <ul className="nav nav-tabs" id="myIconTab" role="tablist">
              {cardinals &&
                cardinals.map((c, index) => {
                  return (
                    <li
                      className="nav-item"
                      key={index}
                      onClick={(e) => onSelectCardinal(e, index)}
                    >
                      <a
                        className={`nav-link ${index === activeCardinalPos ? "active" : ""
                          }`}
                        id="profile-icon-tab"
                        data-bs-toggle="tab"
                        href="#profileIcon"
                        role="tab"
                        aria-controls="profileIcon"
                        aria-selected="false"
                      >
                        <i className="nav-icon i-Home1 me-1"></i> {c}
                      </a>
                    </li>
                  );
                })}
            </ul>
            <div className="tab-content" id="myIconTabContent">
              <div
                className="tab-pane fade active show"
                id="homeIcon"
                role="tabpanel"
                aria-labelledby="home-icon-tab"
              >
                <div className="row">
                  <div className="col-lg-2">
                    <div className="mb-4">
                      <span
                        className={`badge ${countAllKeyPerformanceIndices - ratedKeyPerformanceIndices > countAllKeyPerformanceIndices / (countAllKeyPerformanceIndices - ratedKeyPerformanceIndices) ? "bg-danger" : "indigo-700"} p-2 m-1`}
                      >
                        {`${ratedKeyPerformanceIndices} of ${countAllKeyPerformanceIndices} KPIs rated`}
                      </span>
                    </div>
                    <strong>Select KRA</strong>
                    <div className="list-group mt-3">
                      {userAppraisal &&
                        keyResponsibilityAreas?.map((kra, index) => {
                          return (
                            <button
                              className={`list-group-item list-group-item-action ${index === activeKRAPos ? "active" : ""
                                }`}
                              type="button"
                              key={index}
                              onClick={(e) => onSelectKRA(e, index)}
                            >
                              {kra}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div
                      className="row row-xs"
                      style={{ marginBottom: "25px" }}
                    >
                      <div className="col-md-5">
                        <span>
                          Please evaluate<strong> ALL </strong>the
                          following KPIs
                        </span>
                      </div>
                      <div className="col-md-5 mt-3 mt-md-0">
                        <button className="btn btn-md btn-primary btn-icon" type="button" onClick={onClickSaveBtn} disabled={(userAppraisal.submission_status?.is_supervisor_submitted) || (userAppraisal.is_supervisor_appraisal_closed)}>
                          <span className="ul-btn__icon"> <i className="i-Disk"></i> </span>
                          <span className="ul-btn__text"> Save</span>
                        </button>
                      </div>
                      <div className="col-md-2 mt-3 mt-md-0">
                        <button className="btn btn-md btn-danger btn-icon text-white" type="button" onClick={handleShowModal}
                          disabled={countAllKeyPerformanceIndices > ratedKeyPerformanceIndices || userAppraisal.submission_status?.is_supervisor_submitted || (userAppraisal.is_supervisor_appraisal_closed)}
                        >
                          <span className="ul-btn__icon">
                            <i className="i-Data-Save"></i>
                          </span>
                          <span className="ul-btn__text"> Submit</span>
                        </button>
                      </div>
                    </div>
                    {keyPerformanceIndices.map(
                      (keyPerformanceIndex, kpiIndex) => (
                        <SupervisorSingleKPI
                          keyPerformanceIndex={keyPerformanceIndex}
                          key={kpiIndex}
                          cardinal={cardinals[activeCardinalPos]}
                          kra={keyResponsibilityAreas[activeKRAPos]}
                          kpiIndex={kpiIndex}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>
                Are you sure you want to submit{" "}
                <strong>{activeEmployee.name}</strong>'s appraisal?
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Note that once submitted you cannot edit this appraisal again!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleOnSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  return (
    <div className="main-content">
      <div className="breadcrumb">
        <h1>Appraisal</h1>
        <ul>
          <li>
            <a href="#">Pages</a>
          </li>
          <li>Supervisor Appraisal</li>
        </ul>
      </div>
      <div className="separator-breadcrumb border-top"></div>
      <div className="row">
        <div className="col-lg-2">
          <div id="sectionB">
            <span
              className="mb-3"
              style={{ fontSize: "16px", color: "#000466" }}
            >
              Reporting Line
            </span>
            <div className="list-group">
              {team_members &&
                team_members?.map((tm, index) => {
                  sessionStorage.setItem(tm?.employee_id, JSON.stringify(tm));

                  return (
                    <button
                      className={`list-group-item list-group-item-action ${
                        tm?.employee_id === activeEmployee?.employee_id
                          ? "active"
                          : ""
                      }`}
                      type="button"
                      key={index}
                      onClick={() => onClickTeamMember(tm?.employee_id)}
                      data-index={tm?.employee_id}
                    >
                      {tm.name}
                    </button>
                  );
                })}
            </div>
          </div>
          <div className="separator-breadcrumb border-top"></div>
          {loading && (
            <div id="sectionD">
              <strong>Note: </strong>
              <span>
                select an employee from your reporting line (listed above) to
                commence appraisal
              </span>
            </div>
          )}
          <div className="border-top"></div>
          <AppraisalLegend />
        </div>
        <div id="SectionC" className="col-lg-10">
          { loading ?  <Loader /> : getTeamAppraisal() }
        </div>
      </div>
      {/* end of main-content */}
      {/* Footer Start */}
      <TTLCountdown />
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default SVAppraisal;
