import { useDraggable } from "@dnd-kit/core";
import { nanoid } from "nanoid";
import { useRef } from "react";

import { fields } from "./fields";
import SidebarField from "./SidebarField";

function DraggableSidebarField(props) {
  const { field, ...rest } = props;
  const id = useRef(nanoid());

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: id.current,
    data: {
      field,
      fromSidebar: true,
    },
  });
  return (
    <div
      style={{
        cursor: "pointer",
        userSelect: "none",
        border: `${isDragging ? `3px solid grey` : ""}`,
      }}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className="col-md-4"
    >
      <SidebarField field={field} {...rest} />
    </div>
  );
}

export default function ComponentLibrary(props) {
  const { fieldsRegKey } = props;

  return (
    <div className="row" key={fieldsRegKey}>
      {fields?.map((f) => (
        <DraggableSidebarField key={f.type} field={f} />
      ))}
    </div>
  );
}
