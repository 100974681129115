import React from 'react';

const NoRecordsFound = () => {
  return (
  
    <div className="empty-data-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
      <div className="icon-wrapper" style={{ width: '100px', height: '100px', margin: '0', padding: '0' }}>
          <i className="nav-icon i-File-Search big-icon" style={{ fontSize: '100px', color: '#663399' }} />
      </div>
      <p style={{ margin: '20px' }}>You have not raised a requisition request</p>
      <button class="btn btn-primary btn-sm m-1" type="button">Retire Requisition</button>
    </div>
  );
};

export default NoRecordsFound;
