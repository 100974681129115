import { useState } from "react";

const PermissionCard = ({ title, id, isPermitted, handleSelectChange }) => {
  const [isChecked, setIsChecked] = useState(isPermitted);

  const [permissions, setPermissions] = useState({});

  const onClickCard = (e) => {
    e.preventDefault();
    //handle the selection of permission
    let newStatus = !isChecked;
    setIsChecked(newStatus);
    handleSelectChange(id, isChecked);
  };

  return (
    <div className="col-lg-4">
      <div className="card mb-3">
        <div className="card-body" onClick={onClickCard}>
          <label
            className={`checkbox ${
              isChecked ? "checkbox-outline-success" : "checkbox-outline-danger"
            }`}
          >
            <input type="checkbox" checked={isChecked ? "checked" : ""} />
            <span className="font-weight-bold">{title}</span>

            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PermissionCard;
