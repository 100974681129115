import { useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Announcements from "./announcements";
import ComponentLibrary from "./ComponentLibrary";
import Board from "./Board";
import Field from "./Field";
import Footer from "../../layout/Footer";
import ModalComponent from "../../modals/modal";
import ComponentPropertiesForm from "./modals/ComponentPropertiesForm";
import SidebarField from "./SidebarField";
import AxiosService from "../../../services/AxiosService";
import { serverUrl } from "../../../utils/constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { stopLoader, startLoader } from "../../../store/slices/LoaderSlice";
// import { saveFormElementsData } from "../../../store/slices/requisitionSlice";
import DropdownComponentPropertiesForm from "./modals/DropdownComponentPropertiesForm";
import RequisitionCategoriesCard from "./RequisitionCategoriesCard";
import NewCategoriesForm from "./modals/NewCategoriesForm";
import NewGroupForm from "./modals/NewGroupForm";

function getData(prop) {
  return prop?.data?.current ?? {};
}

function createSpacer({ id }) {
  return {
    id,
    type: "spacer",
    title: "spacer",
  };
}

export default function FormBuilder(props) {
  const dispatch = useDispatch();
  const [activeFormFieldId, setActiveFormFieldId] = useState();
  const [formElementsData, setFormElementsData] = useState({});
  const [showModalForm, setShowModalForm] = useState(false);
  const [showNewGroupForm, setShowNewGroupForm] = useState(false);
  const [showNewCategoryForm, setShowNewCategoryForm] = useState(false);
  const [sidebarFieldsRegenKey, setSidebarFieldsRegenKey] = useState(
    Date.now()
  );
  const spacerInsertedRef = useRef();
  const currentDragFieldRef = useRef();
  const [activeSidebarField, setActiveSidebarField] = useState(); // only for fields from the sidebar
  const [activeField, setActiveField] = useState(); // only for fields that are in the form.
  const [data, updateData] = useImmer({
    fields: [],
  });

  const formId = 1; //TODO: assign props.id

  //If the form exists, get existing form elements from server
  useEffect(() => {
    const getFormElementsFromServer = async () => {
      try {
        dispatch(startLoader());
        //TODO: update correct endpoint url
        let response = await AxiosService.get(
          serverUrl + "/requisitions/" + formId
        );
        setFormElementsData(response);
      } catch (error) {
        toast.error(`Failed to save due to error: ${error}`);
      } finally {
        dispatch(stopLoader());
      }
    };

    getFormElementsFromServer();
  }, []); //do this only once

  const getModalFormData = (field) => {
    //switch logic here to return form data
    switch (field) {
      case "drop_down":
        return (
          <DropdownComponentPropertiesForm
            onClose={setShowModalForm}
            attributes={field}
          />
        );
      default:
        return (
          <ComponentPropertiesForm
            onClose={setShowModalForm}
            attributes={field}
          />
        );
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    //Prepare form element data

    //save form element data to server
    //dispatch(saveFormElementsData(formElementsData));
  };

  const cleanUp = () => {
    setActiveSidebarField(null);
    setActiveField(null);
    currentDragFieldRef.current = null;
    spacerInsertedRef.current = false;
  };

  const handleDragStart = (e) => {
    const { active } = e;
    const activeData = getData(active);

    if (activeData.fromSidebar) {
      //when the component is dragged from the componenet library
      const { field } = activeData;
      const { type } = field;
      setActiveSidebarField(field);
      currentDragFieldRef.current = {
        id: active.id,
        type,
        name: `${type}${fields.length + 1}`,
        parent: null,
      };
      return;
    }

    // for components dragged on the board
    const { field, index } = activeData;

    setActiveField(field);
    currentDragFieldRef.current = field;
    updateData((draft) => {
      draft.fields.splice(index, 1, createSpacer({ id: active.id }));
    });
  };

  const handleDragOver = (e) => {
    const { active, over } = e;
    const activeData = getData(active);

    if (activeData.fromSidebar) {
      const overData = getData(over);

      if (!spacerInsertedRef.current) {
        const spacer = createSpacer({
          id: active.id + "-spacer",
        });

        updateData((draft) => {
          if (!draft.fields.length) {
            draft.fields.push(spacer);
          } else {
            const nextIndex =
              overData.index > -1 ? overData.index : draft.fields.length;

            draft.fields.splice(nextIndex, 0, spacer);
          }
          spacerInsertedRef.current = true;
        });
      } else if (!over) {
        updateData((draft) => {
          draft.fields = draft.fields.filter((f) => f.type !== "spacer");
        });
        spacerInsertedRef.current = false;
      } else {
        updateData((draft) => {
          const spacerIndex = draft.fields.findIndex(
            (f) => f.id === active.id + "-spacer"
          );

          const nextIndex =
            overData.index > -1 ? overData.index : draft.fields.length - 1;

          if (nextIndex === spacerIndex) {
            return;
          }

          draft.fields = arrayMove(draft.fields, spacerIndex, overData.index);
        });
      }
    }
  };

  const handleDragEnd = (e) => {
    const { over } = e;
    setActiveFormFieldId(e.active.id);
    if (!over) {
      cleanUp();
      updateData((draft) => {
        draft.fields = draft.fields.filter((f) => f.type !== "spacer");
      });
      return;
    }

    let nextField = currentDragFieldRef.current;

    if (nextField) {
      const overData = getData(over);

      updateData((draft) => {
        const spacerIndex = draft.fields.findIndex((f) => f.type === "spacer");
        draft.fields.splice(spacerIndex, 1, nextField);

        draft.fields = arrayMove(
          draft.fields,
          spacerIndex,
          overData.index || 0
        );
      });

      setShowModalForm(true);
    }

    setSidebarFieldsRegenKey(Date.now());
    cleanUp();
  };

  const { fields } = data;

  return (
    <div className="main-content">
      <div className="d-flex mb-1">
        <button className="btn btn-dark ripple m-1 ml-auto" type="button">
          Back
        </button>
      </div>
      <div className="row">
        <DndContext
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          autoScroll
        >
          <div className="card ">
            <div className="card-body ">
              <div className="row">
                <div className="col-md-9" id="builder">
                  <RequisitionCategoriesCard
                    setShowNewCategoryForm={setShowNewCategoryForm}
                    setShowNewGroupForm={setShowNewGroupForm}
                  />
                  <div className="card mb-12">
                    <div className="card-body">
                      <strong>
                        The name of the Form <i class="text-20 i-Pen-2"></i>
                      </strong>
                      <div className="row">
                        {/* <Announcements /> */}
                        <SortableContext
                          strategy={verticalListSortingStrategy}
                          items={fields.map((f) => f.id)}
                        >
                          <Board fields={fields} />
                        </SortableContext>

                        <DragOverlay dropAnimation={false}>
                          {activeSidebarField ? (
                            <SidebarField overlay field={activeSidebarField} />
                          ) : null}
                          {activeField ? (
                            <Field overlay field={activeField} />
                          ) : null}
                        </DragOverlay>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3" id="library">
                  <div className="card" style={{ height: "100%" }}>
                    <div className="card-body">
                      <strong>Add a Custom Field</strong>
                      <p>
                        Drag and drop any field type into the canvas to
                        customize the form
                      </p>
                      <ComponentLibrary fieldsRegKey={sidebarFieldsRegenKey} />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={handleSave}
                  class="btn btn-primary ripple m-1"
                  type="button"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </DndContext>
      </div>

      {/* end of main-content */}
      {/* Footer Start */}
      <Footer />
      {/* fotter end */}
      <ModalComponent
        isOpen={showModalForm}
        onRequestClose={() => setShowModalForm(false)}
        title={"Field Properties"}
        content={getModalFormData(
          data?.fields?.find((f) => f.id === activeFormFieldId)?.type
        )}
      />
      <ModalComponent
        isOpen={showNewCategoryForm}
        onRequestClose={() => setShowNewCategoryForm(false)}
        title={"New Category"}
        content={<NewCategoriesForm />}
      />
      <ModalComponent
        isOpen={showNewGroupForm}
        onRequestClose={() => setShowNewGroupForm(false)}
        title={"New Group"}
        content={<NewGroupForm />}
      />
    </div>
  );
}
