import logo from "../../assets/images/Kenna Logo.png";

const Footer = () => {
  return (
    <>
      <div className="flex-grow-1"></div>
      <div className="app-footer">
        <div className="row">
          <div className="col-md-9">
            <p>
              <strong>Kennacounsel - Diamond edition</strong>
            </p>
            <p>Kenna Partner's HR application.</p>
          </div>
        </div>
        <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
          <span className="flex-grow-1"></span>
          <div className="d-flex align-items-center">
            <div className="logo" style={{ width: "120px" }}>
              <img src={logo} alt="" />
            </div>
            <div>
              <p className="m-0">&copy; {(new Date()).getFullYear()} Kenna Partner</p>
              <p className="m-0">All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
