import React, { useState } from 'react';
import { Link } from "react-router-dom";

export const CardHeader = ({title}) => {
    return (
        <div className="card-title">{title}</div>
    )
}

export const CardHeaderWithDropDownMenu = 
(
    {
        title,
        icon = 'i-Gear-2',
        links = []
    }
) => {
    const [show, setShow] = useState('');

    const handleShow = () => {
        if (show === '') return setShow('show');

        return setShow('');
    }

    return (
        <div className="card-header d-flex align-items-center">
            <h3 className="w-50 float-start card-title m-0">{title}</h3>
            <div className={`dropdown dropleft text-end w-50 float-end ${show}`}>
                <button 
                    className="btn bg-gray-100" 
                    id="dropdownMenuButton_table1" 
                    type="button" 
                    data-bs-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false"
                    onClick={() => handleShow()}
                >
                    <i className={`nav-icon ${icon}`}></i>
                </button>
                <div className={`dropdown-menu ${show}`} aria-labelledby="dropdownMenuButton_table1" x-placement="left-start" style={{position: "absolute", willChange: "transform", top: "0px", left: "0px", transform: "translate3d(324px, 0px, 0px)"}}>
                    {
                        links?.map((link) => {
                            <Link className="dropdown-item" to={(link.href) ? link.href : '#'}>{link.title}</Link>
                        })
                    }
                </div>
            </div>
        </div>
    )
}