import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
    getEmployees,
    isEmployeeRequestLoading,
    getError,
    getConfiguration,
    saveEmployee,
    findEmployee,
    updateEmployee,
} from "../store/slices/employeeSlice";

const EmployeeService = () => {
    const dispatch = useDispatch();
    const areRequired = {
        first_name: 'First name is required',
        last_name: 'Last name is required',
        date_of_birth: 'Date of birth is required',
        gender: 'Gender is required',
        marital_status: 'Marital status is required',
        hired_on: 'Hired on is required',
        department: 'Department is required',
        designation: 'Designation is required',
        location: 'Location is required',
        access_type: 'Access type is required',
        mobile_phone: 'Mobile phone is required',
    };
    const controller = new AbortController();
    const isMounted = true;

    const get = async () => {
        try {
            dispatch(isEmployeeRequestLoading(true));
            const response = await AxiosService.get(serverUrl + '/admin/employees', {signal: controller.signal});

            if (isMounted) {
                toast.success("Employees fetched successfully!");
                dispatch(getEmployees(response.data));
                return response.data;
            }            
            
        } catch (error) {
            toast.error(error.message);
            dispatch(getError(error));
        }
    }

    const getEmployeeConfigurations = async () => {
        try {
            dispatch(isEmployeeRequestLoading());
            const response = await AxiosService.get(serverUrl + '/admin/employees/configuration/employee-configurations');
            toast.success("Configurations fetched successfully!");
            dispatch(getConfiguration(response.data));
            return response.data;
        } catch (error) {
            toast.error(error.message);
            dispatch(getError(error));
        }
    }

    const validate = (employeeData) => {
        Object.entries(areRequired).map(([key]) => {
            if (employeeData[key]) {
                delete areRequired[key];
            }
        });

        if (Object.values(areRequired).length > 0) {
            toast.error('Please fill the required fields');
        }

        return areRequired;
    }

    const reset = () => {
        return {
            first_name: '',
            middle_name: '',
            last_name: '',
            date_of_birth: '',
            gender: '',
            marital_status: '',
            hired_on: new Date(),
            department: '',
            designation: '',
            access_type: '',
            location: '',
            street: '',
            city: '',
            state: '',
            zip_code: '',
            personal_email: '',
            mobile_phone: '',
            work_email: '',
            work_phone: '',
            emergency_contact_full_name: '',
            emergency_contact_relationship: '',
            emergency_contact_phone: '',
            emergency_contact_email: '',
            emergency_contact_address: '',
            team_members: [],
            delegations: [],
            supervisor_id: '',
            is_supervisor: false,
            roles: [],
        }
    };

    const save = async (employeeData) => {
        const temp = employeeData;
        Object.entries(employeeData).map(([key]) => {
            if (!employeeData[key]) {
                delete employeeData[key];
            }
        });

        try {
            dispatch(isEmployeeRequestLoading());
            const response = await AxiosService.post(serverUrl + '/admin/employees', employeeData);
            toast.success("Employees created successfully!");
            dispatch(saveEmployee());
            return {};
        } catch (error) {
            toast.error('An error ocurred while saving a user!');
            dispatch(getError(error));
            return temp;
        }
    }

    const findById = async (employeeId) => {
        try {
            dispatch(isEmployeeRequestLoading());
            const response = await AxiosService.get(serverUrl + '/admin/employees/' + employeeId);
            toast.success('Employee record fetched successfully!');
            dispatch(findEmployee(response.data));
            return response.data;
        } catch (error) {
            toast.error('An error ocurred while saving a user!');
            dispatch(getError(error));
        }
    }

    const flatenEmployeeObject = (employee) => {
        return {
            id: employee && employee?.id,
            user_id: employee?.personal_details && employee?.personal_details?.user_id,
            first_name: employee?.personal_details && employee?.personal_details?.first_name,
            middle_name: employee?.personal_details?.middle_name,
            last_name: employee?.personal_details && employee?.personal_details?.last_name,
            date_of_birth: employee?.personal_details && employee?.personal_details?.date_of_birth,
            gender: employee?.personal_details && employee?.personal_details?.gender,
            marital_status: employee?.personal_details && employee?.personal_details?.marital_status,
            roles: employee?.personal_details && employee?.personal_details?.roles.map((role) => role.id),
            username: employee?.personal_details && employee?.personal_details?.username,
            hired_on: employee?.employment_information && employee?.employment_information?.hired_on,
            department: employee?.employment_information && employee?.employment_information?.department.id,
            designation: employee?.employment_information && employee?.employment_information?.designation.id,
            access_type: employee?.employment_information && employee?.employment_information?.access_type.id,
            employment_status: employee?.employment_information && employee?.employment_information?.employment_status,
            is_supervisor: employee?.employment_information && employee?.employment_information?.is_supervisor,
            location: employee?.employment_information && employee?.employment_information?.location.id,
            supervisor_id: employee?.employment_information && employee?.employment_information?.supervisor.supervisor_id,
            street: employee?.address_and_contact_information && employee?.address_and_contact_information?.street,
            city: employee?.address_and_contact_information && employee?.address_and_contact_information.city,
            state: employee?.address_and_contact_information && employee?.address_and_contact_information?.state,
            zip_code: employee?.address_and_contact_information && employee?.address_and_contact_information?.zip_code,
            personal_email: employee?.address_and_contact_information && employee?.address_and_contact_information?.personal_email,
            mobile_phone: employee?.address_and_contact_information && employee?.address_and_contact_information?.mobile_phone,
            work_email: employee?.employment_information && employee?.employment_information?.work_email,
            work_phone: employee?.address_and_contact_information && employee?.address_and_contact_information?.work_phone,
            emergency_contact_full_name: employee?.emergency_contact && employee?.emergency_contact.full_name,
            emergency_contact_relationship: employee?.emergency_contact && employee?.emergency_contact?.relationship,
            emergency_contact_phone: employee?.emergency_contact && employee?.emergency_contact?.phone,
            emergency_contact_email: employee?.emergency_contact && employee?.emergency_contact?.email,
            emergency_contact_address: employee?.emergency_contact && employee?.emergency_contact?.address,
            team_members: employee && employee?.team_members,
            delegations: employee && employee?.delegations
        }
    }

    const update = async (employeeData) => {
        try {
            dispatch(isEmployeeRequestLoading());
            const response = await AxiosService.patch(serverUrl + '/admin/employees/' + employeeData.id, employeeData);
            toast.success('Employee updated successfully!');
            dispatch(updateEmployee());
        } catch (error) {
            toast.error('An error ocurred while saving a user!');
            dispatch(getError(error));
        }
    }

    const passwordUpdate = async (employeeId, passwordValue) => {
        try {
            dispatch(isEmployeeRequestLoading());
            const response = await AxiosService.patch(`${serverUrl}/employees/${employeeId}/update-password`, passwordValue);
            toast.success('Employee password updated successfully!');
            dispatch(updateEmployee());
        } catch (error) {
            toast.error(error?.message);
            dispatch(getError(error));
        }
    }

    const cleanUp = () => {
        isMounted = false;
        controller.abort();
    }

    return {
        get,
        getEmployeeConfigurations,
        validate,
        reset,
        save,
        findById,
        update,
        flatenEmployeeObject,
        passwordUpdate,
        cleanUp,
    }
}

export default EmployeeService;