import React from 'react';
import ModalComponent from '../modals/modal';


const RequisitionModal = ({ isOpen, onRequestClose, title, employee }) => {
return (
    <ModalComponent
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={title}
    className="custom-modal"
    content={
        <>
        <h4 className="text-center"> <span><i className="nav-icon i-Yes"></i></span> Requisition Approval </h4>
        <div className="separator-breadcrumb border-top"></div>

        <ul className="list-group">
        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
            <span className="col-md-4">Policy: </span>
            <span className="col-md-8 border-bottom">Vehicle repair</span>
        </li>
        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
        <span className="col-md-4">Employee: </span>
        <div className="col-md-8 border-bottom">
            <div className="d-flex align-items-center">
            <div className="ul-widget-s5__pic me-3">
                <img
                id="userDropdown"
                src="../../dist-assets/images/faces/1.jpg"
                alt=""
                />
            </div>
            <div>
                <span>Tunde</span>
                <div>
                <span>
                    <strong>TMT</strong>
                </span>
                
                </div>
                <div>
                
                <span>
                    <strong>Officer 1</strong>
                </span>
                </div>
            </div>
            </div>
        </div>
        </li>
        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
            <span className="col-md-4">Line Manager:</span>
            <span className="col-md-8 border-bottom">Olagunju</span>
        </li> 

        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
            <span className="col-md-4">Request Title:</span>
            <span className="col-md-8 border-bottom">Purchase of goods</span>
        </li>

        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
            <span className="col-md-4">Reason:</span>
            <span className="col-md-8 border-bottom">Lengthy reason</span>
        </li>

        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
            <span className="col-md-4">Request Type:</span>
            <span className="col-md-8 border-bottom">Adavance</span>
        </li>
 
        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
            <span className="col-md-4">Amount:</span>
            <span className="col-md-8 border-bottom">N90,000</span>
        </li>

        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
            <span className="col-md-4">Date Needed:</span>
            <span className="col-md-8 border-bottom">30 June, 2023</span>
        </li>

        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
        <span className="col-md-4">Status:</span>
        <div className="col-md-8 d-flex align-items-center border-bottom">
            
            <span className="badge bg-success">Approved</span>
        
        </div>
        </li>

        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0">
        <span className="col-md-4">Approved Status:</span>
        <div className="col-md-8 d-flex align-items-center border-bottom">
            
            <span className="badge bg-success">Approved</span>
        
        </div>
        </li>
        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-start border-0">
            <span className="col-md-4">Approval Sequence:</span>
            <div className="col-md-8">
            <div className="card">
                <div className="card-body">
                <h5 className="card-title">Approval History</h5>
                <ul className="list-unstyled">
                    <li className="mb-3">
                    <h6 className="mb-1">None counsel expense: Raji</h6>
                    <h6 className="mb-1">Comment: </h6>
                    <p className="mb-0">Date: 20 June 2023</p>
                    </li>
                    <div className="separator-breadcrumb border-top"></div>
                    <li className="mb-3">
                    <h6 className="mb-1">None counsel expense: Mathew</h6>
                    <h6 className="mb-1">Comment: Approve</h6>
                    <p className="mb-0">Date: 25 June 2023</p>
                    </li>
                    
                </ul>
                </div>
            </div>
            </div>
        </li>
        </ul>
        </>
    }
    />
);
};

export default RequisitionModal;
