import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    employees: [],
    employee: {},
    employeeConfigurations: [],
    loading: false,
    error: null,
};

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        isEmployeeRequestLoading(state, action) {
            state.loading = true;
            state.error = false;
        },
        getEmployees(state, action) {
            state.employees = action.payload;
            state.loading = false;
            state.error = false;
        },
        findEmployee(state, action) {
            state.employee = action.payload;
            state.loading = false;
            state.error = false;
        },
        getError(state, action) {
            state.loading = false;
            state.error = action.payload
        },
        getConfiguration(state, action) {
            state.employeeConfigurations = action.payload;
            state.loading = false;
            state.error = false;
        },
        resetEmployee(state, action) {
            state.employees = action.payload;
        },
        saveEmployee(state) {
            state.loading = false;
            state.error = false;
        },
        updateEmployee(state) {
            state.loading = false;
            state.error = false;
        }
    }
});

export const {
    isEmployeeRequestLoading,
    getEmployees,
    findEmployee,
    getError,
    getConfiguration,
    resetEmployee,
    saveEmployee,
    updateEmployee,
} = employeeSlice.actions;

export default employeeSlice.reducer;