import React, { useState } from 'react';

const FormSwitch = ({text, onChange, checked=false}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange?.(!isChecked)
  };

  return (
    
    <div>
      
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={handleToggle} />
        <span className="slider round"></span>
      </label>
      <span>{isChecked ? text : ''}</span>
    </div>
  );
};

export default FormSwitch;
