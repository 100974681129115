import React from "react";
import Header from "./Header";
import SideBar from "./Sidebar";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const { isLoading } = useSelector((state) => state.loader);

  return (
    <div className="app-admin-wrap layout-sidebar-compact sidebar-dark-purple clearfix sidenav-open">
      {/* Sidebar */}
      {!isLoading && <SideBar />}

      {/* Header */}
      <div className="main-content-wrap d-flex flex-column">
        <Header />
        {children}
      </div>
      {isLoading && (
        <div className="row">
          <div className="text-center mb-3 col-sm-2">
            <div
              className="spinner-bubble spinner-bubble-info m-5"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
