import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import {
  isAppraisalLoading,
  getAppraisal,
  getAppraisalError,
  updateKeyPerformanceIndexRatings,
  resetEmployeeAppraisal,
  getEvaluation,
  setSingleEvaluationData,
  setAppraisalList
} from "../store/slices/appraisalSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const AppraisalService = () => {
  const dispatch = useDispatch();

  const getEmployeeAppraisal = async (employeeId) => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.get( serverUrl + "/kpi-ratings/employee/" + employeeId );
      toast.success("Records fetched successfully!");
      dispatch(getAppraisal(response));
    } catch (error) {
      toast.error(error?.message);
      dispatch(getAppraisalError(error?.message));
    }
  };

  const saveEmployeeAppraisal = async (userAppraisal, employeeId, param) => {
    let url = serverUrl + "/kpi-ratings";
    let data = setData(userAppraisal, param);

    if (param === "updateEmployeeRatings") {
      url = serverUrl + "/kpi-ratings/supervisor-update";
    }

    if (data.length === 0) {
      toast.error("Please select a rating!")
      return;
    }

    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.post(url, { appraisal_id: userAppraisal.id, employee_id: employeeId, kpi_ratings: data, });
      toast.success("Appraisal saved successfully!");
      dispatch(getAppraisal(response));
    } catch (error) {
      toast.error(error.message);
      dispatch(getAppraisalError(error.message));
    }
  };

  //This is meant to be a private method.
  const setData = (userAppraisal, param) => {
    let data = [];

    if (typeof userAppraisal?.cardinals === 'undefined') {
      return [];
    }

    Object.values(userAppraisal.cardinals).forEach((c) => {
      return Object.values(c.key_responsibility_areas).forEach((kra) => {
        return kra.key_performance_indices.forEach((kpi) => {
          if (
            param === "saveEmployeeRatings" &&
            (kpi.employee_rating !== null ||
              kpi.employee_justification !== null)
          ) {
            data.push(kpi);
          }

          if (
            param === "updateEmployeeRatings" &&
            (kpi.supervisor_rating !== null ||
              kpi.supervisor_justification !== null)
          ) {
            data.push(kpi);
          }
        });
      });
    });

    return data;
  };

  const getCardinals = (userAppraisal) => {
    return userAppraisal?.cardinals
      ? Object.keys(userAppraisal?.cardinals)
      : [];
  };

  const getKeyResponsibilityArea = (userAppraisal, cardinal) => {
    return cardinal &&
      userAppraisal?.cardinals[cardinal] &&
      userAppraisal?.cardinals[cardinal].key_responsibility_areas
      ? Object.keys(userAppraisal.cardinals[cardinal].key_responsibility_areas)
      : [];
  };

  const getKeyPerformanceIndices = (
    userAppraisal,
    cardinal,
    keyResponsibilityArea
  ) => {
    return userAppraisal.cardinals &&
      userAppraisal.cardinals[cardinal] &&
      userAppraisal.cardinals[cardinal].key_responsibility_areas &&
      userAppraisal.cardinals[cardinal].key_responsibility_areas[
        keyResponsibilityArea
      ]
      ? userAppraisal.cardinals[cardinal].key_responsibility_areas[
          keyResponsibilityArea
        ].key_performance_indices
      : [];
  };

  const updateKeyPerformanceIndex = (
    updatedObject,
    kpiIndex,
    cardinal,
    kra
  ) => {
    dispatch(
      updateKeyPerformanceIndexRatings({
        updatedObject,
        kpiIndex,
        cardinal,
        kra,
      })
    );
  };

  const resetEmployeeAppraisalObjects = () => {
    dispatch(resetEmployeeAppraisal());
  };

  const filterEvaluationDataByColumns = (evaluationData, columns) => {
    try {
      const filteredEvaluationData = evaluationData.map((ed) => {
        let filteredObject = {};
        columns.forEach((column) => {
          if (column.isVisible && ed.hasOwnProperty(column.col)) {
            filteredObject[column.col] = ed[column.col];
          }
        });
        return filteredObject;
      });

      return filteredEvaluationData;
    } catch (error) {
      toast.error(error);
    }
  };

  const getEvaluationData = async () => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.get( serverUrl + "/admin/kpi-ratings/evaluate" );
      toast.success("Records fetched successfully!");
      dispatch(getEvaluation(response.data));
    } catch (error) {
      dispatch(getAppraisalError(error?.message));
    }
  };

  const isKeyPerformanceIndexResponseSet = (keyPerformanceIndex, param) => {
    if (
      param === "employeeAppraisal" &&
      keyPerformanceIndex.employee_rating === null
    ) {
      return true;
    }

    if (
      param === "supervisorAppraisal" &&
      keyPerformanceIndex.supervisor_rating === null
    ) {
      return true;
    }

    return false;
  };

  const getKeyPerformanceIndicesByAppraisal = (userAppraisal) => {
    let keyPerformanceIndices = [];

    for (const cardinal in userAppraisal.cardinals) {
      for (const keyResponsibilityArea in userAppraisal.cardinals[cardinal]
        .key_responsibility_areas) {
        keyPerformanceIndices.push(
          Object.values(
            userAppraisal.cardinals[cardinal].key_responsibility_areas[
              keyResponsibilityArea
            ].key_performance_indices
          )
        );
      }
    }

    return keyPerformanceIndices;
  };

  const countKeyPerformanceIndices = (userAppraisal) => {
    let count = 0;
    const keyPerformanceIndices =
      getKeyPerformanceIndicesByAppraisal(userAppraisal);

    keyPerformanceIndices.forEach((keyPerformanceIndex) => {
      count += keyPerformanceIndex.length;
    });

    return count;
  };

  const countRatedKeyPerformanceIndices = (
    userAppraisal,
    param = "employee"
  ) => {
    let kpi = 0;
    let keyPerformanceIndices =
      getKeyPerformanceIndicesByAppraisal(userAppraisal);

    keyPerformanceIndices.forEach((keyPerformanceIndex) => {
      keyPerformanceIndex.forEach((item) => {
        if (param === "employee" && item.employee_rating !== null) {
          kpi += 1;
        }

        if (param === "supervisor" && item.supervisor_rating !== null) {
          kpi += 1;
        }
      });
    });

    return kpi;
  };

  const submitEmployeeAppraisal = async (appraisalId, employeeId) => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.post(
        serverUrl +
          "/appraisals/" +
          appraisalId +
          "/" +
          "employee/" +
          employeeId
      );
      toast.success("Appraisal submitted successfully!");
      dispatch(getAppraisal(response.data));
    } catch (error) {
      toast.error("Error fetching appraisals");
      dispatch(getAppraisalError(error?.message));
    }
  };

  const submitSupervisorAppraisal = async (
    appraisalId,
    employeeId,
    supervisorId
  ) => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.post(
        serverUrl +
          "/appraisals/" +
          appraisalId +
          "/" +
          "employee/" +
          employeeId +
          "/supervisor/" +
          supervisorId
      );
      toast.success("Appraisal submitted successfully!");
      dispatch(getAppraisal(response.data));
    } catch (error) {
      toast.error("Error fetching appraisals");
      dispatch(getAppraisalError(error?.message));
    }
  };

  const getSingleAppraisalEvaluationData = async (appraisalId, employeeId) => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.get(`${serverUrl}/admin/kpi-ratings/evaluate/appraisal/${appraisalId}/employee/${employeeId}`);
      dispatch(setSingleEvaluationData(response.data));
    } catch (error) {
      toast.error(error?.message);
    }
  }

  const getAppraisalEvaluationDataForEmployee = async () => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.get(`${serverUrl}/kpi-ratings/employee-review`);
      dispatch(setSingleEvaluationData(response.data));
    } catch (error) {
      toast.error(error?.message);
    }
  }
  
  const getAppraisalList = async () => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.get(`${serverUrl}/admin/appraisals`);
      dispatch(setAppraisalList(response.data));
    } catch (error) {
      toast.error(error?.message);
    }
  }

  const updateAppraisal = async (appraisal) => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.patch(serverUrl + '/admin/appraisals/' + appraisal.id, appraisal);
      dispatch(setAppraisalList(response.data));
    } catch (error) {
      toast.error('An error occurred while updating the appraisal');
    }
  }

  const store = async (newAppraisalName) => {
    try {
      dispatch(isAppraisalLoading(true));
      const response = await AxiosService.post(`${serverUrl}/admin/appraisals`, { name: newAppraisalName });
      dispatch(setAppraisalList(response.data));
    } catch (error) {
      toast.error('An error occurred while creating the appraisal');
    }
  }

  const filterAppraisalEvaluationRecord = (singeEvaluationData, cardinal) => {
    if (singeEvaluationData?.kpi_ratings?.length === 0) return [];

    return singeEvaluationData?.kpi_ratings?.filter(kpi_rating => kpi_rating.cardinal_name === cardinal.name);
  }

  return {
    getEmployeeAppraisal,
    getEvaluationData,
    filterEvaluationDataByColumns,
    saveEmployeeAppraisal,
    getCardinals,
    getKeyResponsibilityArea,
    getKeyPerformanceIndices,
    updateKeyPerformanceIndex,
    resetEmployeeAppraisalObjects,
    isKeyPerformanceIndexResponseSet,
    countRatedKeyPerformanceIndices,
    countKeyPerformanceIndices,
    getKeyPerformanceIndicesByAppraisal,
    submitEmployeeAppraisal,
    submitSupervisorAppraisal,
    getSingleAppraisalEvaluationData,
    getAppraisalEvaluationDataForEmployee,
    getAppraisalList,
    updateAppraisal,
    store,
    filterAppraisalEvaluationRecord
  };
};

export default AppraisalService;
