import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
    isLoading,
    setCardinals
} from  "../store/slices/cardinalSlice";

const CardinalService = () => {
    const dispatch = useDispatch();

    const getCardinals = async () => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.get(`${serverUrl}/cardinals`);
            dispatch(setCardinals(response.data));
        } catch (error) {
            toast.error("Error fetching cardinals");
        }
    }

    return {
        getCardinals,
    }
}

export default CardinalService;