export const formatDate = (dateToFormat) => {
    const dateObj = new Date(dateToFormat);
    return new Date(dateObj.getTime() - (dateObj.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
}

export const uppercaseFirst = (word) => {
    if (typeof word === 'undefined' || word === null) return '';
    
    if (word === ' ') return word;

    return word[0].toUpperCase() + word.substring(1);
}

export const uppercaseWord = (lowercaseString) => {   
    if (typeof lowercaseString === 'undefined' || lowercaseString === null) return '';
    if (lowercaseString === ' ') return words;

    const words = lowercaseString.split(" ");
    
    return words.map((word) => {
        if (word) return word[0].toUpperCase() + word.substring(1);        
    }).join(" ");
}

export const excerpt = (text, length = 34) => {
    if (typeof text === 'undefined' || text === null) return '';

    if (text.length < length) return text;

    let excerpt = text.substring(0, length - 1);
    return `${excerpt}...`;
}