import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import AuthenticationService from '../services/AuthenticationService';

const TTLCountdown = () => {
    const initialTime = JSON.parse(sessionStorage.getItem('ttl'));
    const [timeRemaining, setTimeRemaining] = useState(initialTime);
    const [show, setShow] = useState(false);
    const accessToken = JSON.parse(sessionStorage.getItem("token"));
    const navigate = useNavigate();
    const authenticationService = AuthenticationService();

    useEffect(() => {
        if (accessToken === null) return navigate('/');
        
        const timerInterval = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime === 300) {
                    setShow(true);
                    return prevTime - 1;
                }

                if (prevTime === 0) {
                    sessionStorage.clear();
                    localStorage.clear();
                    return navigate('/');
                }

                const remainingTime = prevTime - 1;
                sessionStorage.setItem("ttl", JSON.stringify(remainingTime));
                return remainingTime;
            });
        }, 1000);

        return () => clearInterval(timerInterval);
    }, []);

    const hanldeClose = () => setShow(false);

    const handleRenewToken = () => {
        authenticationService.refreshToken();
    }

    return (
        <Modal show={show} onHide={hanldeClose} keyboard={false} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Test</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <p style={{ textAlign: 'center', verticalAlign: 'middle', lineHeight: '90px' }}>Your session will expire in 5 minutes.</p>
                </>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleRenewToken}> Extend Session </Button>
                <Button variant="secondary" onClick={hanldeClose}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
}

export default TTLCountdown;