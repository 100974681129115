import { Routes, Route } from "react-router-dom";
import Payslip from "../../views/payroll/Payslip";
import PayrollUpload from "../../views/payroll/PayrollUpload";
import PayrollView from "../../views/payroll/PayrollView";

const PayslipRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Payslip />} />
      <Route path="/payroll/manage" element={ <PayrollUpload /> } />
      <Route path="/payroll/view" element={ <PayrollView /> } />
    </Routes>
  );
};

export default PayslipRoutes;