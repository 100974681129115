import { useState } from "react";

const ComponentPropertiesForm = ({ id }) => {
  //initialise the attributes to the values on the form elements
  const [componentProperties, setComponentProperties] = useState({
    isRequired: false,
    fieldName: "",
  });
  const onCheckRequired = (e) => {
    let { checked } = e.target;
    setComponentProperties((ps) => {
      return { ...ps, isRequired: checked };
    });
  };

  const handleChangeFieldName = (e) => {
    let { value } = e.target;
    setComponentProperties((ps) => {
      return { ...ps, fieldName: value };
    });
  };

  const handleDeleteField = (e) => {
    //delete field from redux store here
  };

  return (
    <div>
      <h5 className="mb-3">Behaviour</h5>
      <label className="checkbox checkbox-primary">
        <input
          type="checkbox"
          checked={componentProperties.isRequired}
          onChange={onCheckRequired}
        />
        <span>Required when submitting the form</span>
        <span className="checkmark"></span>
      </label>
      <div className="col-md-6 form-group mb-3">
        <label htmlFor="fieldLabel">Field Label</label>
        <input
          className="form-control"
          id="fieldLabel"
          type="text"
          placeholder="Enter details here"
          onChange={handleChangeFieldName}
        />
      </div>
      <div className="row">
        <div className="col-md-10">
          <button
            className="btn btn-danger ripple m-1"
            type="button"
            onClick={handleDeleteField}
          >
            Delete Field
          </button>
        </div>
        <div className="col text-right">
          <button className="btn btn-primary ripple m-1" type="button">
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComponentPropertiesForm;
