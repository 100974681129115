import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '../../components/layout/BreadCrumb';
import HtmlTable from '../../components/tables/HtmlTable';
import Footer from '../../components/layout/Footer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PayslipTable from "../../components/tables/PayslipTable";
import TTLCountdown from '../../components/TTLCountdown';

const PayrollView = () => {
    const location = useLocation();
    const { payroll } = location?.state || {};
    const [payslips, setPayslips] = useState(payroll.payslips);
    const [show, setShow] = useState(false);
    const [payslip, setPayslip] = useState({});    

    const handleClose = () => setShow(false);
    const handleShow = (payslip) => {
        setShow(true);
        setPayslip(payslip);
    }

    const showTable = () => (<PayslipTable {...payslip} />);

    const columns = [
        {
            label: 'S/N',
            style: {},
            render: (payslip) => payslip.id
        },
        {
            label: 'Name',
            style: { position: 'sticky', zIndex: '9', left: '0', backgroundColor: 'green', color: 'white' },
            render: (payslip) => <a style={{ color: 'white', cursor: "pointer" }} onClick={() => handleShow({0: payslip})}>{payslip.employee_name}</a>
        },
        {
            label: 'Actual Annual Gross',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.actual_annual_gross}</span>,
        },
        {
            label: 'Monthly Gross',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.actual_annual_gross}</span>
        },
        {
            label: 'Basic',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.basic}</span>
        },
        {
            label: 'Housing',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.housing}</span>
        },
        {
            label: 'Transportation',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.transportation}</span>
        },
        {
            label: 'Others',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.others}</span>
        },
        {
            label: 'Employee Pension Contribution',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.employee_pension_contribution}</span>
        },
        {
            label: 'Monthly Tax Payable',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.monthly_tax_payable}</span>
        },
        {
            label: 'Loan Salary Advance',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.loan_salary_advance}</span>
        },
        {
            label: 'Other Deductions',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.other_deductions}</span>
        },
        {
            label: 'Penalties',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.penalties}</span>
        },
        {
            label: 'Coporative Contributions',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.coperative_contributions}</span>
        },
        {
            label: 'Other Allowances',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.other_allowances}</span>
        },
        {
            label: 'Thirteenth Month',
            style: { textAlign: 'right' },
            render: (payslip) => <span>&#8358; {payslip.thirteenth_month}</span>
        },
        {
            label: 'Monthly Net Salary',
            style: { textAlign: 'right', backgroundColor: 'green', color: 'white' },
            render: (payslip) => <span>&#8358; {payslip.monthly_net_salary}</span>
        }
    ];

    return (
        <div className="main-content">
            <BreadCrumb title="Payroll View" parentLink="#" parentTitle="Pages" currentTitle="View payroll" />
            <div className="separator-breadcrumb border-top"></div>

            <div className="row">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="card card-icon mb-4">
                                <div className="card-body text-center">
                                    <i className="i-Data-Upload"></i>
                                    <p className="text-muted mt-2 mb-2">Payslip Uploads</p>
                                    <p className="text-primary text-18 line-height-1 m-0">{Object.entries(payslips).length}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="card card-icon mb-4">
                                <div className="card-body text-center">
                                    <i className="i-Data-Download"></i>
                                    <p className="text-muted mt-2 mb-2">Download File</p>
                                    <p className="text-primary text-18 line-height-1 m-0"><a href={payroll.file_path}>Here</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="card card-icon mb-4">
                                <div className="card-body text-center">
                                    <i className="i-Information"></i>
                                    <p className="text-muted mt-2 mb-2">Status</p>
                                    <p className="text-primary text-18 line-height-1 m-0">{(payroll.is_active) ? 'Enabled' : 'Disabled'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="card card-icon mb-4">
                                <div className="card-body text-center">
                                    <i className="i-Calendar-4"></i>
                                    <p className="text-muted mt-2 mb-2">Uploaded At</p>
                                    <p className="text-primary text-18 line-height-1 m-0">{new Date(payroll.uploaded_at).toDateString()}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title">Information</div>
                                    <div className="ul-widget-app__browser-list">
                                        <div className="ul-widget-app__browser-list-1 mb-4">
                                            <i className="i-Calendar-4 text-white bg-warning rounded-circle p-2 me-3"></i><span className="text-15">Date From</span><span className="text-mute">{new Date(payroll.date_from).toDateString()}</span>
                                        </div>
                                        <div className="ul-widget-app__browser-list-1 mb-4">
                                            <i className="i-Calendar-4 text-white green-500 rounded-circle p-2 me-3"></i><span className="text-15">Date To</span><span className="text-mute">{new Date(payroll.date_to).toDateString()}</span>
                                        </div>
                                        <div className="ul-widget-app__browser-list-1 mb-4">
                                            <i className="i-Add-User text-white cyan-500 rounded-circle p-2 me-3"></i><span className="text-15">Uploaded By</span><span className="text-mute">{payroll.uploaded_by}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-md-8">
                    <div className="card o-hidden mb-4">
                        <div className="card-header d-flex align-items-center">
                            <h3 className="w-50 float-start card-title m-0">Payslips</h3>
                        </div>

                        <div className="card-body">
                            <HtmlTable
                                isVerticallyScrollable={true}
                                columns={columns}
                                showFooter={true}
                                data={payslips}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Payslip</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showTable()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <TTLCountdown />
            <Footer />
        </div>
    );
}

export default PayrollView;