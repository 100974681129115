import { Route, Routes } from "react-router-dom";
import NewRequestSelector from "../requisition/NewRequestSelector";
import RequestForm from "../../views/requisition/RequestForm";
import RequestManagement from "../../views/RequestManagement";
import RequisitionHistory from "../../views/requisition/RequisitionHistory";
import FormBuilder from "../requisition/form_builder/FormBuilder";
import ViewRequisition from "../requisition/ViewRequisition";
import RequisitionManagement from "../../views/requisition/RequisitionManagement";
import EditRequestForm from "../../views/requisition/EditRequestForm";

const RequisitionRoutes = () => {
  return (
    <Routes>
      <Route path="/requisition_management" element={<RequestManagement />} />
      <Route path="/form_builder" element={<FormBuilder />} />
      <Route path="/" element={<RequisitionHistory />} />
      <Route path="/details" element={<ViewRequisition />} />
      <Route path="/service" element={<NewRequestSelector category="Service" />} />
      <Route path="/expense" element={<NewRequestSelector category="Expense" />} />
      <Route path="/:id" component={RequestForm} element={<RequestForm />} />
      <Route path="/manage" element={<RequisitionManagement />} />
      <Route path="/edit" element={<EditRequestForm />} />
    </Routes>
  );
};

export default RequisitionRoutes;
