import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";

const OnboardingService = () => {

    const get = async () => {
        const response = await AxiosService.get(serverUrl + '/admin/onboarding-configurations');
        return response;
    }

    return {get}
}

export default OnboardingService;