import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Field from "./Field";
function SortableField(props) {
  const { id, index, field } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      data: {
        index,
        id,
        field,
      },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: "15px",
  };

  return (
    <div
      className="col-lg-12 col-md col-sm form-group mb-3 mt-10"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      activationConstraint={{
        delay: 300,
        tolerance: 5,
      }}
    >
      <label>
        Component name {true && <span className="text-danger">*</span>}
      </label>{" "}
      <i class="text-20 i-Pen-2"></i>
      <Field field={field} />
    </div>
  );
}

export default SortableField;
