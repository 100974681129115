import React, { useState, useEffect, useRef } from 'react';

const TableDropDown = ({ options }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`btn-group ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
        <button
            className="btn btn-secondary btn-sm dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : 'false'}
            onClick={(e) => {
            e.stopPropagation();
            toggleDropdown();
            }}
        >
            Options <span className="caret"></span>
        </button>
        <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`} style={{ marginTop: '30px' }}>
            {options.map((option, index) => (
            <li key={index}>
                <button
                className="dropdown-item"
                    onClick={(e) => {
                    option.action();
                    e.stopPropagation(); 
                    }}
                >
                {option.label}
                </button>
            </li>
            ))}
        </ul>
        </div>
    );
};

export default TableDropDown;
