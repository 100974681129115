import background from "../assets/images/jess-bailey-q10VITrVYUM-unsplash.jpg";
import logo from "../assets/images/Kenna Logo.png";
import {useState } from "react";
import axios from "axios";
import { serverUrl } from "../utils/constants";
import { toast } from "react-toastify";

const ResetPassword = () => {
const [pwd, setPwd] = useState("");
//const [pwdConfirm, setPwdConfirm] = useState("");
const [isLoading, setIsLoading] = useState(false);
const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    //prepare header
    const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": "",
        accept: "application/json",
    },
    };
    //post user credentials to server
    try {
    const result = await axios.post(
        serverUrl + "auth/resetpassword",
        {
        password: pwd,
        },
        config
    );

    const {user} = result.data;
    if (user) {
        //save user credentials in context
        toast.success('Password reset successfully.');
        setIsLoading(false);
    }
    else {
        const data = await result.json();
        toast.error(data.error);
        setIsLoading(false);

    }
    } catch (err) {
      //console.log(err);
        toast.error('An error occurred.');
        setIsLoading(false);
    }
};

if (isLoading) {
    return (
    <div className="main-content">
        <div className="row">
        <div class="text-center mb-3 col-sm-2">
            <p>
            <div
                class="spinner-bubble spinner-bubble-info m-5"
                style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                }}
            ></div>
            </p>
        </div>
        </div>
        {/* end of main-content */}
    </div>
    ); // <-- return early with loading message
}

return (
    <>
    <div
        className="auth-layout-wrap"
        style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        }}
    >
        <div className="auth-content">
        <div className="row">
            <div className="card o-hidden">
            <div className="p-4">
                <div className="auth-logo text-center mb-4">
                <img
                    src={logo}
                    alt=""
                    style={{ height: "90px", width: "150px" }}
                />
                </div>
                <h1 className="mb-3 text-18">Reset Password </h1>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                    style={{
                        backgroundColor: "#F5EAFF",
                        borderColor: "#731EC9",
                    }}
                    className="form-control form-control-rounded"
                    type="password"
                    name="pwd"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    />
                </div>

                <button className="btn btn-rounded btn-primary w-100 mt-2">
                    Reset Password
                </button>
                </form>
            </div>
            </div>
        </div>
        </div>
    </div>
    </>
);
};

export default ResetPassword;
