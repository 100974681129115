import { configureStore } from '@reduxjs/toolkit';
import appraisalReducer from './slices/appraisalSlice';
import LoaderReducer from './slices/LoaderSlice';
import requestReducer from './slices/requestSlice';
import employeeReducer from './slices/employeeSlice';
import workflowStateReducer from './slices/workflowStateSlice';
import holidayReducer from './slices/holidaySlice';
import leaveReducer from './slices/leaveSlice';
import cardinalReducer from './slices/cardinalSlice';
import kraReducer from './slices/kraSlice';
import requestInputReducer from './slices/requestInputSlice';
import authenticationReducer from './slices/authenticationSlice';

const store = configureStore({
    reducer: {
        appraisal: appraisalReducer,
        loader: LoaderReducer,
        request: requestReducer,
        employee: employeeReducer,
        workflowState: workflowStateReducer,
        holiday: holidayReducer,
        leave: leaveReducer,
        cardinal: cardinalReducer,
        kra: kraReducer,
        requestInput: requestInputReducer,
        authenticationData: authenticationReducer,
    },
})

export default store;
