import "./assets/css/lite-purple.css";
import "./assets/css/plugins/perfect-scrollbar.min.css";
import {  useEffect } from "react";
import Layout from "./components/layout/Layout";
import Login from "./views/Login";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./utils/context/AuthContext";
import ProfileRoutes from "./components/routes/Profile";
import SettingsRoutes from "./components/routes/Settings";
import AppraisalRoutes from "./components/routes/Appraisal";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequisitionRoutes from "./components/routes/Requisition";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import LeaveRoutes from "./components/routes/Leave";
import PayslipRoutes from "./components/routes/PayslipRoutes";
import NotFound from "./views/NotFound";
import DashboardRoutes from "./components/routes/DashboardRoutes";
import AddHoliday from "./views/AddHoliday";

function App() {
  useEffect(() => {
    //add class to body element
    document.body.classList.add("text-start");
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer />
      <div className="App">
        <AuthProvider>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/forgot-password" element={<ForgotPassword />} />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route path="/*" element={<ProtectedRoutes />} />
            </Routes>
        </AuthProvider>
      </div>
    </Provider>
  );
}

function ProtectedRoutes() {
  return (
    <Routes>
      <Route
        exact
        path="/dashboard/*"
        element={
          <Layout>
            <DashboardRoutes />
          </Layout>
        }
      />
      <Route
        exact
        path="/appraisal/*"
        element={
          <Layout>
            <AppraisalRoutes />
          </Layout>
        }
      />
      <Route
        exact
        path="/profile/*"
        element={
          <Layout>
            <ProfileRoutes />
          </Layout>
        }
      />
      <Route
        exact
        path="/settings/*"
        element={
          <Layout>
            <SettingsRoutes />
          </Layout>
        }
      />

      <Route
        exact
        path="/requisition/*"
        element={
          <Layout>
            <RequisitionRoutes />
          </Layout>
        }
      />

      <Route
        exact
        path="/leave/*"
        element={
          <Layout>
            <LeaveRoutes />
          </Layout>
        }
      />

      <Route exact path="/payslip/*" element={<Layout> <PayslipRoutes /> </Layout>} />

      <Route path='*' element={<NotFound />} />

      <Route exact path="settings/holiday/*" element={<Layout> <AddHoliday /> </Layout>} />

    </Routes>
  );
}

export default App;
