import React from 'react'

const EmployeeCards = () => {
  
  return (
    
    <div className="row">
            
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div className="card-body text-center">
          <i className="i-Add-User"></i>
          <div className="content">
            <p className="text-muted mt-2 mb-0">Total Employees</p>
            <p className="text-primary text-24 line-height-1 mb-2">5</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div className="card-body text-center">
          <i className="i-Add-User"></i>
          <div className="content">
            <p className="text-muted mt-2 mb-0">Dept 2</p>
            <p className="text-primary text-24 line-height-1 mb-2">10</p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div className="card-body text-center">
          <i className="i-Add-User"></i>
          <div className="content">
            <p className="text-muted mt-2 mb-0">Dept 3</p>
            <p className="text-primary text-24 line-height-1 mb-2">15</p>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div className="card-body text-center">
          <i className="i-Add-User"></i>
          <div className="content">
            <p className="text-muted mt-2 mb-0">Dept 4</p>
            <p className="text-primary text-24 line-height-1 mb-2">20</p>
          </div>
        </div>
      </div>
    </div>
    
</div>
  );
};

export default EmployeeCards;
