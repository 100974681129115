import { Route, Routes } from "react-router-dom";
import Settings from "../../views/Settings";
import Delegation from "../../views/Delegation";
import AppraisalEvaluation from "../../views/admin/appraisal/AppraisalEvaluation";
import AppraisalSetup from "../../views/admin/appraisal/AppraisalSetup";
import CardinalSetup from "../../views/admin/cardinal/CardinalSetup";
import KRASetup from "../../views/admin/key-responsibility-area/KRASetup";
import KPISetup from "../../views/admin/key-performance-index/KPISetup";
import EmployeeList from "../../views/admin/employee/EmployeeList";
import EmployeeEdit from "../../views/admin/employee/EmployeeEdit";
import EmployeeSetUp from "../../views/admin/employee/EmployeeSetUp";
import RequisitionList from "../../views/admin/RequisitionList";
import SingleAppraisalEvalution from "../../views/admin/appraisal/SingleAppraisalEvalution";
import RequestInput from "../../views/admin/RequestInput";

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Settings />} />
      <Route path="/delegation" element={<Delegation />} />
      <Route path="/appraisal/evaluation" element={<AppraisalEvaluation />} />
      <Route path="/appraisal/evaluation/employee" element={<SingleAppraisalEvalution />} />
      <Route path="/appraisal/setup" element={<AppraisalSetup />} />
      <Route path="/appraisal/cardinal/setup" element={<CardinalSetup />} />
      <Route path="/appraisal/key-responsibility-area/setup" element={<KRASetup />} />
      <Route path="/appraisal/key-performance-index/setup" element={<KPISetup />} />
      <Route path="/employee/list" element={ <EmployeeList />} />
      <Route path="/employee/edit" element={ <EmployeeEdit /> } />
      <Route path="/employee/create" element={ <EmployeeSetUp /> } />
      <Route path="/requisition/list" element={ <RequisitionList /> } />
      <Route path="/request/input" element={<RequestInput />} />
    </Routes>
  );
};

export default SettingsRoutes;
