import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cardinals: [],
    isCardinalLoading: false,
    error: null,
}

const cardinalSlice = createSlice({
    name: "cardinal",
    initialState,
    reducers: {
        setCardinals(state, action) {
            const items = action.payload;
            state.cardinals = items?.map((item, index) => ({...item, index: ++index}));
            state.isCardinalLoading = false;
            state.error = null
        },
        isLoading(state) {
            state.isCardinalLoading = true;
            state.error = null;
        }
    }
});

export const { setCardinals, isLoading } = cardinalSlice.actions;

export default cardinalSlice.reducer;