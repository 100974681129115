import { Link } from "react-router-dom";
import "../../assets/css/custom/requisition-styles.css";

const RequestTypeButton = ({ id, title, description, group }) => {
  
  const icons = {
    admin: "i-Engineering",
    counsel: "i-Business-Mens",
    it: "i-Computer-Secure",
    general: "i-Cube-Molecule-2",
  };

  return (
    <div key={id} className="col-lg-3 col-md-6 mb-5">
      <Link className="settings-menu" to={`/requisition/${id}`}>
        <div className="p-4 border border-light rounded d-flex align-items-center card-min-height">
          <i className={`${icons[group]}  text-32 me-3`}></i>
          <div>
            <h4 className="text-18 mb-1">{title}</h4>
            <span>{description}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default RequestTypeButton;
