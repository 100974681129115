import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    requests: [],
    isRequestLoading: false,
    isFormLoading: false,
    requestTypes: [],
    requisitionRequest: {},
    requestList: [],
    error: null,
    adminRequestList: [],
  };

const requestSlice = createSlice({
    name: "request",
    initialState,
    reducers: {
        isLoading(state, action) {
            state.isRequestLoading = true;
            state.error = false;
        },
        isRequestFormLoading(state) {
            state.isFormLoading = true;
            state.error = false;
        },
        getRequests(state, action) {
            const items = action.payload;
            state.requests = items?.map((request, index) => ({ ...request, index: index + 1, }));
            state.isRequestLoading = false;
            state.error = false;
        },
        getRequestError(state, action) {
            state.isRequestLoading = false;
            state.error = action.payload;
        },
        resetRequest(state) {
            state.requests = [];
            state.requisitionRequest = [];
            state.requestList = [];
            state.isRequestLoading = false;
        },
        removeItemFromRequestList(state, action) {
            const item = action.payload;
            state.requestList = state.requestList.filter(request => request.employee_request_id !== item.employee_request_id);
            state.isRequestLoading = false;
            state.error = false;
        },
        getRequestTypes(state, action) {
            state.requestTypes = action.payload;
            state.isRequestLoading = false;
            state.error = false;
        },
        getRequisitionRequest(state, action) {
            state.requisitionRequest = action.payload;
            state.isRequestLoading = false;
            state.error = false;
            state.isFormLoading = false;
        },
        getRequestList(state, action) {
            const items = action.payload;
            state.requestList = items?.map((request, index) => ({ ...request, index: index + 1, }));
            state.isRequestLoading = false;
            state.error = false;
        },
        getRequestListForAdmin(state, action) {
            const items = action.payload;
            state.adminRequestList = items?.map((request, index) => ({ ...request, index: ++index, }));
            state.isRequestLoading = false;
            state.error = false;
        }
    },
});

export const {
    isLoading,
    getRequests,
    getRequestError,
    resetRequest,
    removeItemFromRequestList,
    getRequestTypes,
    getRequisitionRequest,
    getRequestList,
    isRequestFormLoading,
    getRequestListForAdmin,
} = requestSlice.actions;

export default requestSlice.reducer;