import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    holidays: [],
    isHolidayLoading: false,
    error: null,
};

const holidaySlice = createSlice({
    name: 'holidays',
    initialState,
    reducers: {
        isLoading(state) {
            state.isHolidayLoading = true;
        },
        setHolidays(state, action) {
            const items = action.payload;
            state.holidays = items?.map((holiday, index) => ({...holiday, index: index + 1}));
            state.isHolidayLoading = false;
            state.error = null;
        },
        getHolidayError(state, action) {
            state.error = action.payload;
            state.isHolidayLoading = false;
        }
    }
});

export const { isLoading, setHolidays, getHolidayError } = holidaySlice.actions;

export default holidaySlice.reducer;