import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from "../components/layout/Footer";
import BreadCrumb from "../components/layout/BreadCrumb";
import face1 from "../assets/images/faces/1.jpg";
import DashboardService from '../services/DashboardService';
import getBadgeColorByStatus from '../utils/badgeColor';
import Loader from '../components/Loader';
import { excerpt, uppercaseFirst, uppercaseWord } from '../utils/helper';
import TTLCountdown from '../components/TTLCountdown';

const Dashboard = () => {
  const user = JSON.parse(sessionStorage.getItem("user"))?.data;
  const dashboardService = DashboardService();
  const [configs, setConfigs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [windowZoom, setWindowZoom] = useState(1);
  
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await dashboardService.getConfig();
      setConfigs(response);

      setIsLoading(false);

      setWindowZoom(window.outerWidth / window.innerWidth);
    })();
  }, []);

  const getBirthdays = () => {
    const { birthdays } = configs ?? [];
    if (typeof birthdays === 'undefined' || birthdays.length === 0) {
      return <p style={{textAlign: 'center', verticalAlign: 'middle', lineHeight: '90px'}}>No Birthday Celebrants Today.</p>;
    }

    return (
      birthdays.map((birthday) => {
        return (
          <div className="col-4 text-center">
            <div className="ul-widget-app__social-friends mb-4" key={`${birthday.first_name} ${birthday.last_name}`}>
              <img className="avatar-md rounded me-3" src={face1} alt="" />
              <div className="ul-widget-app__small-title">
                <span className="t-font-bolder">{`${uppercaseFirst(birthday.first_name)} ${uppercaseFirst(birthday.last_name)}`}</span><span className="text-primary"></span>
              </div>
            </div>
          </div>
        )
      })
    );
  }

  const getExpenseRequisitionTable = () => {
    const { expense_requisitions } = configs ?? [];

    if (typeof expense_requisitions === 'undefined' || expense_requisitions.length === 0) {
      return <p style={{ textAlign: 'center', verticalAlign: 'middle', lineHeight: '90px' }}>No Expense Requisitions.</p>;
    }

    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Policy</th>
              <th scope="col">Status</th>
              <th scope="col">Date/Time</th>
            </tr>
          </thead>
          <tbody>
            {
              expense_requisitions.map(requisition => {
                return (
                  <tr key={requisition.employee_request_id}>
                    <td data-html="true" data-bs-toggle="tooltip" data-bs-placement="left" title={requisition.request_name} data-original-title={requisition.request_name}>
                      {(windowZoom > 1) ? excerpt(requisition.request_name, 16) : requisition.request_name}
                    </td>
                    <td>
                      <span className={`badge bg-${getBadgeColorByStatus(requisition.state_type_name)}`} data-html="true" data-bs-toggle="tooltip" data-bs-placement="left" title={requisition.state_name} data-original-title={requisition.state_name}>
                        {(windowZoom > 1) ? excerpt(requisition.state_name, 10) : uppercaseWord(requisition.state_name)}
                      </span>
                    </td>
                    <td>{requisition.request_created_at}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  }

  const getServiceRequisitions = () => {
    const { service_requisitions } = configs ?? [];

    if (typeof service_requisitions === 'undefined' || service_requisitions.length === 0) {
      return <p style={{ textAlign: 'center', verticalAlign: 'middle', lineHeight: '90px' }}>No Service Requisitions.</p>;
    }

    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Policy</th>
              <th scope="col">Status</th>
              <th scope="col">Date/Time</th>
            </tr>
          </thead>
          <tbody>
            {
              service_requisitions.map(requisition => {
                return (
                  <tr key={requisition.employee_request_id}>
                    <td data-html="true" data-bs-toggle="tooltip" data-bs-placement="left" title={requisition.request_name} data-original-title={requisition.request_name}>
                      {(windowZoom > 1) ? excerpt(requisition.request_name, 16) : requisition.request_name}
                    </td>
                    <td>
                      <span className={`badge bg-${getBadgeColorByStatus(requisition.state_type_name)}`} data-html="true" data-bs-toggle="tooltip" data-bs-placement="left" title={requisition.state_name} data-original-title={requisition.state_name}>
                        {(windowZoom > 1) ? excerpt(requisition.state_name, 10) : uppercaseWord(requisition.state_name)}
                      </span>
                    </td>
                    <td>{requisition.request_created_at}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  }

  const getTeamMembers = (teamMembers) => {
    if (!teamMembers || teamMembers.length === 0) {
      return <p style={{textAlign: 'center', verticalAlign: 'middle', lineHeight: '90px'}}>You do not have team members.</p>;
    }          
          
    return teamMembers.map((teamMember) => {
      return (
        <div className="col-4 text-center" key={teamMember.employee_id}>
          <div className="ul-widget-app__social-friends mb-4">
            <img className="avatar-md rounded me-3" src={face1} alt="" />
            <div className="ul-widget-app__small-title">
              <span className="t-font-bolder">{teamMember.name}</span><span className="text-primary"></span>
            </div>
          </div>
        </div>
      )
    });
  }

  if (isLoading) return <Loader />;

  return (
    <div className="main-content">
      <BreadCrumb title="Dashboard" parentLink="#" parentTitle="Pages" currentTitle="Dashboard" />
      <div className="separator-breadcrumb border-top"></div>
      <div className="row mb-4">
        {/* Update profile Card */}
        <div className="col-md-4 mb-4">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="card card-profile-1" style={{minHeight: '344px'}}>
                <div className="card-body text-center">
                  <div className="avatar box-shadow-2 mb-3">
                    <img src={face1} id="userDropdown" alt="" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                  </div>
                  <h5 className="m-0">{`${uppercaseFirst(user?.first_name)} ${uppercaseFirst(user?.last_name)}`}</h5>
                  <p className="mt-0">{user?.employee.employment_information.designation.name}</p>
                  <p className="text-small text-muted">Department: {user?.employee.employment_information.department.name}</p>
                  <p className="text-small text-muted">Supervisor: {user?.employee.employment_information?.supervisor.name === undefined ? <p></p> : user?.employee.employment_information?.supervisor.name}</p>
                  <Link className="btn btn-primary btn-rounded" to={'/profile'}>
                    Update Profile
                  </Link>
                </div>
              </div>

            </div>            
          </div>
        </div>

        {/* Employment information */}
        <div className="col-md-4 mb-4">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 text-white">
              <div className="card card-icon mb-4 bg-primary">
                <div className="card-body text-center">
                  <i className="text-20 i-Calendar-4"></i>
                  <p className="mt-2 mb-2">Hired on</p>
                  <p className="card-text">{(new Date(user?.employee.employment_information.hired_on)).toDateString()}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 text-white">
              <div className="card card-icon mb-4 bg-primary">
                <div className="card-body text-center">
                  <i className="text-20 i-Calendar-4"></i>
                  <p className="mt-2 mb-2">Date of birth</p>
                  <p className="card-text">{(new Date(user?.date_of_birth)).toDateString()}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="card card-icon mb-4 border border-light rounded">
                <div className="card-body text-center">
                  <i className="text-20 i-Building"></i>
                  <p className="text-muted mt-2 mb-2">Employment status</p>
                  <p className="text-primary card-text">{uppercaseFirst(user?.status.value)}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="card card-icon mb-4 border border-light rounded">
                <div className="card-body text-center">
                  <i className="text-20 i-Map-Marker"></i>
                  <p className="text-muted mt-2 mb-2">Location</p>
                  <p className="text-primary card-text">{uppercaseFirst(user?.employee.employment_information.location.name)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Team members */}
        <div className="col-md-4 mb-4">
          <div className="card" style={{minHeight: '345px',maxHeight: '345px', overflowY: 'scroll'}}>
            <div className="card-header purple-500 text-purple-500 p-4">
              <div className="d-flex align-items-center">
                <div className="card-title text-white mb-0">Team Members</div>
              </div>
            </div>
            <div className="card-body">
              <div className="ul-widget-app__social-site mt-4 mb-4">
                <div className="row">
                  {getTeamMembers(user?.employee.team_members)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-lg-4">

          <div className="card">
            <div className="card-body">
              <div className="card-title">Featured Links</div>
              <div className="ul-widget1">
                <div className="ul-widget4__item ul-widget4__users">
                  <div className="ul-widget4__img">
                    <i className="text-30 i-Calendar-4 text-primary"></i>
                  </div>
                  <div className="ul-widget2__info ul-widget4__users-info">
                    <a className="ul-widget2__title" href="#">
                      {(windowZoom > 1) ? excerpt('Leave', 10) : 'Leave'}
                    </a>
                    <span className="ul-widget2__username" href="#">Apply for leave</span>
                  </div>
                  <div className="ul-widget4__actions">
                    <Link className="btn btn-outline-info m-1" type="button" to={'/leave/request'}>
                      View
                    </Link>
                  </div>
                </div>
                <div className="ul-widget4__item ul-widget4__users">
                  <div className="ul-widget4__img">
                    <i className="text-30 i-Mail-Add- text-primary"></i>
                  </div>
                  <div className="ul-widget2__info ul-widget4__users-info">
                    <a className="ul-widget2__title" href="#">{(windowZoom > 1) ? excerpt('Expense Requisition', 7) : 'Expense Requisition'}</a><span className="ul-widget2__username" href="#">Create an expense requisition</span>
                  </div>
                  <div className="ul-widget4__actions">
                    <Link className="btn btn-outline-info m-1" type="button" to={'/requisition/expense'}>
                      View
                    </Link>
                  </div>
                </div>
                <div className="ul-widget4__item ul-widget4__users">
                  <div className="ul-widget4__img">
                    <i className="text-30 i-Mail-Add- text-primary"></i>
                  </div>
                  <div className="ul-widget2__info ul-widget4__users-info">
                    <a className="ul-widget2__title" href="#">{(windowZoom > 1) ? excerpt('Service Requisition', 10) : 'Service Requisition'}</a><span className="ul-widget2__username" href="#">Create a service requisition</span>
                  </div>
                  <div className="ul-widget4__actions">
                    <Link className="btn btn-outline-info m-1" type="button" to={'/requisition/service'}>
                      View
                    </Link>
                  </div>
                </div>
                <div className="ul-widget4__item ul-widget4__users">
                  <div className="ul-widget4__img">
                    <i className="text-30 i-Mail-Add- text-primary"></i>
                  </div>
                  <div className="ul-widget2__info ul-widget4__users-info">
                    <a className="ul-widget2__title" href="#">{(windowZoom > 1) ? excerpt('Appraisal', 10) : 'Appraisal'}</a><span className="ul-widget2__username" href="#">Start your appraisal</span>
                  </div>
                  <div className="ul-widget4__actions">
                    <Link className="btn btn-outline-info m-1" type="button" to={'/appraisal'}>
                      View
                    </Link>
                  </div>
                </div>
                <div className="ul-widget4__item ul-widget4__users">
                  <div className="ul-widget4__img">
                    <i className="text-30 i-Calendar-4 text-primary"></i>
                  </div>
                  <div className="ul-widget2__info ul-widget4__users-info">
                    <a className="ul-widget2__title" href="#">{(windowZoom > 1) ? excerpt('Payslip', 10) : 'Payslip'}</a><span className="ul-widget2__username" href="#">View your payslips</span>
                  </div>
                  <div className="ul-widget4__actions">
                    <Link className="btn btn-outline-info m-1" type="button" to={'/payslip'}>
                      View
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <>
          <div className="col-xl-6 col-lg-8 col-md-12">
            <div className="card text-start mb-4">
              <div className="card-body">
                <h4 className="card-title mb-3">Expense Requisition</h4>
                {getExpenseRequisitionTable()}
              </div>
            </div>

            <div className="card text-start mt-4">
              <div className="card-body">
                <h4 className="card-title mb-3">Expense Requisition</h4>
                {getServiceRequisitions()}
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4">
            <div className="card" style={{ minHeight: '345px', maxHeight: '345px', overflowY: 'scroll' }}>
              <div className="card-header purple-500 text-purple-500 p-4">
                <div className="d-flex align-items-center">
                  <div className="card-title text-white mb-0">Birthday Celebrants</div>
                </div>
              </div>
              <div className="card-body">
                <div className="ul-widget-app__social-site mt-4 mb-4">
                  <div className="row">
                    {getBirthdays()}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </>

      </div>
      {/* end of main-content */}
      {/* Footer Start */}
      <TTLCountdown />
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default Dashboard;