import Select from "react-select";
export const fields = [
  //sidebar menu
  {
    type: "text",
    title: "Text Input",
  },
  {
    type: "number",
    title: "Number Input",
  },
  {
    type: "large_text",
    title: "Large Text",
  },
  {
    type: "drop_down",
    title: "Dropdown",
  },
  {
    type: "add_employee",
    title: "Employees",
  },
  {
    type: "date_time",
    title: "Date/Time",
  },
  {
    type: "date",
    title: "Date",
  },
  {
    type: "time",
    title: "Time",
  },
  {
    type: "file",
    title: "File Upload",
  },
];

export const renderers = {
  text: () => (
    <input
      type="text"
      placeholder="This is a text input"
      className="form-control"
    />
  ),
  number: () => (
    <div className="input-group mb-3">
      <span className="input-group-text">$</span>
      <input
        className="form-control"
        type="text"
        aria-label="Amount (to the nearest dollar)"
      />
      <span class="input-group-text">.00</span>
    </div>
  ),
  large_text: () => <textarea rows="6" placeholder="Large text area" />,
  drop_down: () => (
    <Select
      options={[
        { value: "OptionA", label: "option A" },
        { value: "OptionB", label: "option B" },
      ]}
    />
  ),
  add_employee: () => (
    <button
      className="btn btn-primary dropdown-toggle"
      id="dropdownMenuButton"
      type="button"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Employee Select
    </button>
  ),
  date_time: () => (
    <input
      className="form-control"
      id="picker2"
      placeholder="Date and Time yyyy-mm-dd 00:59:59"
    />
  ),
  date: () => <input className="form-control" placeholder="Date yyyy-mm-dd" />,
  time: () => <input className="form-control" placeholder="Time 00:59:59" />,
  file: () => (
    <form className="dropzone dz-clickable" id="multple-file-upload" action="#">
      <div className="dz-default dz-message">
        <span>Drop files here to upload</span>
      </div>
    </form>
  ),
  button: () => (
    <button className="btn btn-outline-secondary m-1">Button</button>
  ),
};
