import { useEffect } from "react";
import { useSelector } from "react-redux";
import BreadCrumb from "../components/layout/BreadCrumb";
import Loader from "../components/Loader";
import AppraisalService from "../services/AppraisalService";
import CardinalService from "../services/CardinalService";
import ImageData from "../components/employee/avatar";
import TTLCountdown from "../components/TTLCountdown";

const EmployeeAppraisalEvalutions = () => {
    const appraisalService = AppraisalService();
    const { singeEvaluationData, loading } = useSelector((state) => state.appraisal);
    const { isCardinalLoading = false, cardinals = [] } = useSelector((state) => state.cardinal);
    const cardinalService = CardinalService();

    useEffect(() => {
        (async () => {
            await appraisalService.getAppraisalEvaluationDataForEmployee();
            await cardinalService.getCardinals();
        })();

    }, [])

    const getEvaluationRecords = (cardinal) => {
        const results = appraisalService.filterAppraisalEvaluationRecord(singeEvaluationData, cardinal);

        if (results && results.length !== 0) {
            return (
                <div className="card mb-4" style={{ height: '500px', overflow: 'auto', width: 'auto' }} key={cardinal.id}>
                    <div className="card-header bg-primary text-white">{cardinal.name}</div>
                    <div className="card-body">
                        {
                            results?.map((kpi_rating) => {
                                if (kpi_rating.cardinal_name === cardinal.name) {
                                    return (
                                        <div key={kpi_rating.key_performance_index_id}>
                                            <h5 className="card-title">{kpi_rating.key_responsibility_area_name}</h5>
                                            <p className="card-text">{kpi_rating.key_performance_index_name}</p>
                                            <p className="card-text"><span className="fw-bold">Employee Justification: </span> <span className="text-primary">{kpi_rating.employee_justification}</span></p>
                                            <p className="card-text"><span className="fw-bold">Employee Rating: </span> <span className="text-primary">{kpi_rating.employee_rating}</span></p>
                                            <p className="card-text"><span className="fw-bold">Supervisor Justification: </span> <span className="text-primary">{kpi_rating.supervisor_justification}</span></p>
                                            <p className="card-text"><span className="fw-bold">Supervisor Rating: </span> <span className="text-primary">{kpi_rating.supervisor_rating}</span></p>
                                            <div className="separator-breadcrumb border-top mt-3"></div>
                                        </div>
                                    )

                                }
                            })
                        }
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="main-content">
            <BreadCrumb
                title="Appraisal"
                parentLink="#"
                parentTitle="Pages"
                currentTitle="Employee Evaluation"
            />
            <div className="separator-breadcrumb border-top"></div>
            {
                (loading || isCardinalLoading) ? <Loader /> :
                    <div className="row">
                        {
                            (singeEvaluationData?.kpi_ratings?.length === 0) ?
                                <div className="alert alert-dismissible fade show alert-info" role="alert">
                                    <strong className="text-capitalize">Info!</strong> There are no records of your appraisal, kindly contact HR.
                                    <button
                                        className="btn btn-close"
                                        type="button"
                                        data-bs-dismiss="alert"
                                        aria-label="Close"
                                    >
                                    </button>
                                </div> :
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card card-profile-1 mb-4">
                                                    <div className="card-body text-center">
                                                        <div className="avatar box-shadow-2 mb-3">
                                                            <img src={ImageData} alt="employee image" />
                                                        </div>
                                                        <h5 className="m-0 text-primary">{singeEvaluationData?.evaluation?.employee_name}</h5>
                                                        <p className="mt-0 text-muted"></p>
                                                        <p>Supervisor: {singeEvaluationData?.evaluation?.supervisor}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <table className="table table-border">
                                                    <tbody>
                                                        <tr>
                                                            <td>Appraisal: </td>
                                                            <td className="text-primary">{singeEvaluationData?.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Self Review</td>
                                                            <td className="text-primary">{(typeof singeEvaluationData?.evaluation === 'undefined' ? '' : singeEvaluationData?.evaluation['Self Review'])}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Actual Score Technical</td>
                                                            <td className="text-primary">{(typeof singeEvaluationData?.evaluation === 'undefined') ? '' : singeEvaluationData?.evaluation['Actual Score Technical']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Technical Score Weighting</td>
                                                            <td className="text-primary">{(typeof singeEvaluationData?.evaluation === 'undefined') ? '' : singeEvaluationData?.evaluation['Technical Score Weighting']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Overall Score</td>
                                                            <td className="text-primary">{(typeof singeEvaluationData?.evaluation === 'undefined') ? '' : singeEvaluationData?.evaluation['Overall Score']}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-9">
                                        <div className="card text-start mb-4">
                                            <div className="card-body">
                                                <h4 className="card-title mb-3">Cardinal Scores</h4>
                                                <div className="table-responsive" style={{ display: 'block', whiteSpace: 'nowrap' }}>
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Cardinal</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Actual Score</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Total Score</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Weight</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Final Percentage Score</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                cardinals?.map((cardinal) => {
                                                                    for (const key in singeEvaluationData.evaluation) {
                                                                        if (key === cardinal.name) {
                                                                            return (
                                                                                <tr className="text-primary" key={cardinal.id}>
                                                                                    <td>{cardinal.name}</td>
                                                                                    <td style={{ textAlign: 'center' }}>{singeEvaluationData.evaluation[key]['Actual Score']}</td>
                                                                                    <td style={{ textAlign: 'center' }}>{singeEvaluationData.evaluation[key]['Total Score']}</td>
                                                                                    <td style={{ textAlign: 'center' }}>{singeEvaluationData.evaluation[key]['Weight']}</td>
                                                                                    <td style={{ textAlign: 'center' }}>{singeEvaluationData.evaluation[key]['Final Percentage Score']}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            cardinals?.map(cardinal => getEvaluationRecords(cardinal))
                                        }

                                    </div>
                                </div>
                        }
                    </div>
            }
            <TTLCountdown />
        </div>

        

    )
}

export default EmployeeAppraisalEvalutions;