import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isLoading, getRequestInput, getAttributeTypes, getRequestError, attributeUpdated, setRequestInputs } from '../store/slices/requestInputSlice';

const RequestInputService = () => {
    const dispatch = useDispatch();

    const get = async () => {
        dispatch(isLoading());

        try {
            const response = await AxiosService.get(`${serverUrl}/admin/attributes`);
            const attributeTypes = await AxiosService.get(`${serverUrl}/admin/attribute-types`);
            toast.success("Request fetched successfully!");
            dispatch(getRequestInput(response.data));
            dispatch(getAttributeTypes(attributeTypes));        
        } catch (error) {
            dispatch(getRequestError(error));
        }
    }

    const update = async (formData, requestInputs) => {
        dispatch(isLoading());

        try {
            await AxiosService.patch(`${serverUrl}/admin/attributes/${formData.attribute_id}`, {...formData});
            toast.success('Attribute updated successfully!');
            dispatch(attributeUpdated(formData));
            get();
        } catch (error) {
            dispatch(getRequestError(error));
        }
    }

    const create = async (formData) => {
        dispatch(isLoading());

        try {
            await AxiosService.post(`${serverUrl}/admin/attributes`, {...formData});
            toast.success('Attribute created successfully!');
            get();
        } catch (error) {
            dispatch(getRequestError(error));
        }
    }

    return {
        get,
        update,
        create,
    }
}

export default RequestInputService;