import React, { useEffect, useState } from "react";
import axios from "axios";
import { serverUrl } from "../utils/constants";
import ImageData from "../components/employee/avatar";
import TabSwitcher from "../components/requisition/requisitionTabs";
import RequisitionTable from "../components/requisition/requisitionTable";
import RequisitionModal from "../components/requisition/requisitionModal";
import Footer from "../components/layout/Footer";
import BreadCrumb from "../components/layout/BreadCrumb";

const RequestManagement = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const accessToken = JSON.parse(localStorage.getItem("token"));
  const [activeTab, setActiveTab] = useState("pending");
  const handleRowClick = async (employeeId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios.get(
        `${serverUrl}/employees/${employeeId}`,
        config
      );
      const employee = response.data;
      setSelectedEmployee(employee);
      setIsModalOpen(true);
    } catch (error) {}
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        setIsLoading(true);
        const response = await axios.get(`${serverUrl}/employees`, config);
        setEmployeeData(response.data.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  const employeeDataTable = employeeData.map((employee, index) => ({
    ...employee,
    index: index + 1,
  }));

  const columns = [
    {
      label: "S/N",
      render: (employee) => employee.index,
    },
    {
      label: "Requester",
      render: (employee) => (
        <img
          className="rounded-circle m-0 avatar-sm-table"
          src={employee.personal_details.photo || ImageData}
          alt=""
        />
      ),
    },
    {
      label: "Title",
      render: () => "Request for Approval",
    },
    {
      label: "Date Requested",
      render: () => "Jun 30, 10:00AM",
    },

    {
      label: "Status",
      render: () => <span className="badge bg-success">Approved</span>,
    },
    {
      label: "Action",
      render: (employee) => (
        <button
          className="btn border border-grey"
          type="button"
          onClick={() => handleRowClick(employee.id)}
        >
          <i className="nav-icon i-Eye"></i>
        </button>
      ),
    },
  ];

  return (
    <div className="main-content">
      <BreadCrumb
        title="Requisition"
        parentLink="#"
        parentTitle="Requisition Request"
        currentTitle="Requisition Request Management"
      />
      <div className="separator-breadcrumb border-top"></div>
      <div className="row"></div>

      <div className="col-md-12">
        <button
          className="btn btn-primary"
          type="button"
          style={{ margin: "20px", float:'right' }}
          
        >
          Raise a Requisition
        </button>
      </div>

      <div className="card card col-md-12 p-2 mt-2 border border-grey rounded">
      <h4 class="card-title mb-3" style={{marginTop:'10px'}}>Requisition Requests</h4>

        <TabSwitcher activeTab={activeTab} setActiveTab={setActiveTab}>
          <div label="pending">
            <RequisitionTable
              data={employeeDataTable}
              columns={columns}
              isLoading={isLoading}
              onRowClick={handleRowClick}
            />
          </div>
          <div label="approval">jjjjjjjjjjjjjjjjjjjjj</div>
        </TabSwitcher>
      </div>

      <div className="row"></div>

      <RequisitionModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        title={`Approved`}
        employee={selectedEmployee}
      />

    <Footer />
    </div>
  );
};

export default RequestManagement;
