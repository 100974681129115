import background from "../assets/images/jess-bailey-q10VITrVYUM-unsplash.jpg";
import logo from "../assets/images/Kenna Logo.png";
import {useState } from "react";
import axios from "axios";
import { serverUrl } from "../utils/constants";
import { toast } from "react-toastify";
import { startLoader, stopLoader } from "../store/slices/LoaderSlice";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
const [name, setName] = useState("");
const dispatch = useDispatch();
const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(startLoader());

    //prepare header
    const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": "",
        accept: "application/json",
    },
    };
    //post user credentials to server
    try {
    const result = await axios.post(
        serverUrl + "auth/forgotpassword",
        {
        username: name,
        },
        config
    );

    const {user} = result.data;
    if (user) {
        //save user credentials in context
        toast.success('Reset email sent. Please check your inbox.');
        dispatch(stopLoader());
    }
    else {
        const data = await result.json();
        toast.error(data.error);
        dispatch(stopLoader());

    }
    } catch (err) {
      //console.log(err);
        toast.error('An error occurred.');
        dispatch(stopLoader());
    }
};

return (
    <>
    <div
        className="auth-layout-wrap"
        style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        }}
    >
        <div className="auth-content">
        <div className="row">
            <div className="card o-hidden">
            <div className="p-4">
                <div className="auth-logo text-center mb-4">
                <img
                    src={logo}
                    alt=""
                    style={{ height: "90px", width: "150px" }}
                />
                </div>
                <h1 className="mb-3 text-18">Forgot Password </h1>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                    style={{
                    backgroundColor: "#F5EAFF",
                    borderColor: "#731EC9",
                    }}
                    className="form-control form-control-rounded"
                    name="email"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    />
                </div>

                <button className="btn btn-rounded btn-primary w-100 mt-2">
                    Reset Password
                </button>
                </form>
                <div className="mt-3 text-center">
                <a className="text-muted" href="/">
                    <u>Sign In?</u>
                </a>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </>
);
};

export default ForgotPassword;
