import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    requestInputs: [],
    isRequestInputLoading: false,
    error: null,
    attributeTypes: [],
    isAttributeUpdated: false,
}

const requestInputSlice = createSlice({
    name: "requestInput",
    initialState,
    reducers: {
        isLoading(state) {
            state.isRequestInputLoading = true;
            state.error = null;
        },
        getRequestInput(state, action) {
            const items = action.payload;
            state.requestInputs = items?.map((item, index) => ({...item, index: ++index}));
            state.isRequestInputLoading = false;
            state.error = null;
        },
        getAttributeTypes(state, action) {
            state.attributeTypes = action.payload;
        },
        getRequestError(state, action) {
            state.error = action.payload;
            state.isRequestInputLoading = false;
        },
        attributeUpdated(state, action) {
            state.isRequestInputLoading = false;
            state.error = null;
        },
        setRequestInputs(state, action) {
            const items = action.payload;
            state.requestInputs = items;
        }
    }
});

export const {
    isLoading,
    getRequestInput,
    getAttributeTypes,
    getRequestError,
    attributeUpdated,
    setRequestInputs,
} = requestInputSlice.actions;

export default requestInputSlice.reducer;