
const HtmlTable = ({isVerticallyScrollable = false, columns = [], data = [], showFooter = false}) => {
    const getColumns = () => {
        return columns?.map((column) => {
            return (<th scope="col" style={column.style} key={column.label}>{column.label}</th>)
        });
    }

    const getFooters = () => {
        let counter = 0;

        return columns?.map((column) => {
            return (<th scope="col" style={column.style} key={column.label + ++counter}>{column.label}</th>)
        });
    }

    return (
        <div className="table-responsive mt-3" style={isVerticallyScrollable ? { display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', height: '600px', textAlign: 'center'} : {}}>
            <table className="table table-striped">
                <thead>
                    <tr>
                        { getColumns() }
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    {
                                        (columns?.map((column, i) => { return (<td style={column.style} key={i}>{column.render(item)}</td>) }))
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        { (showFooter) && getFooters() }
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default HtmlTable;