import { createSlice } from "@reduxjs/toolkit";

const LoaderSlice = createSlice(
    {
        name: 'Loader',
        initialState: {
            isLoading: false,
        },
        reducers: {
            startLoader: (state) => { state.isLoading = true },
            stopLoader: (state) => { state.isLoading = false },
        }
    }
);

export const { startLoader, stopLoader } = LoaderSlice.actions;

export default LoaderSlice.reducer;