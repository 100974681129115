import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    leaves: [],
    isLeaveLoading: false,
    error: null,
    leaveBalance: []
};

const leaveSlice = createSlice({
    name: 'leaves',
    initialState,
    reducers: {
        isLoading(state) {
            state.isLeaveLoading = true;
        },
        setLeaves(state, action) {
            state.leaves = action.payload;
            state.isLeaveLoading = false;
            state.error = null;
        },
        setLeaveBalance(state, action) {
            state.leaveBalance = action.payload;
            state.isLeaveLoading = false;
            state.error = null;
        },
        setError(state, action) {
            state.error = action.payload;
            state.isLeaveLoading = false;
        }
    }
});

export const { isLoading, setLeaves, setLeaveBalance } = leaveSlice.actions;
export default leaveSlice.reducer;