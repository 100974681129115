/**
 * This content keeps track of whether or not a user logged in
 */
import { createContext, useState } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authData, setAuthData] = useState({});

  const addToAuthData = (user, accessToken, ttl) => {
    setAuthData(prevState => ({...prevState, user, accessToken, ttl}));
    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("token", JSON.stringify(accessToken));
    sessionStorage.setItem("authenticated", true);
    sessionStorage.setItem("ttl", JSON.stringify(ttl * 60));
  }

  return (
    <AuthContext.Provider value={{authData, addToAuthData}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;