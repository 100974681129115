import { Routes, Route } from "react-router-dom";
import SelfAppraisal from "../../views/SelfAppraisal";
import SVAppraisal from "../../views/SVAppraisal";
import EmployeeAppraisalEvalutions from "../../views/EmployeeAppraisalEvalutions";


const AppraisalRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SelfAppraisal />} />
      <Route path="/team-members" element={ <SVAppraisal /> } />
      <Route path="/appraisal-evaluation" element={ <EmployeeAppraisalEvalutions /> } />
    </Routes>
  );
};

export default AppraisalRoutes;
