import React, { useState, useEffect } from 'react';
import BreadCrumb from "../../../components/layout/BreadCrumb";
import Footer from "../../../components/layout/Footer";
import DataTable from '../../../components/tables/reactTable';
import ModalComponent from '../../../components/modals/modal';
import TableDropDown from '../../../components/requisition/dropDown';
import CardinalService from '../../../services/CardinalService';
import NoRecords from '../../../components/requisition/not-found';
import Loader from '../../../components/Loader';
import { useSelector } from 'react-redux';
import TTLCountdown from '../../../components/TTLCountdown';
 
const CardinalSetup = () => {
  const cardinalService = CardinalService();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isCardinalLoading = false, cardinals = [] } = useSelector((state) => state.cardinal );

  const handleAddCardinal = () => {
    setIsAddModalOpen(true);
  };

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  useEffect(() => {
    (async () => { await cardinalService.getCardinals(); })();
  }, []);

  const getDataTable = () => {
    if (cardinals?.length === 0 ) {
      return <NoRecords />
    }

    return <DataTable data={cardinals} columns={columns} />;
  }

  const columns = [
    {
      label: "S/N",
      render: (cardinal) => cardinal.index,
      sortFunction: () => (item) => item.index,
    },

    {
      label: "Name",
      render: (cardinal) => `${cardinal.name}`,
      sortFunction: () => (item) => item.name,
    },
    {
      label: "Description",
      render: (cardinal) => `${cardinal?.description || ''}`,
      sortFunction: () => (item) => item.description,
    },
    {
      label: "Actions",
      render: (cardinal) => (
        <TableDropDown
          options={[
            { label: "Edit", action: () => handleEditClick(cardinal) },
            { label: "Delete", action: () => { } },
          ]}
        />
      ),
    }
  ];

  return (
    <div className="main-content">
      <BreadCrumb title="Appraisal" parentLink="#" parentTitle="Pages" currentTitle="Cardinal Setup" />
      <div className="separator-breadcrumb border-top"></div>
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-header d-flex align-items-center">
              <h3 className="w-50 float-start card-title m-0">List of Cardinals</h3>
              <div className="dropdown dropleft text-end w-50 float-end">
                <button className="btn bg-primary text-white" type="button" aria-haspopup="true" onClick={handleAddCardinal}>
                  <i className="nav-icon i-Upload text-white"></i> Add Cardinal
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="table-responsive">
                  { isCardinalLoading ? <Loader /> : getDataTable() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of main-content */}
      {/* Modal Component */}
      <ModalComponent
        isOpen={isAddModalOpen}
        onRequestClose={() => setIsAddModalOpen(false)}
        title={`Add New Cardinal`}
        content={(
          <>
            <form>
              <div className="form-group">
                <label htmlFor="name">Name: *</label>
                <input type="text" id="name" name="name" className="form-control" required />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description: </label>
                <input type="text" id="description" name="description" className="form-control" required />
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">Save</button>
              </div>
            </form>
          </>
        )}
      />
      {selectedRecord && (
        <ModalComponent
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          title={`Edit Cardinal`}
          content={(
            <>
              <form>
                <div className="form-group">
                  <label htmlFor="editName">Name: *</label>
                  <input
                    type="text"
                    id="editName"
                    name="editName"
                    className="form-control"
                    defaultValue={selectedRecord.name}
                    required />
                </div>
                <div className="form-group">
                  <label htmlFor="editDescription">Description: </label>
                  <input type="text"
                    id="editDescription"
                    name="editDescription"
                    className="form-control"
                    defaultValue={selectedRecord.description}
                    required />
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Update</button>
                </div>
              </form>
            </>
          )}
        />
      )}
      {/* Footer Start */}

      <TTLCountdown />
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default CardinalSetup;
