import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isLoading, setLeaves, setLeaveBalance } from "../store/slices/leaveSlice";

const LeaveService = () => {
    const dispatch = useDispatch();

    const get = async () => {
        try {
            dispatch(isLoading);
            const response = await AxiosService.get(`${serverUrl}/leaves`);
            dispatch(setLeaves(response.data));
        } catch (error) {
            toast.error('An error occurred fetching leaves');
        }
    }

    const getLeaveBalance = async (employeeId) => {
        try {
            dispatch(isLoading);
            const response = await AxiosService.get(`${serverUrl}/leaves/balance/employee/${employeeId}`);
            dispatch(setLeaveBalance(response.data));
        } catch (error) {
            toast.error('There was an error fetching leave balance');
        }
    }

    const calculateDuration = (startDate, resumptionDate, holidays) => {
        if (startDate === null || resumptionDate === null) return 0;

        if (isResumptionDateGreaterThanStartDate(resumptionDate, startDate)) return 0;

        let leaveDays = [];
        let holidayDuringLeave = 0;
        let resetStateDateTime = new Date(startDate).setHours(0, 0, 0, 0);
        let resetResumptionDateTime = new Date(resumptionDate).setHours(0, 0, 0, 0);

        for (var currentDate = new Date(resetStateDateTime); currentDate < resetResumptionDateTime; currentDate.setDate(currentDate.getDate() + 1)) {
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
                leaveDays.push(new Date(currentDate));
            }
        }

        holidays?.map((holiday) => {
            leaveDays.forEach(leaveDay => {
                if (new Date(leaveDay).getTime() === new Date(new Date(holiday.date).setHours(0, 0, 0, 0)).getTime()) {
                    holidayDuringLeave += 1;
                }
            })
        });

        return (leaveDays.length - holidayDuringLeave);
    }

    const isResumptionDateGreaterThanStartDate = (resumptionDate, startDate) => {
        if ((resumptionDate - startDate) <= 0) {
            toast.error('Resumption date cannot be in the past!');
            return true;
        }

        return false;
    }

    return {
        get,
        getLeaveBalance,
        calculateDuration,
        isResumptionDateGreaterThanStartDate
    }
}

export default LeaveService;