import logo from "../../assets/images/Kenna Logo.png";
import { Link } from "react-router-dom";

const SideBarSubMenu = ({ isActiveSubMenu, subMenu }) => {
  return (
    <div
      className="sidebar-left-secondary rtl-ps-none"
      data-perfect-scrollbar
      data-suppress-scroll-x="true"
    >
      {/* <i className="sidebar-close i-Close"></i> */}
      <header>
        <div className="logo" style={{ marginLeft: "-2px", marginTop: "-10px", }} >
          <img src={logo} alt="" style={{ height: "70px", marginLeft: "-10px" }} />
        </div>
        <hr style={{ backgroundColor: "#d1d5db", marginTop: "-5px" }}></hr>
      </header>
      <div
        className="submenu-area"
        style={{ display: isActiveSubMenu ? "block" : "none" }}
        data-parent="dashboard"
      >
        <header>
          <h6>{subMenu && subMenu.display}</h6>
        </header>
        <ul className="childNav">
          {subMenu &&
            subMenu?.subMenus &&
            subMenu?.subMenus.map((sm, index) => {
              return (
                <li className="nav-item" key={index}>
                  <Link to={sm?.link} key={index}>
                    <i className={sm?.icon}></i>
                    <span className="item-name">{sm?.title}</span>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default SideBarSubMenu;
