import React from 'react';
import { useLocation } from 'react-router-dom';
import NoRecords from './not-found';
import Footer from '../layout/Footer';
import getBadgeColorByStatus from '../../utils/badgeColor';
import getIconByFileURL from '../../utils/FileIcons';
import BreadCrumb from '../layout/BreadCrumb';

const ViewRequisition = () => {
    const location = useLocation();
    const { requisition } = location?.state || {}; 
    const requestValue = (Object.keys(requisition.request_value).length !== 0) ? JSON.parse(requisition.request_value) : requisition.request_value;
    
    if (!requisition) {
        return <div className="main-content">
        <BreadCrumb
        title="Requisition"
        parentLink="#"
        parentTitle="Requisition Request"
        currentTitle="My Requisitions Details"
        />
        <div className="separator-breadcrumb border-top"></div>
            <div className="row">
                <div className="text-center mb-3 col-sm-2">
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <NoRecords />
                    </div>
                </div>
            </div>
        </div>;
    }

    return (
        <div className="main-content">
            <div className="breadcrumb">
                <h1>Requisition</h1>
                <ul>
                    <li>
                        <a href="#">Requisition Request</a>
                    </li>
                    <li>My Requisitions Details</li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row"></div>
            {/* start of main-content */}
            <div className="col-md-7 offset-md-2">
                <div className="card text-start">
                    <div className="card-body">
                        <h3 className={`text-${getBadgeColorByStatus(requisition.state_type_name)} text-center`} style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                            {requisition?.state_name}
                        </h3>
                        <i class="fa-solid fa-file-pdf"></i>
                        <h4 className="card-title mb-2 text-center separator-breadcrumb border-bottom pb-3">
                            {requisition?.request_name}
                        </h4>
                        <div className='pt-2'>
                            {
                                requisition?.request_value ? (
                                    requestValue.map((item, index) => (

                                        <div class="" id="ul-widget5-tab1-content">
                                            <div class="ul-widget5">
                                                <div class="ul-widget5__item" style={{ borderBottom: '0.07rem dashed #e5e7eb' }}>
                                                    <div class="ul-widget5__content">

                                                        <div class="ul-widget5__section">
                                                            <h5 class="fw-bold">{item.name}</h5>
                                                            {(!Array.isArray(item.value)) && <p>{item.value}</p>}

                                                            {(Array.isArray(item.value)) &&

                                                                <li class="list-group-item border-0">
                                                                    {item.value.map((selected) => <span class="badge rounded-pill badge-outline-primary p-2 m-1">{selected.name}</span>)}
                                                                </li>
                                                            }

                                                            {(item.attribute_type === 'file') &&
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        {item.value.map((file, index) =>
                                                                            <div className="col-md-4" key={index}>
                                                                                <a href={file} target="_blank" rel="noopener noreferrer">
                                                                                    <img src={getIconByFileURL(file)} alt={`File ${index + 1}`} width="50" height="50" />
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                ) : (
                                    <div>
                                        <NoRecords />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            {/* end of main-content */}
            {/* Footer Start */}
            <Footer />
            {/* fotter end */}
        </div>
    );
};

export default ViewRequisition;
