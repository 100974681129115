import { Route, Routes } from "react-router-dom";
import LeaveRequest from "../../views/leaves/LeaveRequest";
import LeaveManagement from "../../views/leaves/LeaveManagement";
import LeaveHistory from "../../views/leaves/LeaveHistory";

const LeaveRoutes = () => {
  return (
    <Routes>
        <Route path="/request" element={<LeaveRequest />} />
        <Route path="/manage" element={<LeaveManagement />} />
        <Route path="/history" element={ <LeaveHistory /> } />
    </Routes>
  );
};

export default LeaveRoutes;
