import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isLoading, setHolidays, getHolidayError } from "../store/slices/holidaySlice";

const HolidayService = () => {
    const dispatch = useDispatch();

    const get = async () => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.get(`${serverUrl}/holidays`);
            dispatch(setHolidays(response.data));
        } catch (error) {
            toast.error("Error fetching requests");
            dispatch(getHolidayError(error.message));
        }
    }

    const update = async (holiday) => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.patch(`${serverUrl}/admin/holidays/${holiday.id}`, holiday);
            get();
        } catch (error) {
            toast.error("Error fetching requests");
            dispatch(getHolidayError(error.message));
        }
    }

    const create = async (holiday) => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.post(`${serverUrl}/admin/holidays`, holiday);
            get();
        } catch (error) {
            toast.error("Error fetching requests");
            dispatch(getHolidayError(error.message));
        }
    }

    return {
        get,
        update,
        create,
    }
}

export default HolidayService;