import { Link } from "react-router-dom";

const SettingsButton = ({
  to = "#",
  icon = "i-Data-Cloud",
  title,
  subTitle,
}) => {
  return (
    <div className="col-lg-3 col-md-6 mb-4">
      <Link className="settings-menu" to={to}>
        <div className="p-4 border border-light rounded d-flex align-items-center">
          <i className={`${icon} text-32 me-3`}></i>
          <div>
            <h4 className="text-18 mb-1">{title}</h4>
            <span>{subTitle}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SettingsButton;
