export default function getIconByFileURL( fileURL ) {
    const extension = fileURL.split('.').pop().toLowerCase();
        switch (extension) {
            case 'xls':
            case 'xlsx':
            return require('../assets/images/attachments/excel.jpeg');
            case 'doc':
            case 'docx':
            return require('../assets/images/attachments/doc.jpeg');
            case 'png':
            return require('../assets/images/attachments/png.jpeg');
            case 'jpg':
            case 'jpeg':
            return require('../assets/images/attachments/jpeg.jpeg');
            case 'pdf':
            return require('../assets/images/attachments/pdf.jpeg');
            default:
            return require('../assets/images/attachments/default.jpeg'); 
        }
}