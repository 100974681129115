const InputLayout = ({ title, children }) => {
  return (
    <div className="card col-md-12  mt-4 border border-grey rounded">
      <h5 className="text-black mb-3 font-weight-bold p-2" style={{marginTop:'10px'}}>
        {title}
      </h5>

      <div className="d-flex justify-content-center">{children}</div>
    </div>
  );
};
export default InputLayout;
