import { serverUrl } from "../utils/constants";
import AxiosService from "./AxiosService";
import { isLoading, setWorkflowStates } from "../store/slices/workflowStateSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const WorkflowStateService = () => {
    const dispatch = useDispatch();

    const getStates = async () => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.get(`${serverUrl}/states`);
            dispatch(setWorkflowStates(response.data));
        } catch (error) {
            toast.error("Error fetching states");
        }
    }

    return {
        getStates
    }
}

export default WorkflowStateService;