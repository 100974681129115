import { useDroppable } from "@dnd-kit/core";

import { CSS } from "@dnd-kit/utilities";
import SortableField from "./SortableField";

export default function Board(props) {
  const { fields } = props;

  const { attributes, listeners, setNodeRef, transform, transition, isOver } =
    useDroppable({
      id: "canvas_droppable",
      data: {
        parent: null,
        isContainer: true,
      },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    flex: 1,
    border: `4px solid ${isOver ? "red" : " #B9B7BD"}`,
    margin: "10px",
    borderRadius: "20px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    minHeight: "400px",
    overflowY: "auto",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {fields &&
        fields?.map((f, i) => (
          <div key={f.id}>
            <SortableField key={f.id} id={f.id} field={f} index={i} />
          </div>
        ))}
    </div>
  );
}
