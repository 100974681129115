import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authData: [],
    loading: false,
    error: null,
}

const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setAuthData(state, action) {
            state.authData = action.payload;
            state.loading = false;
            state.error = null;
        },
        isLoading(state) {
            state.loading = true;
        },
        setError(state, action) {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export const { setAuthData, isLoading, setError } = authenticationSlice.actions;

export default authenticationSlice.reducer;